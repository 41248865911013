export const compareUsers = (a, b) => {
    if (`${a.lastName} ${a.firstName}` < `${b.lastName} ${b.firstName}`) {
        return -1;
    } else if (`${a.lastName} ${a.firstName}` > `${b.lastName} ${b.firstName}`) {
        return 1;
    } else {
        return 0;
    }
};

export const compareLessons = (a, b) => {
    if (a.id < b.id) {
        return -1;
    } else if (a.id > b.id) {
        return 1;
    } else {
        return 0;
    }
};

export const compareById = (a, b) => {
    let aNumber = a.id;
    let bNumber = b.id;
    if (aNumber < bNumber) {
        return -1;
    } else if (aNumber > bNumber) {
        return 1;
    } else {
        return 0;
    }
};

export const compareBySection = (a, b) => {
    let aNumber = Number(a.section);
    let bNumber = Number(b.section);
    if (aNumber < bNumber) {
        return -1;
    } else if (aNumber > bNumber) {
        return 1;
    } else {
        return 0;
    }
};

export const formatDate = (dateString) => {
    let date = new Date(dateString);
    let options = { year: "numeric", month: "long", day: "numeric" };
    return `${date.toLocaleDateString("ru-RU", options)} в ${date.toLocaleTimeString("ru-RU")}`;
};

export const mongoObjectId = () => {
    let timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
        timestamp +
        "xxxxxxxxxxxxxxxx"
            .replace(/[x]/g, function() {
                return ((Math.random() * 16) | 0).toString(16);
            })
            .toLowerCase()
    );
};

export const userRoles = ["user", "expert"];

export const roleOptions = [
    {
        text: "Администратор",
        value: "admin",
    },
    {
        text: "Автор",
        value: "author",
    },
    {
        text: "Учитель",
        value: "teacher",
    },
    {
        text: "Ученик",
        value: "user",
    },
    {
        text: "Эксперт",
        value: "expert"
    }
];

export const blockOptions = [
    {
        text: "Текст",
        value: "text",
    },
    {
        text: "iframe блок",
        value: "iframe",
    },
    {
        text: "Интерактив: заполнить пропуски",
        value: "question-interactive-fill-slots"
    },
    {
        text: "Интерактив: выбрать из списка",
        value: "question-interactive-fill-from-list",
    },
    {
        text: "Интерактив: классификация",
        value: "question-interactive-classification",
    },
    {
        text: "Интерактив: найти пару",
        value: "question-interactive-find-pairs",
    },
    {
        text: "Интерактив: найти соответствия",
        value: "question-interactive-find-matches",
    },
    {
        text: "Интерактив: отметить слова",
        value: "question-interactive-select-words",
    },
    {
        text: "Интерактив: угадай слово",
        value: "question-interactive-guess-word",
    },
    {
        text: "Интерактив: поиск слов",
        value: "question-interactive-word-search",
    },
    {
        text: "Интерактив: упорядочить слова",
        value: "question-interactive-order-words",
    },
    // not showing interactive block to avoid misuse
    // {
    //     text: "Интерактивный блок",
    //     value: "interactive",
    // },
    {
        text: "Вопрос без правильного ответа",
        value: "indefiniteQuestion",
    },
    {
        text: "Вопрос",
        value: "question",
    },
];
