// modules
import React from 'react';
import { withTranslation } from 'react-i18next';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';

const AboutModal = ({ onClose, t }) => (
  <Modal size="small" open closeOnDimmerClick={false} onClose={onClose} >
    <div className="landing-modal">
      <div className="landing-modal-cross" onClick={onClose}>
        <img src={cross} alt="Cross icon" />
      </div>

      <div className="landing-modal-header">
        <span>{t('landing.header.about')}</span>
      </div>

      <div className="landing-modal-about text">
        {t('landing.aboutmodal.text')}
      </div>

      <div className="landing-modal-header">
        <span>{t('landing.header.contacts')}</span>
      </div>

      <ul className="landing-modal-about contacts__list">
        <li className="landing-modal-about contacts__item">
          <a href="https://arman-pv.kz" target="_blank" rel="noopener noreferrer">arman-pv.kz</a>
        </li>
        <li className="landing-modal-about contacts__item">
          <a href="mailto:info@arman-pv.kz" target="_blank" rel="noopener noreferrer">info@arman-pv.kz</a>
        </li>
      </ul>
    </div>
  </Modal>
);

export const WrappedAboutModal = withTranslation()(AboutModal);
