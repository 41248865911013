// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import shuffle from "lodash/shuffle"
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
  padding-bottom: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const StyledWordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  background-color: rgba(250, 250, 250);
  border-radius: 8px;
`;

const StyledWordNumber = styled.div`
  align-self: flex-start;
`;

const StyledWordHint = styled.div`
  font-weight: bold;
`;

const StyledLetterLine = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
`;

const StyledLetter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid rgb(150, 150, 150);
  border-radius: 4px;
`;

const StyledTargetLetter = styled(StyledLetter)`
  transform: ${props => props.isCurrent ? 'scale(1.15)' : 'none'};
  border-color: ${props => props.isCorrect === undefined ? '#000' : props.isCorrect ? '#18c518' : '#e84e42'};
  border-width: ${props => props.isCurrent ? '2px' : '1px'};
`;

const StyledSourceLetter = styled(StyledLetter)`
  color: ${props => props.isDisabled ? 'rgb(150, 150, 150)' : '#000'};
  border-color: ${props => props.isDisabled ? '#fff' : 'rgb(150, 150, 150)'};
  cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
`;

const StyledControls = styled.div`
  display: flex;
  column-gap: 8px;
`;

class InteractiveQuestion7 extends Component {
  constructor(props) {
    super(props);

    const { block } = this.props;

    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const words = (interactive.words || []).map(({ word, hint }) => ({ word, hint, showHint: false }));
    const shuffledWords = words.map(({ word }) => shuffle(word.split('')).map((letter) => ({ letter, isSelected: false })));
    const userAnswers = block.answer ? JSON.parse(block.answer) : Array(words.length).fill('');

    this.state = {
      answered: !!block.answer,
      correct: block.correct,
      elapsed: block.time || 0,
      words,
      shuffledWords,
      userAnswers,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { elapsed, userAnswers, words } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    if (!userAnswers.every((answer, index) => answer.length === words[index].word.length)) {
      alert('Угадайте все слова!');
      return;
    }

    // stop timer
    if (this.timer) clearInterval(this.timer);

    let isCorrect = true;
    // compare user answers with correct answers
    for (let i = 0; i < userAnswers.length; i++) {
      if (userAnswers[i] !== words[i].word) {
        isCorrect = false;
      }
    }

    // convert user answers array into a string
    const stringifiedAnswer = JSON.stringify(userAnswers);

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    //   userAnswers,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
        userAnswers,
      });
    });
  };

  onReanswerClick = () => {
    const newShuffledWords = [...this.state.shuffledWords].map((lettersArray) => lettersArray.map((letter) => ({ ...letter, isSelected: false })));

    this.setState(prevState => ({
      answered: false,
      userAnswers: Array(prevState.words.length).fill(''),
      shuffledWords: newShuffledWords,
      correct: undefined,
      elapsed: 0,
      hasReanswered: true
    }), () => {
      // start the timer again
      this.startTimer();
    });
  };

  handleShowHint = (index) => {
    const newWords = [...this.state.words];
    newWords[index].showHint = !newWords[index].showHint;

    this.setState({
      words: newWords,
    });
  };

  handleResetAnswer = (index) => {
    const newUserAnswers = [...this.state.userAnswers];
    newUserAnswers[index] = '';
    const newShuffledWords = [...this.state.shuffledWords];
    newShuffledWords[index] = newShuffledWords[index].map((item) => ({ ...item, isSelected: false }));

    this.setState({
      userAnswers: newUserAnswers,
      shuffledWords: newShuffledWords,
    });
  };

  handleSourceLetterClick = (letter, isSelected, wordIndex, letterIndex) => {
    if (this.state.answered || isSelected || this.state.userAnswers[wordIndex].length === this.state.shuffledWords[wordIndex].length) {
      return;
    }

    const newUserAnswers = [...this.state.userAnswers];
    newUserAnswers[wordIndex] = `${newUserAnswers[wordIndex]}${letter}`;
    const newShuffledWords = [...this.state.shuffledWords];
    newShuffledWords[wordIndex][letterIndex].isSelected = true;

    this.setState({
      userAnswers: newUserAnswers,
      shuffledWords: newShuffledWords,
    });
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>{ReactHtmlParser(this.props.block.text)}</div>
          </div>
          <StyledContainer>
            {this.state.words.map(({ word, hint, showHint }, wordIndex) => (
              <StyledWordContainer key={`interactive-guess-word-${word}`}>
                  <StyledWordNumber>
                    {wordIndex + 1}/{this.state.words.length}
                  </StyledWordNumber>
                  {showHint ? (
                      <StyledWordHint>{hint}</StyledWordHint>
                  ) : null}
                  <StyledLetterLine>
                    {word.split('').map((_, letterIndex) => (
                      <StyledTargetLetter
                        isCurrent={letterIndex === this.state.userAnswers[wordIndex].length}
                        isCorrect={this.state.answered && this.state.userAnswers[wordIndex].length === word.length ? this.state.userAnswers[wordIndex] === word : undefined}
                      >
                        {letterIndex > this.state.userAnswers[wordIndex].length ? '' : this.state.userAnswers[wordIndex][letterIndex]}
                      </StyledTargetLetter>
                    ))}
                  </StyledLetterLine>
                  <StyledLetterLine>
                    {this.state.shuffledWords[wordIndex].map(({ letter, isSelected }, letterIndex) => (
                      <StyledSourceLetter
                        isDisabled={this.state.answered || isSelected}
                        onClick={() => this.handleSourceLetterClick(letter, isSelected, wordIndex, letterIndex)}
                      >
                        {letter}
                      </StyledSourceLetter>
                    ))}
                  </StyledLetterLine>
                  <StyledControls>
                    {hint ? (
                      <Button primary onClick={() => this.handleShowHint(wordIndex)}>
                        {showHint ? t("dashboard.course.lesson.question.hideHint") : t("dashboard.course.lesson.question.showHint")}
                      </Button>
                    ) : null}
                    <Button disabled={this.state.answered} onClick={() => this.handleResetAnswer(wordIndex)}>
                      {t("dashboard.course.lesson.question.resetAnswer")}
                    </Button>
                  </StyledControls>
              </StyledWordContainer>
            ))}
          </StyledContainer>
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion7));
