// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { adminRoutes } from 'assets/routes';
import { compareUsers } from 'assets/utils';
import { redirect } from 'utils';
// styles
import { Container, Header, List, Loader, Button } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from 'store/User';

class Authors extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.fetchAuthors();
  }

  fetchAuthors = () => {
    this.setState({ loading: true });

    axios
      .get(adminRoutes.authors.getAuthors())
      .then(response => {
        this.setState({ loading: false, authors: response.data.message });
      })
      .catch(error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
  };

  render() {
    let authors = [];
    let authorItems = [];
    let authorsNumber = 0;

    if (this.state.authors && this.state.authors.length !== 0) {
      // copy authors
      authors = [...this.state.authors];
      // count the number of authors
      authorsNumber = authors.length;
      // sort authors
      authors.sort(compareUsers);

      // add authors to list
      for (let i = 0; i < authors.length; i++) {
        const author = authors[i];

        authorItems.push(
          <List.Item key={'admin-authors-' + i}>
            <List.Icon name="user" />
            <List.Content>
              <List.Header
                style={{
                  color: '#3697dd',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px'
                }}
                onClick={() => redirect(this, `/admin/authors/${author.email}`)}
              >
                {`${author.lastName} ${author.firstName}`}
              </List.Header>
              <List.Description>{author.email}</List.Description>
            </List.Content>
          </List.Item>
        );
      }
    }

    return (
      <DocumentTitle title="Управление авторами">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              Управление авторами
            </Header>

            <Button className="admin-authors-new-author" onClick={() => redirect(this, '/admin/authors/new')}>
              Создать нового автора
            </Button>

            <div className="admin-authors-subheader">Авторы: {authorsNumber}</div>
            <List divided relaxed="very">
              {authorItems}
            </List>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Authors);
