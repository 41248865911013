// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import axios from "axios";
import groupBy from "lodash/groupBy";
// assets
import { practicesRoutes } from "assets/routes";
import { compareBySection } from "assets/utils";
// styles
import "./index.css";
// components
// redux

class PracticesList extends Component {
    state = {
        loading: false,
        practices: [],
    };

    componentDidMount() {
        // fetch practices
        this.fetchPractices();
    }

    fetchPractices = () => {
        this.setState({ loading: true });
        axios
            .get(practicesRoutes.getPractices())
            .then((response) => {
                this.setState({ loading: false, practices: response.data.message });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    render() {
        let practicesComponent = [];

        if (this.state.practices && this.state.practices.length !== 0) {
            // group practices by group number
            let groupedPractices = groupBy(this.state.practices, "groupNumber");

            // iterate through groups
            for (let key in groupedPractices) {
                // group component
                let groupComponent = [];
                // get array of practices in the current group
                let groupPractices = [...groupedPractices[key]];
                // sort practices by section
                groupPractices = groupPractices.sort(compareBySection);

                // iterate over each group practice
                for (let i = 0; i < groupPractices.length; i++) {
                    // get one practice
                    let practice = groupPractices[i];
                    // push practice to group component
                    groupComponent.push(
                        <div
                            className="dashboard-practice-practices-list-block"
                            key={`dashboard-practice-practices-list-block-${i}`}
                        >
                            <a
                                className="dashboard-practice-practices-list-link"
                                href={`/dashboard/practice/${practice._id}`}
                            >{`${practice.section}. ${practice.name}`}</a>
                        </div>
                    );
                }

                // push a group component to practices list component
                practicesComponent.push(
                    <div
                        className="dashboard-practice-practices-list-group"
                        key={`dashboard-practice-practices-list-group-${key}`}
                    >
                        <div className="dashboard-practice-practices-list-group-name">
                            {groupedPractices[key][0].groupName
                                ? `${groupedPractices[key][0].groupNumber}. ${groupedPractices[key][0].groupName}`
                                : "Остальное"}
                        </div>
                        {groupComponent}
                    </div>
                );
            }
        }

        return (
            <DocumentTitle title="Список практических уроков">
                <div>
                    <h1>Список практических уроков</h1>
                    {practicesComponent}
                </div>
            </DocumentTitle>
        );
    }
}

export default connect(
    (store) => ({}),
    {}
)(PracticesList);
