// modules
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
// styles
import "./index.css";
// components
import { Loader } from "semantic-ui-react";
import CourseNavigation from "./CourseNavigation";
import MenuNavigation from "./MenuNavigation";
import Profile from "./Profile";
import CoursesList from "./CoursesList";
import Course from "./Course";
import { WrappedPaymentModal as PaymentModal } from "./PaymentModal";
import { WrappedPaymentRequiredModal as PaymentRequiredModal } from "./PaymentRequiredModal";
// redux
import { getUserPaymentInfo } from "store/User";

const StyledNavigationContainer = styled.div`
  background-image: ${(props) => `url(${props.background})`};
  background-position: top;
  background-repeat: repeat-y;
  background-size: auto 100vh;
`;

const StyledContentContainer = styled.div`
  background-color: ${(props) => props.color};
`;

class Dashboard extends Component {
  componentDidMount() {
    this.props.getUserPaymentInfo();
  }

  render() {
    const {
      currentCourse,
      hasAccessToCourses,
      loadingUserPaymentInfo,
      t,
    } = this.props;

    const leftPanelBackground =
      (currentCourse &&
        currentCourse.courseStyle &&
        currentCourse.courseStyle.leftPanelBackground) ||
      "none";
    const rightPanelBackground =
      (currentCourse &&
        currentCourse.courseStyle &&
        currentCourse.courseStyle.rightPanelBackground) ||
      "none";
    const contentBackgroundColor =
      (currentCourse &&
        currentCourse.courseStyle &&
        currentCourse.courseStyle.contentBackgroundColor) ||
      "#ffffff";

    if (loadingUserPaymentInfo) {
      return <Loader />;
    }

    return (
      <DocumentTitle title={t("dashboard.title")}>
        <div className="dashboard">
          <StyledNavigationContainer
            className="course-navigation"
            background={leftPanelBackground}
          >
            <CourseNavigation />
          </StyledNavigationContainer>
          <StyledContentContainer
            className="content"
            color={contentBackgroundColor}
          >
            {hasAccessToCourses ? (
              <Switch>
                <Route exact path="*/dashboard/profile" component={Profile} />
                <Route
                  exact
                  path="*/dashboard/courses"
                  component={CoursesList}
                />
                <Route path="*/dashboard/courses/:courseId" component={Course} />
                <Redirect to="/dashboard/courses" />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path="*/dashboard/courses"
                  component={CoursesList}
                />
                <Route
                  exact
                  path="*/dashboard"
                  component={PaymentRequiredModal}
                />
                <Route
                  exact
                  path="*/dashboard/payment"
                  component={PaymentModal}
                />
                <Redirect to="/dashboard" />
              </Switch>
            )}
          </StyledContentContainer>
          <StyledNavigationContainer
            className="menu-navigation"
            background={rightPanelBackground}
          >
            <MenuNavigation />
          </StyledNavigationContainer>

          <div className="mobile-navigation">
            <CourseNavigation />
            <MenuNavigation />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCourse: state.ui.currentCourse,
  hasAccessToCourses: state.user.user.hasAccessToCourses,
  loadingUserPaymentInfo: state.user.getUserPaymentInfo.pending,
  payment: state.user.user.payment,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPaymentInfo: () => dispatch(getUserPaymentInfo()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Dashboard);
