import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
import DocumentTitle from 'react-document-title';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
// assets
import { bold, capitalize, italics } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
import { languages, roles } from 'assets/strings';
import { compareUsers } from 'assets/utils';
// styles
import { Button, Container, Dropdown, Header, Icon, Input, List, Loader } from 'semantic-ui-react';
// components
// redux

const classLanguageOptions = [
  {
    key: 'ru',
    text: 'русский',
    value: 'ru'
  },
  {
    key: 'kk',
    text: 'казахский',
    value: 'kk'
  },
  {
    key: 'en',
    text: 'английский',
    value: 'en'
  }
];

const StyledClassFormationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

const StyledMoveButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledListContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const StyledListHeader = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;

const StyledList = styled.div`
  height: ${props => `${props.height}px`};
  overflow-y: auto;
  border: 1px solid #cecece;
`;

const StyledListItem = styled.div`
  padding: 8px;
  width: 100%;
  background-color: ${props => props.isSelected ? '#efefef' : '#fff'};
  border: 1px solid #cecece;
`;

const StyledSearch = styled.input`
  padding: 8px;
  width: 100%;
`;

const StyledStudentsList = styled(List)`
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
`;

const StyledStudentHeader = styled(List.Header)`
  margin-bottom: 8px;
  color: #3697dd;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

function LoaderComponent() {
  return <Loader active size="large" />;
}

function Class({ history, match, t }) {
  const [isLoading, setLoading] = useState(false);
  const [currentClass, setCurrentClass] = useState({
    name: '',
    description: '',
    language: 'ru',
    students: []
  });
  const [allStudents, setAllStudents] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedLeftStudents, setSelectedLeftStudents] = useState([]);
  const [selectedRightStudents, setSelectedRightStudents] = useState([]);

  useEffect(() => {
    Promise
      .all([fetchAllStudents(), fetchClass(match.params.id)])
      .then(() => setLoading(false))
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  function fetchAllStudents() {
    return new Promise((resolve, reject) => {
      setLoading(true);

      axios
        .get(adminRoutes.users.getUsers())
        .then(response => {
          setAllStudents(response.data.message);
          resolve();
        })
        .catch(err => reject(err.response));
    });
  }

  function fetchClass(id) {
    return new Promise((resolve, reject) => {
      setLoading(true);
  
      axios
        .get(adminRoutes.classes.getClass(id))
        .then(response => {
          setCurrentClass(response.data.message);
          resolve();
        })
        .catch(err => reject(err.response));
    })
  }

  function editClass() {
    const r = window.confirm(t("admin.classes.editThisClassConfirm"));
    if (r) {
      const { _id = '', name = '', description = '', language = '', students = [] } = currentClass;
      // validate fields
      if (!_id) {
        alert(t("admin.classes.classLoadingError"));
        return;
      }
      if (!name || !language) {
        alert(t("admin.classes.nameAndLanguageRequired"));
        return;
      }

      setLoading(true);

      axios
        .post(adminRoutes.classes.editClass(_id), {
          class: {
            name: name,
            description: description,
            language: language,
            students: students
          }
        })
        .then(() => fetchClass(_id))
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
  }

  function deleteClass() {
    const r = window.confirm(t("admin.classes.deleteThisClassConfirm"));
    if (r) {
      const { _id = '' } = currentClass;
      // validate fields
      if (!_id) {
        alert(t("admin.classes.classLoadingError"));
        return;
      }

      setLoading(true);

      axios
        .delete(adminRoutes.classes.deleteClass(_id))
        .then(() => history.push('/admin/classes'))
        .catch(err => {
          alert(t("admin.classes.errorWhileDeleting"));
          console.error(err);
          setLoading(false);
        });
    }
  }

  function moveFromLeftToRight() {
    // update class property for selected students
    const newAllStudents = allStudents.map(student => {
      if (selectedLeftStudents.includes(student._id)) {
        return {
          ...student,
          class: currentClass._id
        }
      }
      return { ...student };
    });
    setAllStudents(newAllStudents);

    const newClassStudents = [...currentClass.students, ...selectedLeftStudents];
    setCurrentClass(prevCurrentClass => ({
      ...prevCurrentClass,
      students: newClassStudents
    }));
    setSelectedLeftStudents([]);
    setSelectedRightStudents([]);
  }

  function moveFromRightToLeft() {
    // set class property to null for selected students
    const newAllStudents = allStudents.map(student => {
      if (selectedRightStudents.includes(student._id)) {
        return {
          ...student,
          class: null
        }
      }
      return { ...student };
    });
    setAllStudents(newAllStudents);

    const classStudents = [...currentClass.students];
    const newClassStudents = classStudents.filter(item => !selectedRightStudents.includes(item));
    setCurrentClass(prevCurrentClass => ({
      ...prevCurrentClass,
      students: newClassStudents
    }));
    setSelectedLeftStudents([]);
    setSelectedRightStudents([]);
  }

  const allStudentItems = useMemo(() => {
    const availableStudents = allStudents.filter(student => !student.class && !currentClass.students.includes(student._id));
    const searchStudents = availableStudents.filter(student => {
      let name = `${student.lastName} ${student.firstName} ${student.email}`.toLowerCase();
      return name.includes(searchString.toLowerCase());
    });
    const sortedStudents = searchStudents.sort(compareUsers);

    return sortedStudents.map((student, index) => {
      const isSelected = selectedLeftStudents.includes(student._id);

      return (
        <StyledListItem 
          key={`admin-teacher-students-${index}`}
          isSelected={isSelected}
          onClick={() => {
            let newSelectedLeftStudents = [...selectedLeftStudents];
            if (isSelected) {
              newSelectedLeftStudents = newSelectedLeftStudents.filter(item => item !== student._id);
            } else {
              newSelectedLeftStudents.push(student._id);
            }
            setSelectedLeftStudents(newSelectedLeftStudents);
          }}
        >
          {`${student.lastName} ${student.firstName} (${student.email})`}
        </StyledListItem>
      );
    });
  }, [allStudents, currentClass, searchString, selectedLeftStudents]);

  const classStudentItems = useMemo(() => {
    if (!allStudents.length) return [];

    const classStudents = currentClass.students.map(id => allStudents.find(item => item._id === id));
    const sortedStudents = classStudents.sort(compareUsers);

    return sortedStudents.map((student, index) => {
      const isSelected = selectedRightStudents.includes(student._id);

      return (
        <StyledListItem 
          key={`admin-class-students-${index}`}
          isSelected={isSelected}
          onClick={() => {
            let newSelectedRightStudents = [...selectedRightStudents];
            if (isSelected) {
              newSelectedRightStudents = newSelectedRightStudents.filter(item => item !== student._id);
            } else {
              newSelectedRightStudents.push(student._id);
            }
            setSelectedRightStudents(newSelectedRightStudents);
          }}
        >
          {`${student.lastName} ${student.firstName} (${student.email})`}
        </StyledListItem>
      );
    });
  }, [allStudents, currentClass, selectedRightStudents]);

  const studentItems = useMemo(() => {
    if (!allStudents.length) return [];

    const students = currentClass.students.map(id => allStudents.find(item => item._id === id));
    const filteredStudents = students.filter(student => student.scope === 'user' || student.scope === 'expert');
    const searchStudents = filteredStudents.filter(students => {
      let name = `${students.lastName} ${students.firstName} ${students.email}`.toLowerCase();
      return name.includes(searchString.toLowerCase());
    });
    const sortedStudents = searchStudents.sort(compareUsers);
    
    return sortedStudents.map((student, index) => {
      const verified = student.verification ? student.verification.status : false;
      const paid = student.payment ? student.payment.completed : false;

      return (
        <List.Item key={`admin-students-${index}`}>
          <List.Icon name="user" />
          <List.Content>
            <StyledStudentHeader onClick={() => history.push(`/admin/users/${student.email}`)}>
              {`${student.lastName} ${student.firstName}`}
            </StyledStudentHeader>
            <List.Description>
              {italics(capitalize(roles[student.scope]))}
              {student.email}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });
  }, [allStudents, currentClass]);

  return (
    <DocumentTitle title={t("admin.classes.title")}>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <Container>
          <Button basic color="blue" onClick={() => history.push('/admin/classes')}>
            {t("admin.classes.backToClasses")}
          </Button>

          <Header size="large" textAlign="center">
            {currentClass && currentClass.name ? currentClass.name : t("admin.classes.classLoading")}
          </Header>

          {bold(t("admin.classes.new.className"))}
          <Input
            fluid
            name="name"
            placeholder={t("admin.classes.new.className")}
            value={currentClass.name}
            onChange={(e, data) => {
              setCurrentClass(prevCurrentClass => ({
                ...prevCurrentClass,
                name: data.value
              }));
            }}
            style={{ marginBottom: '1em' }}
          />

          {bold(t("admin.classes.new.classDesc"))}
          <Input
            fluid
            name="description"
            placeholder={t("admin.classes.new.classDesc")}
            value={currentClass.description}
            onChange={(e, data) => {
              setCurrentClass(prevCurrentClass => ({
                ...prevCurrentClass,
                description: data.value
              }));
            }}
            style={{ marginBottom: '1em' }}
          />

          {bold(t("admin.classes.new.lang"))}
          <Dropdown
            fluid
            selection
            name="language"
            placeholder="Фамилия"
            value={currentClass.language}
            options={classLanguageOptions}
            onChange={(e, data) => {
              setCurrentClass(prevCurrentClass => ({
                ...prevCurrentClass,
                language: data.value
              }));
            }}
            style={{ marginBottom: '1em' }}
          />

          <Header size="medium" textAlign="center" style={{ marginBottom: '32px' }}>
            {t("admin.classes.listStudents")}
          </Header>
          <StyledStudentsList divided relaxed="very">
            {studentItems}
          </StyledStudentsList>

          <Header size="medium" textAlign="center">
            {t("admin.classes.classFormation")}
          </Header>
          <StyledClassFormationContainer>
            <StyledListContainer>
              <StyledListHeader>{t("admin.classes.listAvailableStudents")}</StyledListHeader>
              <StyledSearch 
                placeholder={t("admin.users.searchForStudents")}
                value={searchString}
                onChange={event => {
                  const { value } = event.target;
                  setSearchString(value);
                }}
              />
              <StyledList height={444}>
                {allStudentItems}
              </StyledList>
            </StyledListContainer>
            <StyledMoveButtonsContainer>
              <Button icon onClick={moveFromLeftToRight}>
                <Icon name="angle double right" />
              </Button>
              <Button icon onClick={moveFromRightToLeft}>
                <Icon name="angle double left" />
              </Button>
            </StyledMoveButtonsContainer>
            <StyledListContainer>
              <StyledListHeader>{t("admin.classes.listStudentsAddedtotheClass")}</StyledListHeader>
              <StyledList height={480}>
                {classStudentItems}
              </StyledList>
            </StyledListContainer>
          </StyledClassFormationContainer>

          <Button
            type="button"
            onClick={editClass}
            style={{
              backgroundColor: '#0e79b2',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            {t("admin.classes.editClass")}
          </Button>
          <Button
            type="button"
            onClick={deleteClass}
            style={{
              backgroundColor: '#cc515d',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            {t("admin.classes.deleteClass")}
          </Button>
        </Container>
      )}
    </DocumentTitle>
  );
}

export default compose(
  withTranslation()
)(Class);