// modules
import React from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// components
import MathjaxWrapper from 'components/MathjaxWrapper';


const ExtraMaterialModal = ({ onClose, open, t, text }) => {
    const history = useHistory();

    const onModalClose = () => {
        // remove hash and search params from URL
        const queryParams = new URLSearchParams();
        history.replace({
            search: queryParams.toString(),
        });

        onClose();
    };

    const goToWord = () => {
        const str = window.location.search.substring(window.location.search.lastIndexOf('=') + 1);

        if (str) {
            setTimeout(() => {
                const elements = document.querySelectorAll(`[data-name='${str}']`);
                if (elements) {
                    elements.forEach(el => {
                        el.scrollIntoView({behavior: 'smooth'});
                        el.classList.add('active')
                        setTimeout(function(){
                            el.classList.remove('active');
                        }, 5000);
                    })
                }
            }, 1000);
        }
    }
    goToWord()

    return (
        <Modal closeIcon open={open} onClose={onModalClose} size="large">
            <Modal.Header>{t('dashboard.course.visualization.hint.extraMaterial')}</Modal.Header>
            <Modal.Content className="ck-content" style={{ width: '100%',}}>
                <MathjaxWrapper text={ReactHtmlParser(text)} />
            </Modal.Content>
        </Modal>
    );
}

export default withTranslation()(ExtraMaterialModal);
