// modules
import React, { Component } from 'react';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox, Container, Input, Message, TextArea } from 'semantic-ui-react';
// components
import ReactAudioPlayer from 'react-audio-player';
import AlternativeEditor from 'components/AlternativeEditor';
import { AddSlotButton, InteractiveSingleSlot } from '../InteractiveQuestionsCommon';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

class InteractiveFillSlotsBlock extends Component {
  constructor(props) {
    super(props);

    const { new: isNew, block } = this.props;
    const isExistingBlock = !isNew && block;
    const interactive = isExistingBlock ? JSON.parse(block.interactive || '""') : {};

    this.state = {
      block: isExistingBlock ? block : {
        text: '',
        audio: '',
        hint: '',
        recTime: '',
        correctText: '',
        wrongText: '',
        hintingText: '',
        testQuestion: false,
        highlighted: false,
      },
      interactive: {
        slots: interactive.slots || [''],
      },
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.block && this.props.block.audio && prevProps.block && this.props.block.audio !== prevProps.block.audio) {
      this.setState(prevState => ({
        block: {
          ...prevState.block,
          audio: this.props.block.audio,
        },
      }));
    }
  }

  handleDeleteAudio = () => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'highlighted' || data.name === 'testQuestion') {
      result = data.checked;
    }

    this.setState(prevState => ({
      block: {
        ...prevState.block,
        [data.name]: result,
      }
    }));
  };

  handleChangeSlot = (e, data) => {
    const updatedSlots = [...this.state.interactive.slots];
    updatedSlots.splice(data.index, 1, data.value);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        slots: updatedSlots,
      },
    }));
  }

  handleAddSlot = () => {
    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        slots: [...prevState.interactive.slots, ''],
      },
    }));
  }

  handleDeleteSlot = (index) => {
    const updatedSlots = [...this.state.interactive.slots];
    updatedSlots.splice(index, 1);

    this.setState(prevState => ({
      interactive: {
        ...prevState.interactive,
        slots: updatedSlots,
      },
    }));
  }

  handleChoseImage = (e) => {
    alert('Это упражнение временно не поддерживает изображения')
    // var input = e.target.closest('.ui.input')
    // input.querySelector('input').type = 'file';
    // input.querySelector('input').onchange = e => { 
    //   var file = e.target.files[0]; 
    //   var reader = new FileReader();
    //   reader.onload = () => {
    //     var content = `<img src="${reader.result}">`
    //     input.querySelector('input').type = 'text'
    //     input.querySelector('input').value = content

    //     const updatedSlots = [...this.state.interactive.slots];
    //     updatedSlots.splice(input.getAttribute('index'), 1, content);

    //     this.setState(prevState => ({
    //       interactive: {
    //         ...prevState.interactive,
    //         slots: updatedSlots,
    //       },
    //     }));  
    //   }
    //   reader.readAsDataURL(file);
    // }
    // input.querySelector('input').click();
  }

  handleAddBlock = () => {
    this.props.handleAdd(this.state.block, this.state.interactive);
  }

  handleEditBlock = () => {
    this.props.handleEdit(this.state.block, this.state.interactive);
  }

  render() {
    let questionTextHint = '';
    switch (this.props.block.blockType) {
      case 'question-interactive-fill-slots':
      case 'question-interactive-fill-from-list':
        questionTextHint = 'Впишите текст, который должен быть вставлен. Используйте символы -1-, -2- и т.д. для обозначения места вставки текста. Вы можете использовать одни и те же номера для полей вставки одинаковых слов в данном тексте.';
        break;
      case 'question-interactive-find-matches':
        questionTextHint = 'Впишите текст, который должен быть вставлен. Используйте символы -1-, -2- и т.д. для обозначения места вставки текста.';
        break;
      case 'question-interactive-select-words':
        questionTextHint = 'Впишите текст, который должен быть вставлен. Оберните слова, которые должны быть выделены учеником в символы "---" с обеих сторон, например: На небе сияли крупные ---звезды---.';
        break;
      default:
        questionTextHint = '';
    }

    let questionInstructions = '';
    switch (this.props.block.blockType) {
      case 'question-interactive-fill-slots':
        questionInstructions = 'Впишите для каждого пропуска все возможные варианты вставки через знак ; (точка с запятой).';
        break;
      case 'question-interactive-fill-from-list':
        questionInstructions = 'Впишите верный вариант (тогда будет предоставлен список из всех указанных пропусков) или список слов для выбора через знак ; (точка с запятой) для каждого пропуска (укажите правильный ответ первым)';
        break;
      case 'question-interactive-find-matches':
        questionInstructions = 'Заполните каждый пропуск в соответствии с нумерацией в задании';
        break;
      default:
        questionInstructions = '';
    }

    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold(questionTextHint)}
          <AlternativeEditor editorType="full" data={this.state.block.text} onChange={this.handleTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Аудио-вопрос')}
          {this.state.block.audio ? (
            <div style={{ marginBottom: '2em' }}>
              <ReactAudioPlayer src={this.state.block.audio} controls />
              <br />
              <br />
              <Button negative onClick={this.handleDeleteAudio}>
                Удалить аудио вопрос
              </Button>
            </div>
          ) : null}
          <input type="file" onChange={this.props.handleAudioSelect} />
        </div>

        {this.props.block.blockType !== 'question-interactive-select-words' && (
          <div style={{ marginBottom: '2em' }}>
            {bold(questionInstructions)}
            {this.state.interactive.slots.map((value, index) => (
              <InteractiveSingleSlot
                key={`interactive-fill-slot-${index}`}
                value={value}
                index={index}
                handleChange={this.handleChangeSlot}
                handleDelete={this.handleDeleteSlot}
                handleChoseImage={this.handleChoseImage}
              />
            ))}

            <AddSlotButton onClick={this.handleAddSlot}>
              Добавить следующий элемент
            </AddSlotButton>
          </div>
        )}

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}

          <Container>
            <Checkbox
              label="Подсветить блок?"
              toggle
              name="highlighted"
              checked={this.state.block.highlighted}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          <Container>
            <Checkbox
              label="Тестовый вопрос?"
              toggle
              name="testQuestion"
              checked={this.state.block.testQuestion}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
        </div>

        {bold('Рекоммендуемое время:')}
        <Input fluid name="recTime" type="number" value={this.state.block.recTime || ''} onChange={this.handleChange} />
        <Message
          info
          icon="stopwatch"
          header="Время решения вопроса"
          content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя"
        />

        <div>
          {bold('Подсказка:')}
          <TextArea
            autoHeight
            name="hint"
            value={this.state.block.hint}
            onChange={this.handleChange}
            style={{ width: '100%', minHeight: 100 }}
          />

          {bold('Текст при правильном ответе:')}
          <TextArea
            autoHeight
            name="correctText"
            value={this.state.block.correctText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />

          {this.state.block.testQuestion ? null : (
            <div>
              {bold('Текст-подсказка при первом неправильном ответе:')}
              <TextArea
                autoHeight
                name="hintingText"
                value={this.state.block.hintingText}
                onChange={this.handleChange}
                style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
              />
            </div>
          )}

          {bold('Текст при неправильном ответе:')}
          <TextArea
            autoHeight
            name="wrongText"
            value={this.state.block.wrongText}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>

        {this.props.new ? (
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={this.handleAddBlock}
            style={addStyle}
          >
            Добавить блок
          </Button>
        ) : (
          <>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.props.editing}
              onClick={this.handleEditBlock}
              style={editStyle}
            >
              Редактировать блок
            </Button>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.props.deleting}
              onClick={this.props.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default InteractiveFillSlotsBlock;
