// modules
import React, { Component } from 'react';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { bold, header } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Container, Input, Message } from 'semantic-ui-react';
// components
// redux

const redirect = (context, path) => {
  context.props.history.push(path);
};

class NewUser extends Component {
  state = {
    user: {
      email: '',
      password: '',
      lastName: '',
      firstName: ''
    },
    error: {
      status: false,
      msg: ''
    }
  };

  handleChange = (e, data) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  handleAddUser = () => {
    // show alert if fields are empty
    if (!this.state.user.email || !this.state.user.password || !this.state.user.lastName || !this.state.user.firstName) {
      alert('Не все поля заполнены');
      return;
    }

    // ask confirmation
    const r = window.confirm('Создать нового ученика?');
    if (r) {
      // add user
      axios
        .post(adminRoutes.users.createUser(), { user: { ...this.state.user } })
        .then(() => {
          redirect(this, '/admin/users');
        })
        .catch(() => {
          this.setState({
            error: {
              status: true,
              msg: 'Данный логин уже занят'
            }
          });
        });
    }
  };

  render() {
    const { email, password, firstName, lastName } = this.state.user;

    return (
      <DocumentTitle title="Добавить нового пользователя">
        <Container>
          <Button basic color="blue" onClick={() => redirect(this, '/admin/users')}>
            Вернуться к ученикам
          </Button>

          {header('Добавить нового ученика', '24px', 600)}
          {bold('Логин')}
          <Input
            fluid
            required
            name="email"
            placeholder="Логин"
            value={email}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Пароль')}
          <Input
            fluid
            required
            name="password"
            placeholder="Пароль"
            value={password}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Фамилия')}
          <Input
            fluid
            required
            name="lastName"
            placeholder="Фамилия"
            value={lastName}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold('Имя')}
          <Input
            fluid
            required
            name="firstName"
            placeholder="Имя"
            value={firstName}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {this.state.error.status && (
            <Message negative>
              <Message.Header>{this.state.error.msg}</Message.Header>
            </Message>
          )}

          <Button
            type="button"
            onClick={this.handleAddUser}
            style={{
              backgroundColor: '#0e79b2',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            Добавить ученика
          </Button>
        </Container>
      </DocumentTitle>
    );
  }
}

export default NewUser;
