// modules
import 'animate.css';
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
// styles
import './WelcomePage.css';

// components
import Modal from './Modal'; // Путь к компоненту Modal

import { WrappedLoginModal as LoginModal } from "./LoginModal";
import { WrappedRegisterModal as RegistrationForm } from "./RegistrationForm";
import {useColor} from '../Utils/ColorContext';
import mobpk from '../../../assets/images/landing/mobpk.svg'
import komp from '../../../assets/images/landing/komp.svg'

// utils
import kundelikIntegration from '../../../services/kundelik-integration';
import { userRoles } from "../../../assets/utils";

// redux
import { loginWithKundelik, logout } from "../../../store/User";

const WelcomePage = ({ history, t }) => {
    const dispatch = useDispatch();
    
    const [isHovered, setIsHovered] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isRegistrationModalOpen, setRegistrationModalOpen] = useState(false);
    const [kundelikId, setKundelikId] = useState('');

    const {color} = useColor();

    const isAuthenticated = useSelector((state) =>
        Boolean(!!state.user.user.email)
    );

    const role = useSelector((state) => state.user.user.scope);

    const loginWithKundelikDone = useSelector((state) => state.user.loginWithKundelik.done);

    const headerStyle = {
        backgroundColor: color === '#3C3C56' ? color : 'white',
        color: color === '#3C3C56' ? '#e6f5ff' : 'black', // Если фон #3C3C56, то текст белый, иначе черный
        // другие стили заголовка
        transition: 'background-color 1s, color 1s, box-shadow 1s',
    };
    const headerTextStyle = {
        color: color === '#3C3C56' ? 'white' : '#00B9F8',
        transition: 'background-color 1s, color 1s',
        // другие стили для текста заголовка
    };

    const openLoginModal = (e) => {
        e.preventDefault()
        setLoginModalOpen(true);
    };

    const openRegistrationModal = (e) => {
        e.preventDefault();
        setKundelikId('');
        setRegistrationModalOpen(true);
    };
    const headerButStyle = {
        backgroundColor: color === '#3C3C56' ? 'white' : '#00B9F8',
        color: color === '#3C3C56' ? '#00B9F8' : 'white',
        transition: 'background-color 1s, color 1s, box-shadow 1s',
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        const checkKundelikTokenInUrl = async () => {
          const { hash } = history.location;
    
          // check if access_token exists in URL string
          if (hash.length > 0) {
            const hashParams = new URLSearchParams(hash.substring(1));
            if (hashParams.has('access_token')) {
              const token = hashParams.get('access_token');
              const user = await kundelikIntegration.getCurrentUser(token);
              
              if (user) {
                const doesUserHaveKundelikId = await kundelikIntegration.checkEkitapUserForKundelikId(user.id_str);
    
                if (doesUserHaveKundelikId) {
                  localStorage.setItem('kundelik-token', token);
                  dispatch(loginWithKundelik(user.id_str, token));
                } else {
                  setKundelikId(user.id_str);
                  setLoginModalOpen(true);
                  // setRegistrationModalOpen(true);
                }
    
                window.history.replaceState(null, '', window.location.origin);
              }
            }
          }
        }
    
        const checkHomeworkContextInUrl = () => {
          const { search } = history.location;
    
          // check if homework assigning context is given
          const searchParams = new URLSearchParams(search);
          if (searchParams.has('external_login', 'kundelik')) {
            // store Kundelik homework context in session storage
            const homeworkContext = {
              group_id: searchParams.get('group_id'),
              subject_id: searchParams.get('subject_id'),
              lesson_id: searchParams.get('lesson_id'),
              worktype_id: searchParams.get('worktype_id'),
            };
            sessionStorage.setItem('homework-context', JSON.stringify(homeworkContext));
    
            // check if teacher is authenticated with Kundelik
            kundelikIntegration.checkAuthentication();
          }
        }
    
        checkKundelikTokenInUrl();
        checkHomeworkContextInUrl();
      }, []);

    useEffect(() => {
        if (loginWithKundelikDone && role) {
          const redirectUrl = userRoles.includes(role) ? '/dashboard/courses' : '/admin';
          history.push(redirectUrl);
        }
    }, [loginWithKundelikDone, role]);

    return (
        <div className="WelcomePage" style={headerStyle}>
            <div className='Info'>

                <div className='Info-left , Info-left animate__animated animate__slideInLeft'>
                    <div className='Info-text' style={headerTextStyle}>
                        <span>{t("landing.header1") + " " + t("landing.header2")}</span>
                    </div>
                    {isAuthenticated ? (
                        <div className='registration' >
                        <button style={headerButStyle}
                            className={`come ${isHovered ? 'hovered' : ''}`}
                            onClick={() =>
                                history.push(
                                  userRoles.includes(role) ? "/dashboard/courses" : "/admin"
                                )
                            }
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                               {userRoles.includes(role) ? t("landing.courseList") : t("landing.admin")}
                        </button>
                        <button className='Become-a-member'
                                onClick={() => dispatch(logout())}>{t("logout")}</button>
                        </div>
                    ) : (
                        <div className='registration' >
                        <button style={headerButStyle}
                            className={`come ${isHovered ? 'hovered' : ''}`}
                            onClick={openLoginModal}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                               {t("landing.login")}
                        </button>
                        {/* <button className='Become-a-member'
                                onClick={openRegistrationModal}>{t("landing.register")}</button> */}
                        </div>
                    )}
                    

                </div>
                <div className='Info-right , Info-right animate__animated animate__slideInRight'>
                    <div className="Untitled">
                        <img className='fullpkimg' src={komp} alt="Untitled"/>
                        <img className='mobpkimg' src={mobpk} alt="mobpk"/>
                    </div>
                </div>
            </div>


            {isLoginModalOpen && (
                <Modal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)}>
                    <LoginModal kundelikId={kundelikId} />
                </Modal>
            )}

            {isRegistrationModalOpen && (
                <Modal isOpen={isRegistrationModalOpen} onClose={() => setRegistrationModalOpen(false)}>
                    <RegistrationForm kundelikId={kundelikId} />
                </Modal>
            )}

        </div>
    );
}
export default withRouter(withTranslation()(WelcomePage));
