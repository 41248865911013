// modules
import React from 'react';
import styled from 'styled-components';
// assets
// styles
import { Button, Input } from 'semantic-ui-react';
// components
// redux

const WordContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const WordInputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;

export default function ({ word = {}, index = 0, handleChange, handleDelete }) {
  return (
    <WordContainer>
      <WordInputContainer>
        <span style={{ marginRight: '8px', fontWeight: 'bold', cursor: 'pointer' }}>::</span>
        <Input
          placeholder='Введите слово'
          label='Слово'
          name='word'
          value={word.word || ''}
          index={index}
          onChange={handleChange}
        />
        <Button icon='delete' onClick={() => handleDelete(index)} />
      </WordInputContainer>

      <Input
          placeholder='Введите подсказку'
          label='Подсказка'
          name='hint'
          value={word.hint || ''}
          index={index}
          onChange={handleChange}
      />
    </WordContainer>
  );
}

