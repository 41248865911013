// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import { nanoid } from "nanoid";
// assets
import { dashboardRoutes } from "assets/routes";
import { SELECT_WORDS_CLASSNAME } from "assets/interactiveQuestions";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

// Version 1
// const WORD_REGEX = /(-{0}|-{3})([\p{Letter}-]+)(-{0}|-{3})/gu;
// Version 2
// const WORD_REGEX = /<*\/*[\p{Letter}-]+>*/gu;
// Version 3
const WORD_REGEX = /(<\/?[^>]+>)|(-*\p{Letter}+-*)/gu;
const CORRECT_WORD_REGEX = /---.*---/;

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

class InteractiveQuestion6 extends Component {
  constructor(props) {
    super(props);

    const { block } = this.props;
    const { text = '' } = block;
    

    this.state = {
      answered: !!block.answer,
      correct: block.correct,
      elapsed: block.time || 0,
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered,
    };
    this.correctWordIds = [];
    this.userAnswers = block.answer ? JSON.parse(block.answer) : [];

    const replacedText = text.replaceAll(WORD_REGEX, this.replaceWordsInText);
    this.renderedElements = ReactHtmlParser(replacedText);
  }

  componentDidMount() {
    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    }

    const blockId = this.props.block._id;
    const spanElements = document.querySelectorAll(`div.${SELECT_WORDS_CLASSNAME} span[data-blockid='${blockId}']`) || [];

    [...spanElements].forEach((element) => {
      element.addEventListener('click', this.onSpanClicked);
    });
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);

    const blockId = this.props.block._id;
    const spanElements = document.querySelectorAll(`div.${SELECT_WORDS_CLASSNAME} span[data-blockid='${blockId}']`) || [];

    [...spanElements].forEach((element) => {
      element.addEventListener('click', this.onSpanClicked);
    });
  }

  replaceWordsInText = (word) => {
    const isCorrectWord = CORRECT_WORD_REGEX.test(word);
    const wordWithoutDashes = word.replaceAll('---', '');
    const blockId = this.props.block._id;
    const wordId = nanoid(10);

    if (word.length !== 0 && word.charAt(0) === '<' && word.charAt(word.length - 1) === '>') {
      return word;
    }

    if (word === 'nbsp') {
      return word;
    }

    if (isCorrectWord) {
      this.correctWordIds.push(wordId);
    }

    let extraClass = '';
    if (this.state.answered && this.userAnswers.length !== 0 && wordWithoutDashes === this.userAnswers[0]) {
      extraClass = isCorrectWord ? ' selected correct' : ' selected incorrect';
      this.userAnswers = this.userAnswers.slice(1);
    }

    return `<span class='${extraClass}' data-blockid='${blockId}' data-wordid='${wordId}'>${wordWithoutDashes}</span>`;
  };

  onSpanClicked = (event) => {
    const { target: element } = event;

    if (this.state.answered) {
      return;
    }

    if (element.classList.contains('selected')) {
      element.classList.remove('selected');
    } else {
      element.classList.add('selected');
    }
  };

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { elapsed } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    // stop timer
    if (this.timer) clearInterval(this.timer);

    // collect user answers
    const wordHtmlElements = document.querySelectorAll(`div.${SELECT_WORDS_CLASSNAME} span.selected[data-blockid='${this.props.block._id}']`) || [];
    const wordElements = [...wordHtmlElements];
    this.userAnswers = wordElements.map((element) => element.textContent);

    let isCorrect = this.userAnswers.length !== 0 && this.userAnswers.length === this.correctWordIds.length;
    // handle coloring of user answers
    wordElements.forEach((element) => {
      const isCurrentCorrect = this.correctWordIds.includes(element.dataset.wordid);
      element.classList.add(isCurrentCorrect ? 'correct' : 'incorrect');

      if (!isCurrentCorrect && isCorrect) {
        isCorrect = false;
      }
    });

    // convert user answers array into a string
    const stringifiedAnswer = JSON.stringify(this.userAnswers);

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
      });
    });
  };

  onReanswerClick = () => {
    // handle de-coloring of user answers
    const selectedWordHtmlElements = document.querySelectorAll(`div.${SELECT_WORDS_CLASSNAME} span.selected[data-blockid='${this.props.block._id}']`) || [];
    [...selectedWordHtmlElements].forEach((element) => {
      element.classList.remove('correct');
      element.classList.remove('incorrect');
      element.classList.remove('selected');
    });

    this.userAnswers = [];
    this.setState({
      answered: false,
      correct: undefined,
      elapsed: 0,
      hasReanswered: true
    }, () => {
      // start the timer again
      this.startTimer();
    });
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className={`ck-content ${SELECT_WORDS_CLASSNAME}`} style={{ width: '100%' }}>
              <p>{this.renderedElements}</p>
            </div>
          </div>
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion6));
