import axios from "axios";
import { authRoutes } from "../assets/routes";

const OAUTH_BASE_URL = 'https://login.kundelik.kz/oauth2';
const OAUTH_RESPONSE_TYPE = 'token';
const OAUTH_CLIENT_ID = '3529304b1e5e471cabc8c20b7a72b7b2';
const OAUTH_PERMISSIONS = ['CommonInfo', 'FriendsAndRelatives', 'EducationalInfo'];

const API_BASE_URL = 'https://api.kundelik.kz/v2';

const EKITAP_DASHBOARD_BASE_URL = 'https://ekitap.kz/dashboard';

class KundelikIntegration {
    authenticate(redirectUri = window.location.origin) {
        window.location = `${OAUTH_BASE_URL}?response_type=${OAUTH_RESPONSE_TYPE}&client_id=${OAUTH_CLIENT_ID}&scope=${OAUTH_PERMISSIONS.join(',')}&redirect_uri=${redirectUri}`;
    }

    async getCurrentUser(token) {
        try {
            if (!token) throw Error('No token');

            const config = {
                headers: {
                    'Access-Token': token,
                },
            };

            const response = await axios
                .get(`${API_BASE_URL}/users/me`, config)
                .catch(() => {
                    throw Error('Error retrieving current user');
                });

            return response.data;
        } catch (error) {
            console.error(error.message);
            return null;
        }
    }

    checkEkitapUserForKundelikId(id) {
        return new Promise((resolve) => {
            axios
            .post(authRoutes.checkKundelikId(), { kundelikId: id })
            .then((response) => {
                resolve(response.status === 200);
            })
            .catch(() => {
                resolve(false);
            });
        });
    }

    checkAuthentication() {
        const kundelikToken = localStorage.getItem('kundelik-token');

        if (kundelikToken) {
            const user = this.getCurrentUser(kundelikToken);
            if (user) {
                window.history.replaceState(null, '', window.location.origin);
            } else {
                this.authenticate(window.location.origin);
            }
        }

        this.authenticate(window.location.origin);
    }

    async checkKundelikTokenAndLinkAccount(hash, callback, errorAlertMsg) {
        // check if access_token exists in URL string
        if (hash.length > 0) {
          const hashParams = new URLSearchParams(hash.substring(1));
          if (hashParams.has('access_token')) {
            const token = hashParams.get('access_token');
            const user = await this.getCurrentUser(token);
            
            if (user) {
              axios
                .post(authRoutes.linkKundelikId(), { kundelikId: user.id_str })
                .then(() => {
                  callback();
                })
                .catch((error) => {
                  if (error.response.status === 409) {
                    alert(errorAlertMsg);
                  }
                })
                .finally(() => {
                  window.history.replaceState(null, '', window.location.pathname);
                });
            }
          }
        }
      }

    canUserAssignHomework(role) {
        const homeworkContext = sessionStorage.getItem('homework-context');
        const isTeacher = role === 'teacher';
        const hasHomeworkContext = homeworkContext !== null;

        return isTeacher && hasHomeworkContext;
    }

    async assignHomework(courseId, lessonId, blockId) {
        return new Promise((resolve, reject) => {
            const kundelikToken = localStorage.getItem('kundelik-token');
            if (!kundelikToken) {
                reject('Попробуйте выбрать задание на платформе Kundelik еще раз');
            }

            const homeworkContextStr = sessionStorage.getItem('homework-context');
            if (!homeworkContextStr) {
                reject('Нет контекста домашнего задания');
            }

            const homeworkContext = JSON.parse(homeworkContextStr);
            const { group_id, subject_id, lesson_id, worktype_id } = homeworkContext;

            const payload = {
                eduGroup: group_id,
                subjectId: subject_id,
                lesson: lesson_id,
                workType: worktype_id,
                displayInJournal: true,
                isImportant: true,
                type: 'Homework',
                periodType: 'Semester',
                markType: 'Mark5',
                status: 'Sent',
                text: `${EKITAP_DASHBOARD_BASE_URL}/courses/${courseId}/lesson/${lessonId}#${blockId}`,
            };

            const config = {
                headers: {
                    'Access-Token': kundelikToken,
                },
            };
            axios
                .post(`${API_BASE_URL}/works`, payload, config)
                .then(() => {
                    sessionStorage.removeItem('homework-context');
                    resolve();
                });
        });
    }
}

export default new KundelikIntegration();
