import React, { useEffect, useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import styled from 'styled-components';
import { Icon  } from 'semantic-ui-react';

const StyledTextWrapper = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    padding: 4px;
    background-color: rgba(240, 240, 240);
    border-radius: 4px;
    cursor: pointer;
`;

export function transformTextToSpeechNodes(node) {
    if (node.type === 'tag' && node.name === 'text-to-speech') {
        return <TextToSpeech text={node.attribs.text} />;
      }
}

const TextToSpeech = ({ text }) => {
    const [speechText, setSpeechText] = useState('');

    const handleTextClick = () => {
        if ('speechSynthesis' in window && speechText) {
            const speech = new SpeechSynthesisUtterance(speechText);
            speech.lang = 'ru-RU';

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
            }
            window.speechSynthesis.speak(speech);
        }
    };

    useEffect(() => {
        if (text) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            setSpeechText(doc.documentElement.textContent);
        }
    }, [text]);

    return (
        <StyledTextWrapper onClick={handleTextClick}>
            {ReactHtmlParser(text)}
            <Icon name='volume up' size='small' />
        </StyledTextWrapper>
    );
};