// modules
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';

const ManualModal = ({ onClose, t }) => (
  <Modal size="small" open closeOnDimmerClick={false} onClose={onClose} >
    <div className="landing-modal">
      <div className="landing-modal-cross" onClick={onClose}>
        <img src={cross} alt="Cross icon" />
      </div>

      <div className="landing-modal-header">
        <span>{t('landing.header.manual')}</span>
      </div>

      <div className="landing-modal-manual">
        {ReactHtmlParser(t('landing.manual.content'))}
      </div>
    </div>
  </Modal>
);

export const WrappedManualModal = withTranslation()(ManualModal);
