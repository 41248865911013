// modules
import React from 'react';
import styled from 'styled-components';
// assets
// styles
import { Button, Icon, Input } from 'semantic-ui-react';
// components
// redux

const PairContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const PairElementInput = styled(Input)`
  width: 100%;

  & > input {
    flex: 1 0 0!important;
  }
`;

export default function ({ pair = {}, index = 0, handleChange, handleDelete, handleChoseImage }) {
  return (
    <PairContainer>
      <PairElementInput
        key={`interactive-pair-${index}-first`}
        placeholder='Введите первый элемент пары'
        label='Элемент №1'
        value={pair.first}
        index={index}
        order='first'
        onChange={handleChange}
        action={{ icon: 'image outline', onClick: handleChoseImage }}
      />
      <PairElementInput
      key={`interactive-pair-${index}-second`}
        placeholder='Введите второй элемент пары'
        label='Элемент №2'
        value={pair.second}
        index={index}
        order='second'
        onChange={handleChange}
        action={{ icon: 'image outline', onClick: handleChoseImage }}
      />
      <Button
        negative
        icon
        labelPosition='right'
        onClick={() => handleDelete(index)}
      >
        <Icon name='remove circle' />
        Удалить
      </Button>
    </PairContainer>
  );
}

