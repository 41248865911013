export const languages = {
    ru: 'русский',
    kk: 'казахский',
    en: 'английский'
};

export const roles = {
    admin: "администратор",
    author: "автор",
    teacher: "учитель",
    user: "ученик",
    expert: "эксперт"
};

export const blockTypes = {
    text: "текст",
    nickname: "ввод никнейма",
    video: "видео",
    picture: "изображение",
    dialogue: "диалог",
    indefiniteQuestion: "вопрос без правильного ответа",
    question: "вопрос",
    iframe: "iframe блок",
    interactive: "интерактивный блок",
    "question-interactive-fill-slots": "интерактив: заполнить пропуски",
    "question-interactive-fill-from-list": "интерактив: выбрать из списка",
    "question-interactive-classification": "интерактив: классификация",
    "question-interactive-find-pairs": "интерактив: найти пару",
    "question-interactive-find-matches": "интерактив: найти соответствия",
    "question-interactive-select-words": "интерактив: отметить слова",
    "question-interactive-guess-word": "интерактив: угадай слово",
    "question-interactive-word-search": "интерактив: поиск слов",
    "question-interactive-order-words": "интерактив: упорядочить слова"
};

export const userActions = { 
    none: "admin.userActions.none",

    registration: "admin.userActions.registration",
    verification: "admin.userActions.verification",
    login: "admin.userActions.login",
    "forgot password": "admin.userActions.forgot password",
    "reset password": "admin.userActions.reset password",
    "editing profile": "admin.userActions.editing profile",

    "loading lesson": "admin.userActions.loading lesson",
    "updating last lesson": "admin.userActions.updating last lesson",
    "updating lesson current block": "admin.userActions.updating lesson current block",
    answer: "admin.userActions.answer",

    "reset progress": "admin.userActions.reset progress",
};
