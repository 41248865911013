// modules
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
// components
import CircularProgressbar from 'react-circular-progressbar';
// styles
import 'react-circular-progressbar/dist/styles.css';

const ImageProgressbarWrapper = styled.div`
	position: relative;
	cursor: pointer;

	${media.lessThan('400px')`
		width: 36px;
		height: 36px;
	`}

	${media.between('400px', '600px')`
		width: 48px;
		height: 48px;
	`}

	${media.between('600px', '800px')`
		width: 56px;
		height: 56px;
	`}

	${media.greaterThan('800px')`
		width: 64px;
		height: 64px;
	`}
`;

const CircularProgressbarContainer = styled.div`
  position: absolute;
`;

const ImageContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	img {
		height: 48px;
		border-radius: 50%;

		${media.lessThan('400px')`
			width: 28px;
			height: 28px;
		`}

		${media.between('400px', '600px')`
			width: 36px;
			height: 36px;
		`}

		${media.between('600px', '800px')`
			width: 46px;
			height: 46px;
		`}

		${media.greaterThan('800px')`
			width: 52px;
			height: 52px;
		`}
	}
`;

const ImageProgressbar = ({ imgSrc, progress }) => (
  <ImageProgressbarWrapper onClick={() => (window.location = '/')}>
    <CircularProgressbarContainer>
      <CircularProgressbar strokeWidth={6} percentage={progress} />
    </CircularProgressbarContainer>
    <ImageContainer>
      <img src={imgSrc} alt="Deckard logo" />
    </ImageContainer>
  </ImageProgressbarWrapper>
);

export default ImageProgressbar;
