// modules
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
// components
import { Button, Modal } from "semantic-ui-react";
// styles
import "react-rangeslider/lib/index.css";
// assets
import cross from "assets/images/cross.svg";

const StyledModalHeader = styled.h2`
  margin: 0 0 16px 0;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  line-height: 140%;
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  &&& {
    padding: 16px;
    width: 100%;
    background-color: ${({ isCancel }) => (isCancel ? "#4682e0" : "#009b72")};
    color: white;
  }
`;

const PaymentRequiredModal = ({ t }) => {
  const [isError, setError] = useState(false);

  const history = useHistory();

  const onCloseClick = () => {
    history.push("/dashboard/courses");
  };

  const onContinueClick = () => {
    history.push("/dashboard/payment");
  };

  const onReloadClick = () => {
    window.location.href = "/dashboard";
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.get("redirect") === "true") {
      const order = searchParams.get("order");
      const mpi_order = searchParams.get("mpi_order");
      const rrn = searchParams.get("rrn");
      const res_code = searchParams.get("res_code");
      const amount = searchParams.get("amount");
      const currency = searchParams.get("currency");
      const res_desc = searchParams.get("res_desc");
      const sign = searchParams.get("sign");

      if (res_code !== "0") {
        setError(true);
        return;
      }

      axios
        .post("/api/payment", {
          order,
          mpi_order,
          rrn,
          res_code,
          amount,
          currency,
          res_desc,
          sign,
        })
        .then(() => {
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  return (
    <Modal size="tiny" open closeOnDimmerClick={false}>
      <div className="landing-modal">
        <div className="landing-modal-cross" onClick={onCloseClick}>
          <img src={cross} alt="Cross icon" />
        </div>

        <StyledModalContent>
          {isError ? (
            <>
              <StyledModalHeader>
                {t("dashboard.payment.error.title")}
              </StyledModalHeader>
              <StyledModalContent>
                <div>{t("dashboard.payment.error.content")}</div>
              </StyledModalContent>
              <StyledButton onClick={onReloadClick}>
                {t("dashboard.payment.error.action")}
              </StyledButton>
            </>
          ) : (
            <>
              <StyledModalHeader>
                {t("dashboard.payment.required.title")}
              </StyledModalHeader>
              <StyledModalContent>
                <div>{t("dashboard.payment.required.content")}</div>
              </StyledModalContent>
              <StyledButton onClick={onContinueClick}>
                {t("dashboard.payment.required.action")}
              </StyledButton>
            </>
          )}
        </StyledModalContent>
      </div>
    </Modal>
  );
};

export const WrappedPaymentRequiredModal = withTranslation()(
  PaymentRequiredModal
);
