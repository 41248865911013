// modules
import React from 'react';
import styled from 'styled-components';
// assets
// styles
import { Button, Icon, Input } from 'semantic-ui-react';
// components
// redux

const SlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const SlotInput = styled(Input)`
  width: 100%;
`;

export default function({ value = '', index = 0, groupIndex, handleChange, handleDelete, handleChoseImage }) {
  return (
    <SlotContainer>
      <SlotInput
        placeholder='Введите пропуск'
        label={`Пропуск -${index + 1}-:`}
        index={index}
        groupindex={groupIndex}
        value={value}
        onChange={handleChange}
        action={{ icon: 'image outline', onClick: handleChoseImage }}
      />
      <Button compact negative icon labelPosition='right' onClick={() => handleDelete(index, groupIndex)}>
        <Icon name='remove circle' />
        Удалить
      </Button>
    </SlotContainer>
  );
}
