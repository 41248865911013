// modules
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';


const editorConfiguration = {
  image: {
    toolbar: ['imageStyle:alignCenter', 'imageStyle:alignLeft', 'imageStyle:alignRight']
  },
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'subscript',
    'superscript',
    'specialCharacters',
    'removeFormat',
    '|',
    'fontBackgroundColor',
    'fontColor',
    'fontfamily',
    'fontsize',
    '|',
    'alignment',
    'outdent',
    'indent',
    '|',
    'link',
    'blockQuote',
    'code',
    'codeBlock',
    '|',
    'imageUpload',
    '|',
    'undo',
    'redo'
  ]
};

const colorPalette = [
  {
    color: '#D91E18',
    label: 'Красный'
  },
  {
    color: '#E67E22',
    label: 'Оранжевый'
  },
  {
    color: '#F9BF3B',
    label: 'Желтый'
  },
  {
    color: '#008a00',
    label: 'Зеленый'
  },
  {
    color: '#4C99E6',
    label: 'Голубой'
  },
  {
    color: '#1F3A93',
    label: 'Синий'
  },
  {
    color: '#F62496',
    label: 'Розовый'
  },
  {
    color: '#9A12B3',
    label: 'Фиолетовый'
  },
  {
    color: '#96411B',
    label: 'Коричневый'
  },
  {
    color: '#999999',
    label: 'Серый'
  },
  {
    color: '#ffffff',
    label: 'Белый',
    hasBorder: true
  },
  {
    color: '#000000',
    label: 'Черный'
  },
  {
    color: 'rgb(251,235,110)',
    label: 'Светло-желтый'
  },
  {
    color: 'rgb(148,86,163)',
    label: 'Пурпурный'
  },
  {
    color: 'rgb(163,12,17)',
    label: 'Бордовый'
  },
  {
    color: 'rgb(224,132,102)',
    label: 'Кораловый'
  },
  {
    color: 'rgb(249,240,221)',
    label: 'Бежевый'
  },
  {
    color: 'rgb(151,201,60)',
    label: 'Салатовый'
  },
  {
    color: 'rgb(251,201,170)',
    label: 'Персиковый'
  },
  {
    color: 'rgb(244,170,199)',
    label: 'Светло-розовый'
  },
  {
    color: 'rgb(204,167,206)',
    label: 'Светлая слива'
  },
  {
    color: 'rgb(161,211,147)',
    label: 'Бледно-салатовый'
  },
  {
    color: 'rgb(141,216,248)',
    label: 'Небесно-голубой'
  },
  {
    color: 'rgb(234,247,254)',
    label: 'Светло-серый',
    hasBorder: true
  },
];

const fullEditorConfiguration = {
  toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'subscript',
			'superscript',
			'|',
			'alignment',
			'numberedList',
			'bulletedList',
			'todoList',
			'outdent',
			'indent',
			'|',
			'imageUpload',
			'mediaEmbed',
			'insertTable',
			'specialCharacters',
      '|',
      'undo',
			'redo',
			'-',
			'style',
			'|',
			'fontBackgroundColor',
			'fontColor',
			'fontFamily',
			'fontSize',
			'|',
			'link',
			'highlight',
			'blockQuote',
			'code',
			'codeBlock',
			'htmlEmbed',
			'horizontalLine',
			'|',
			'sourceEditing',
			'findAndReplace',
			'removeFormat'
		],
		shouldNotGroupWhenFull: true
	},
	language: 'ru',
	image: {
    resizeOptions: [
      {
          name: 'resizeImage:original',
          label: 'Оригинал',
          value: null
      },
      {
          name: 'resizeImage:20',
          label: '20%',
          value: '20'
      },
      {
          name: 'resizeImage:40',
          label: '40%',
          value: '40'
      },
      {
          name: 'resizeImage:60',
          label: '60%',
          value: '60'
      },
      {
          name: 'resizeImage:80',
          label: '80%',
          value: '80'
      }
    ],
		toolbar: [
			'toggleImageCaption',
			'imageStyle:inline',
			'imageStyle:block',
      'imageStyle:wrapText',
			'linkImage',
      'resizeImage'
		]
	},
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells',
			'tableCellProperties',
			'tableProperties'
		]
	},
  fontColor: {
    colors: [...colorPalette]
  },
  fontBackgroundColor: {
    colors: [...colorPalette]
  },
  link: {
    decorators: {
        toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
                download: 'file'
            }
        },
        openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            defaultValue: true,
            attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
            }
        }
    }
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  htmlSupport: {
    allow: [
        {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
        }
    ]
  },
  style: {
    definitions: [
      {
        name: 'Упражнение V1',
        element: 'blockquote',
        classes: [ 'cs-exercise' ]
      },
      {
        name: 'Упражнение V2',
        element: 'blockquote',
        classes: [ 'cs-exercise-2' ]
      },
      {
        name: 'Упражнение V3',
        element: 'blockquote',
        classes: [ 'cs-exercise-3' ]
      },
      {
          name: 'Определение V1',
          element: 'blockquote',
          classes: [ 'cs-definition' ]
      },
      {
          name: 'Определение V2',
          element: 'blockquote',
          classes: [ 'cs-definition-2' ]
      },
      {
          name: 'Определение V3',
          element: 'blockquote',
          classes: [ 'cs-definition-3' ]
      },
      {
          name: 'Угловатый',
          element: 'blockquote',
          classes: [ 'cs-square' ]
      },
      {
          name: 'Скругленный',
          element: 'blockquote',
          classes: [ 'cs-rounded' ]
      },
      {
          name: 'Круглый',
          element: 'blockquote',
          classes: [ 'cs-rounded-full' ]
      },
      {
        name: 'Аквамарин',
        element: 'blockquote',
        classes: ['cs-color', 'cs-softcyan' ]
      },
      {
        name: 'Голубой',
        element: 'blockquote',
        classes: ['cs-color', 'cs-info' ]
      },
      {
        name: 'Синий',
        element: 'blockquote',
        classes: ['cs-color', 'cs-blue' ]
      },
      {
        name: 'Мятный',
        element: 'blockquote',
        classes: ['cs-color', 'cs-mint' ]
      },
      {
        name: 'Светло-зеленый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-success' ]
      },
      {
        name: 'Зеленый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-green' ]
      },
      {
        name: 'Желтый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-warning' ]
      },
      {
        name: 'Оранжевый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-orange' ]
      },
      {
        name: 'Коричневый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-brown' ]
      },
      {
        name: 'Розовый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-pink' ]
      },
      {
        name: 'Красный',
        element: 'blockquote',
        classes: ['cs-color', 'cs-error' ]
      },
      {
        name: 'Бордовый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-burgundy' ]
      },
      {
        name: 'Пурпурный',
        element: 'blockquote',
        classes: ['cs-color', 'cs-magenta' ]
      },
      {
        name: 'Фиолетовый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-purple' ]
      },
      {
        name: 'Индиго',
        element: 'blockquote',
        classes: ['cs-color', 'cs-indigo' ]
      },
      {
        name: 'Серый',
        element: 'blockquote',
        classes: ['cs-color', 'cs-grey' ]
      },
      {
        name: 'Стандарт',
        element: 'blockquote',
        classes: ['cs-color', 'cs-standart' ]
      },
      {
        name: 'перу',
        element: 'blockquote',
        classes: ['cs-color', 'cs-peru' ]
      },
      {
        name: 'Home',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-home' ]
      },
      {
        name: 'Mail',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-mail' ]
      },
      {
        name: 'Attach',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-attach' ]
      },
      {
        name: 'Award',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-award' ]
      },
      {
        name: 'Chart',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-chart' ]
      },
      {
        name: 'Bubble',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-bubble' ]
      },
      {
        name: 'Bookmark',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-bookmark' ]
      },
      {
        name: 'Calendar',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-calendar' ]
      },
      {
        name: 'Flag',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-flag' ]
      },
      {
        name: 'Link',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-link' ]
      },
      {
        name: 'Printer',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-print' ]
      },
      {
        name: 'Chat',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-chat' ]
      },
      {
        name: 'Chat 2',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-chat-2' ]
      },
      {
        name: 'Message',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-message' ]
      },
      {
        name: 'Edit',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-edit' ]
      },
      {
        name: 'Quil',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-quil' ]
      },
      {
        name: 'Pen',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-pen' ]
      },
      {
        name: 'Markup',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-markup' ]
      },
      {
        name: 'Ruler',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-ruler' ]
      },
      {
        name: 'Pencil Ruler',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-pen-ruler' ]
      },
      {
        name: 'Palette',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-palette' ]
      },
      {
        name: 'Magic',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-magic' ]
      },
      {
        name: 'Tools',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-tools' ]
      },
      {
        name: 'Grid',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-grid' ]
      },
      {
        name: 'Bug',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-bug' ]
      },
      {
        name: 'Cursor',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-cursor' ]
      },
      {
        name: 'Computer',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-computer' ]
      },
      {
        name: 'Save',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-save' ]
      },
      {
        name: 'Mouse',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-mouse' ]
      },
      {
        name: 'Restart',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-restart' ]
      },
      {
        name: 'Paper',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-paper' ]
      },
      {
        name: 'File list',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-file-list' ]
      },
      {
        name: 'Book',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-book' ]
      },
      {
        name: 'Align',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-align' ]
      },
      {
        name: 'Link',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-link' ]
      },
      {
        name: 'Quote',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-quote' ]
      },
      {
        name: 'Paragraph',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-paragraph' ]
      },
      {
        name: 'Asterisk',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-asterisk' ]
      },
      {
        name: 'Question',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-question' ]
      },
      {
        name: 'Exclamation',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-exclamation' ]
      },
      {
        name: 'Heart',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-heart' ]
      },
      {
        name: 'Flask',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-flask' ]
      },
      {
        name: 'Tube',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-tube' ]
      },
      {
        name: 'Microscope',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-microscope' ]
      },
      {
        name: 'Virus',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-virus' ]
      },
      {
        name: 'Map',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-map' ]
      },
      {
        name: 'Pushpin',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-pushpin' ]
      },
      {
        name: 'Rocket',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-rocket' ]
      },
      {
        name: 'Globe',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-globe' ]
      },
      {
        name: 'Compass',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-compass' ]
      },
      {
        name: 'Anchor',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-anchor' ]
      },
      {
        name: 'Image',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-image' ]
      },
      {
        name: 'Video',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-video' ]
      },
      {
        name: 'Music',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-music' ]
      },
      {
        name: 'Headphone',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-headphone' ]
      },
      {
        name: 'Mic',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-mic' ]
      },
      {
        name: 'Volume',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-volume' ]
      },
      {
        name: 'Notification',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-notify' ]
      },
      {
        name: 'Repeat',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-repeat' ]
      },
      {
        name: 'Star',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-star' ]
      },
      {
        name: 'Cog',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-cog' ]
      },
      {
        name: 'Information',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-info' ]
      },
      {
        name: 'Warning',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-warning' ]
      },
      {
        name: 'Alert',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-alert' ]
      },
      {
        name: 'Check',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-check' ]
      },
      {
        name: 'Refresh',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-refresh' ]
      },
      {
        name: 'Shield',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-shield' ]
      },
      {
        name: 'Eye',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-eye' ]
      },
      {
        name: 'Search',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-search' ]
      },
      {
        name: 'Share',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-share' ]
      },
      {
        name: 'Timer',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-timer' ]
      },
      {
        name: 'User',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-user' ]
      },
      {
        name: 'Fire',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-fire' ]
      },
      {
        name: 'Bulb',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-bulb' ]
      },
      {
        name: 'Temp',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-temp' ]
      },
      {
        name: 'Earth',
        element: 'blockquote',
        classes: [ 'cs-icon', 'ic-earth' ]
      },
    ]
  },
};


const answerOptionEditorConfiguration = {
  toolbar: [
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'subscript',
    'superscript',
    'specialCharacters',
    'removeFormat',
    '|',
    'fontBackgroundColor',
    'fontColor',
    'fontfamily',
    'fontsize',
    '|',
    'alignment',
    'outdent',
    'indent',
    '|',
    'link',
    'blockQuote',
    'code',
    'codeBlock',
    '|',
    'undo',
    'redo'
  ]
};

const editorConfigurationMapping = {
  default: editorConfiguration,
  full: fullEditorConfiguration,
  answerOption: answerOptionEditorConfiguration
};

const Editor = ({ editorType = 'default', data, onChange }) => {
  return (
    <div>
      <CKEditor
        editor={CustomEditor}
        config={editorConfigurationMapping[editorType]}
        data={data}
        onChange={(event, editor) => {
          onChange(editor.getData());
        }}
      />
    </div>
  );
};

export default Editor;
