// modules
import React from "react";
import AudioPlayer from "react-h5-audio-player";
// assets
// styles
import "react-h5-audio-player/lib/styles.css";
// components
// redux

const CustomAudioPlayer = ({ url }) => {
    const baseUrl = "https://ekitap.kz";
    // Uncomment when testing on local server
    // const baseUrl = "http://localhost:8080";

    return <AudioPlayer src={`${baseUrl}${url}`} showJumpControls={false} />;
};

export default CustomAudioPlayer;
