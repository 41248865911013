// modules
import React, { Fragment, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import logo from 'assets/images/astro_logo.png';
import { FiChevronDown, FiChevronUp, FiPrinter, FiSearch, FiBookOpen, FiFileText } from 'react-icons/fi';
import { TiPi, TiPipette } from 'react-icons/ti';
import { scrollToTop, scrollToBottom } from 'utils/scroll';
// styles
// components
import FormulasModal from './FormulasModal';
import ExtraMaterialModal from './ExtraMaterialModal';
import InstructionModal from './InstructionModal';
import SearchModal from './SearchModal';
import ImageProgressbar from './ImageProgressbar';
import NavigationButton from 'components/NavigationButton';
// redux
import { toggleFormulasModal, toggleExtraMaterialModal, toggleQuestionTimer } from 'store/UI';

const CourseNavigationContainer = styled.div`
  position: fixed;
  display: flex;
  gap: 16px;

  @media print {
    display: none;
  }

  ${media.lessThan('600px')`
    position: relative;
		flex-direction: row;
		align-self: center;
		padding: 0px 16px;
	`}

  ${media.greaterThan('600px')`
		flex-direction: column;
		justify-content: flex-start;
    align-content: flex-start;
    margin-left: 24px;
		padding: 24px 0px;
		height: 100%;
	`}
`;

const ImageProgressbarContainer = styled.div`
  ${media.lessThan('600px')`
		margin-right: auto;
		margin-bottom: 0px;
	`}

  ${media.greaterThan('600px')`
		margin-right: 0px;
		margin-bottom: 16px;
	`}
`;

const TopButtonContainer = styled.div`
  ${media.lessThan('600px')`
		display: none;
	`}

  ${media.greaterThan('600px')`
		margin-top: auto;
	`}
`;

const BottomButtonContainer = styled.div`
  ${media.lessThan('600px')`
		display: none;
	`}
`;

const CourseNavigation = ({ currentCourse, currentLessonProgress, isFormulasModalShown, toggleFormulasModal, isExtraMaterialModalShown, toggleExtraMaterialModal }) => {
  const [isInstructionModalShown, toggleInstructionModal] = useState(false);
  const [isSearchModalShown, toggleSearchModal] = useState(false);

  const handlePrint = () => {
    window.print();
  };

  const handlePalette = (e) => {
    e.target.classList.toggle('show');
  }
  document.onclick = function (e) {
    if (!e.target.classList.contains("palette-button") && !e.target.classList.contains("palette")) {
      document.querySelectorAll('.palette-button').forEach(el => {
        el.classList.remove('show')
      })
    }
  };

  const handleColor = () => {
    const palette = document.querySelectorAll('.palette')
    if (palette) {
      palette.forEach(el => {
        el.addEventListener('click', () => {
          localStorage.setItem('ekitap-color', el.attributes.color.value)
          changeColor()
        })
      })
    }
  }
  handleColor()
  const changeColor = () => {
    const color = localStorage.getItem('ekitap-color') ? localStorage.getItem('ekitap-color') : '#FFFFFF',
          content = document.querySelector('.dashboard .content'),
          palette = document.querySelectorAll('.palette')
    if (content) content.attributes.color.value = color
    if (palette) {
      palette.forEach(el => {
        if (el.attributes.color.value === color) el.classList.add('active')
        else el.classList.remove('active')
      })
    }
  }
  changeColor()

  return (
    <CourseNavigationContainer>
      {currentCourse && (
        <Fragment>
          <FormulasModal text={currentCourse.formulas} open={isFormulasModalShown} onClose={() => toggleFormulasModal(false)} />
          <ExtraMaterialModal text={currentCourse.extraMaterial} open={isExtraMaterialModalShown} onClose={() => toggleExtraMaterialModal(false)} />
          <SearchModal
            courseId={currentCourse._id}
            lastLesson={currentCourse.lastLesson}
            lessonKeywords={currentCourse.lessonKeywords}
            open={isSearchModalShown}
            onClose={() => toggleSearchModal(false)}
          />
        </Fragment>
      )}

      {currentCourse && (
        <Fragment>
          <InstructionModal text={currentCourse.formulas} open={isInstructionModalShown} onClose={() => toggleInstructionModal(false)} />
        </Fragment>
      )}

      <ImageProgressbarContainer>
        <ImageProgressbar progress={currentLessonProgress} imgSrc={logo} />
      </ImageProgressbarContainer>

      {currentCourse && (
        <Fragment>
          <NavigationButton id="instruction-button" onClick={() => toggleInstructionModal(true)}>
            <FiBookOpen color="#ffffff" />
          </NavigationButton>
          
          {currentCourse.formulas && (
            <NavigationButton id="formulas-button" onClick={() => toggleFormulasModal(true)}>
              <TiPi color="#ffffff" />
            </NavigationButton>
          )}

          {currentCourse.extraMaterial && (
            <NavigationButton id="extraMaterial-button" onClick={() => toggleExtraMaterialModal(true)}>
              <FiFileText color="#ffffff" />
            </NavigationButton>
          )}

          {/* <NavigationButton id="search-button" onClick={() => toggleSearchModal(true)}>
            <FiSearch color="#ffffff" />
          </NavigationButton> */}

          {/* <NavigationButton
            id="timer-button"
            isActive={isQuestionTimerShown}
            onClick={() => toggleQuestionTimer(!isQuestionTimerShown)}
          >
            <FiWatch color="#ffffff" />
          </NavigationButton> */}

          <NavigationButton id="print-button" onClick={handlePrint}>
            <FiPrinter color="#ffffff" />
          </NavigationButton>

          <NavigationButton id="palette-button" className="palette-button" onClick={handlePalette}>
            <div className='palette-block'>
              <button className='palette' color='#FFFFFF'></button>
              <button className='palette' color='#F2EFD7'></button>
              <button className='palette' color='#FFDFDF'></button>
              <button className='palette' color='#F5D9F5'></button>
              <button className='palette' color='#CFCFFF'></button>
              <button className='palette' color='#CFFFCF'></button>
              <button className='palette' color='#DFE6EC'></button>
              <button className='palette' color='#EEEDE1'></button>
              <button className='palette' color='#E7E7E7'></button>
            </div>
          </NavigationButton>
        </Fragment>
      )}

      <TopButtonContainer>
        <NavigationButton id="top-button" onClick={scrollToTop}>
          <FiChevronUp color="#ffffff" />
        </NavigationButton>
      </TopButtonContainer>

      <BottomButtonContainer>
        <NavigationButton id="bottom-button" onClick={scrollToBottom}>
          <FiChevronDown color="#ffffff" />
        </NavigationButton>
      </BottomButtonContainer>
    </CourseNavigationContainer>
  );
};

const mapStateToProps = state => ({
  currentCourse: state.ui.currentCourse,
  currentLessonProgress: state.ui.currentLessonProgress,
  isFormulasModalShown: state.ui.isFormulasModalShown,
  isExtraMaterialModalShown: state.ui.isExtraMaterialModalShown,
  // isQuestionTimerShown: state.ui.isQuestionTimerShown,
});

const mapDispatchToProps = dispatch => ({
  toggleFormulasModal: value => dispatch(toggleFormulasModal(value)),
  toggleExtraMaterialModal: value => dispatch(toggleExtraMaterialModal(value)),
  // toggleQuestionTimer: value => dispatch(toggleQuestionTimer(value)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(CourseNavigation);
