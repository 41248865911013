// modules
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
import { withTranslation } from 'react-i18next';
// assets
import { bold, header } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Container, Dropdown, Input, Message } from 'semantic-ui-react';
// components
// redux

const redirect = (context, path) => {
  context.props.history.push(path);
};

const classLanguageOptions = [
  {
    key: 'ru',
    text: 'русский',
    value: 'ru'
  },
  {
    key: 'kk',
    text: 'казахский',
    value: 'kk'
  },
  {
    key: 'en',
    text: 'английский',
    value: 'en'
  }
];

class NewClass extends Component {
  state = {
    class: {
      name: '',
      description: '',
      language: 'ru'
    },
    error: {
      status: false,
      msg: ''
    }
  };

  handleChange = (e, data) => {
    const { name, value } = data;

    this.setState(prevState => ({
      class: {
        ...prevState.class,
        [name]: value
      }
    }));
  };

  handleCreateClass = () => {
    const { t } = this.props;
    // show alert if fields are empty
    if (!this.state.class.name || !this.state.class.description || !this.state.class.language) {
      alert(t("admin.classes.new.fieldsNotFilled"));
      return;
    }

    // ask confirmation
    const r = window.confirm(t("admin.users.createNewClass") + "?");
    if (r) {
      // deconstruct user
      const { user } = this.props;

      // create class
      axios
        .post(adminRoutes.classes.createClass(), { class: { ...this.state.class, teacher: user._id } })
        .then(() => {
          redirect(this, '/admin/users');
        })
        .catch(() => {
          this.setState({
            error: {
              status: true,
              msg: t("admin.classes.new.classAlreadyCreated")
            }
          });
        });
    }
  };

  render() {
    const { name, description, language } = this.state.class;
    const { t } = this.props;
    return (
      <DocumentTitle title={t("admin.users.createNewClass")}>
        <Container>
          <Button basic color="blue" onClick={() => redirect(this, '/admin/users')}>
            {t("admin.classes.new.backToStudents")}
          </Button>

          {header(t("admin.users.createNewClass"), '24px', 600)}
          {bold(t("admin.classes.new.className"))}
          <Input
            fluid
            required
            name="name"
            placeholder={t("admin.classes.new.name")}
            value={name}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold(t("admin.classes.new.classDesc"))}
          <Input
            fluid
            required
            name="description"
            placeholder={t("admin.classes.new.desc")}
            value={description}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />
          {bold(t("admin.classes.new.lang"))}
          <Dropdown
            fluid
            selection
            name="language"
            placeholder="Фамилия"
            value={language}
            options={classLanguageOptions}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {this.state.error.status && (
            <Message negative>
              <Message.Header>{this.state.error.msg}</Message.Header>
            </Message>
          )}

          <Button
            type="button"
            onClick={this.handleCreateClass}
            style={{
              backgroundColor: '#0e79b2',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            {t("admin.users.createNewClass")}
          </Button>
        </Container>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(NewClass);
