import React, { useState } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import './RegistrationForm.css';
import { register } from "../../../store/User";
import { useColor } from '../Utils/ColorContext';

const RegistrationForm = ({ done, error, kundelikId = '', makeRegister, onClose, toggleLoginModalShown, t }) => {
  const [hasAggreed, setAggreed] = useState(false);
  const [showText, setShowText] = useState(true); // Начальное значение showText устанавливается в true
  const [showPassword, setShowPassword] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { color } = useColor();

  // Функция handleRegister
  const handleRegister = event => {
    event.preventDefault();

    setShowText(true); // Установка showText в true перед вызовом makeRegister

    makeRegister(firstName, lastName, email, password, kundelikId)
      .then(() => {
        // Если регистрация успешна, скрыть сообщение об успехе через 3 секунды
        setTimeout(() => {
          setShowText(false);
        }, 3000);
      })
      .catch(() => {
        // Если возникает ошибка, скрыть сообщение об ошибке через 3 секунды
        setTimeout(() => {
          setShowText(false);
        }, 3000);
      });
  };

  const headerTextStyle = {
      color: color === '#3C3C56' ? 'white' : 'black',
      // другие стили для текста заголовка
  };
  const titleStyle = {
      color: color === '#3C3C56' ? 'white' : '#7B6CEA',
  }

    return (
        <div className='formloginreg'>
            <div className='center'>
                <h1 style={titleStyle} > {t("landing.registermodal.header")}</h1>
            </div>
          <form onSubmit={handleRegister}>
            {showText && done && <div className="landing-modal-form-message1 success">{t('landing.registermodal.msg.success')}</div>}
            {showText && error && <div className="landing-modal-form-message1 error">{t('landing.registermodal.msg.error')}</div>}
            <div className='loginreg'>
                <label htmlFor="userlastname"></label>
                <input
                  type="text"
                  id="userlastname"
                  name="userlastname"
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                  placeholder={t('lastName') + '*'}
                  required
                />

            </div>
            <div className='loginreg'>
                <label htmlFor="username"></label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={firstName}
                  required
                  placeholder={t('firstName') + '*'}
                  onChange={event => setFirstName(event.target.value)}
                  />
            </div>
            <div className='loginreg'>
                <label htmlFor="Email"></label>
                <input
                  type="text"
                  id="Email"
                  name="Email"
                  value={email}
                  required
                  placeholder="Email*"
                  onChange={event => setEmail(event.target.value)}
                />
            </div>
            <div className='loginreg'>
                <label htmlFor="password"></label>
                <input
                  id="password"
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('password') + '*'}
                  onChange={event => setPassword(event.target.value)}
                />
            </div>
            {/* {kundelikId && (
              <div className='loginreg'>
                <label htmlFor='kundelikId'></label>
                <input
                  disabled
                  type="text"
                  name="kundelikId"
                  value={`Kundelik ID: ${kundelikId}`}
                />
              </div>
            )} */}
            <div className='textreg'>
              <div className='loginregagreements'>
                <input
                  type="checkbox"
                  id="showPassword"
                  name="showPassword"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}/>
                <label htmlFor="showPassword" style={headerTextStyle}>{t('landing.registermodal.showpassword')}</label>
              </div>

              <div className='helppasswordreg'>
                <div className='isAgreed'>
                  <input
                    id="isAgreed"
                    name="isAgreed"
                    value={hasAggreed}
                    type="checkbox"
                    onChange={event => setAggreed(event.target.checked)}
                  />
                  <label htmlFor="isAgreed" style={headerTextStyle}>
                    {t('landing.registermodal.agreement')}
                  </label>
                </div>
              </div>

              <div className='buttonsubmitreg'>
                <input disabled={!hasAggreed} type="submit" value={t('register')} />
              </div>
            </div>
          </form>
        </div>
    );
}


const mapStateToProps = state => ({
  done: state.user.register.done,
  error: state.user.register.fail
});

const mapDispatchToProps = dispatch => ({
  makeRegister: (firstName, lastName, email, phone, password, kundelikId) => dispatch(register(firstName, lastName, email, phone, password, kundelikId))
});

export const WrappedRegisterModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(RegistrationForm);