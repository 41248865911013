// modules
import React, {useState,useEffect,useRef} from 'react';
import { withTranslation } from "react-i18next";

import './Contacts.css';
import Vector1 from '../../../assets/images/landing/Vector1.svg'
import Vector2 from '../../../assets/images/landing/Vector2.svg'
import Vector3 from '../../../assets/images/landing/Vector3.svg'
import Vector4 from '../../../assets/images/landing/Vector4.svg'
import {useColor} from '../Utils/ColorContext';

function Contacts({t}) {
    const { color } = useColor();

    const titleStyle = {
        color: color === '#3C3C56' ? 'white' : '#7B6CEA',
    }

    return (
        <div className="contacts">
            <div className='titlecontact'>
                <h1 style={titleStyle}>{t('landing.header.contacts')}</h1>
            </div>
            <div className="contact-info">
                <div className="contact-item">
                    <div className='cont'>
                        <img src={color === '#3C3C56' ? Vector2 : Vector3} alt="Logo" />
                        <h4>arman-pv.kz</h4>
                    </div>
                    <div className='cont'>
                        <img src={color === '#3C3C56' ? Vector1 : Vector4} alt="Logo" />
                        <h4>info@arman-pv.kz</h4>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default withTranslation()(Contacts);