// modules
import React, { Component } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import DocumentTitle from 'react-document-title';
// assets
import { bold, header } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
// styles
import { Button, Checkbox, Container, Dropdown, Image, Input, Loader, Message } from 'semantic-ui-react';
import './index.css';
// components
// redux

const redirect = (context, path) => {
  context.props.history.push(path);
};

class NewCourse extends Component {
  state = {
    course: {
      name: '',
      description: '',
      language: '',
      hidden: false,
      cover: ''
    },
    uploading: false
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (data.name === 'hidden') {
      result = data.checked;
    }

    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        [data.name]: result
      }
    }));
  };

  handleAddCourse = () => {
    // show alert if name was not entered
    if (!this.state.course.name || !this.state.course.language) {
      alert('Название учебника или язык учебника не были введены');
      return;
    }

    const r = window.confirm('Создать новый учебник?');
    if (r) {
      // add course
      axios.post(adminRoutes.courses.addCourse(), { course: { ...this.state.course } }).then(() => {
        redirect(this, '/admin/courses');
      });
    }
  };

  handleCoverUpload = async e => {
    // create a new form data
    const data = new FormData();
    // append file
    data.append('file', e.target.files[0]);
    // get file extension
    let extension = e.target.files[0].name.split('.');
    extension = extension[extension.length - 1];
    // append filename
    data.append('filename', `course-cover-${nanoid(16)}.${extension}`);
    // set uploading to true
    this.setState({ uploading: true });
    // axios call
    const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch(error => {
      this.setState({ uploading: false });
      return;
    });
    // set course cover
    this.setState(prevState => ({
      ...prevState,
      course: {
        ...prevState.course,
        cover: response.data.message.link
      },
      uploading: false
    }));
  };

  render() {
    const languageOptions = [
      {
        key: 'ru',
        text: 'Русский',
        value: 'ru'
      },
      {
        key: 'kk',
        text: 'Казахский',
        value: 'kk'
      }
    ];

    return (
      <DocumentTitle title="Добавить новый учебник">
        <Container>
          {header('Добавить новый учебник', '24px', 600)}
          <Message
            info
            icon="hand pointer outline"
            header="Уроки учебника"
            content="Добавлять уроки в этот учебник можно будет после создания, выбрав соответствующий учебник из списка"
          />

          {bold('Название учебника')}
          <Input
            fluid
            required
            name="name"
            placeholder="Название учебника"
            value={this.state.course.name}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {bold('Описание учебника')}
          <Input
            fluid
            required
            name="description"
            placeholder="Описание учебника"
            value={this.state.course.description}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {bold('Язык учебника')}
          <Dropdown
            fluid
            selection
            required
            name="language"
            placeholder="Язык учебника"
            value={this.state.course.language}
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
            options={languageOptions}
          />

          {bold('Скрыть учебник?')}
          <Checkbox
            checked={this.state.course.hidden}
            name="hidden"
            toggle
            onChange={this.handleChange}
            style={{ marginBottom: '1em' }}
          />

          {bold('Обложка учебника')}
          {this.state.course.cover && (
            <Image
              className="admin-courses-new-course-cover"
              src={this.state.course.cover}
              alt="Course cover"
              style={{ marginBottom: '1em' }}
            />
          )}

          <div style={{ marginBottom: '2em' }}>
            {this.state.uploading ? (
              <Loader active inline="centered" size="large" />
            ) : (
              <input type="file" onChange={this.handleCoverUpload} />
            )}
            <div style={{ marginTop: '1em' }}>
              <b>Размер файла не должен превышать 10 МБ</b>
            </div>
          </div>

          <Button
            type="button"
            onClick={this.handleAddCourse}
            style={{
              backgroundColor: '#0e79b2',
              color: '#fff',
              borderRadius: '0px',
              boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
              marginBottom: '1em'
            }}
          >
            Добавить учебник
          </Button>
        </Container>
      </DocumentTitle>
    );
  }
}

export default NewCourse;
