// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";
import { scroller } from "react-scroll";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import media from "styled-media-query";
// assets
import notFound from "assets/images/404.png";
import stop from "assets/images/stop.svg";
import nextArrow from "assets/images/next-arrow.png";
import prevArrow from "assets/images/prev-arrow.png";
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Image, Loader } from "semantic-ui-react";
// components
import StarsComponent from "./StarsComponent";
import LessonStatsModal from "./LessonStatsModal";
import IframeBlock from "./IframeBlock";
import IndefiniteQuestion from "./IndefiniteQuestion";
import Question from "./Question";
import InteractiveQuestion1 from "components/InteractiveQuestions/InteractiveQuestion1";
import InteractiveQuestion2 from "components/InteractiveQuestions/InteractiveQuestion2";
import InteractiveQuestion3 from "components/InteractiveQuestions/InteractiveQuestion3";
import InteractiveQuestion4 from "components/InteractiveQuestions/InteractiveQuestion4";
import InteractiveQuestion5 from "components/InteractiveQuestions/InteractiveQuestion5";
import InteractiveQuestion6 from "components/InteractiveQuestions/InteractiveQuestion6";
import InteractiveQuestion7 from "components/InteractiveQuestions/InteractiveQuestion7";
import InteractiveQuestion8 from "components/InteractiveQuestions/InteractiveQuestion8";
import InteractiveQuestion9 from "components/InteractiveQuestions/InteractiveQuestion9";
import TextBlock from "./TextBlock";
import InteractiveBlock from "components/InteractiveBlock";
// redux
import { toggleFormulasModal } from 'store/UI';
import { toggleExtraMaterialModal } from 'store/UI';
import { getUser, logout } from "store/User";
// utils
import kundelikIntegration from "../../../../services/kundelik-integration";

const StyledLessonContainer = styled.div`
    min-height: 70vh;
    font-weight: 400;
`;

const StyledErrorContainer = styled.div`
    text-align: center;

    ${media.lessThan("400px")`
        font-size: 14px;

        .image {
            width: 100%;
        }
    `}

    ${media.between("400px", "600px")`
        font-size: 16px;
        
        .image {
            width: 80%;
        }
    `}

    ${media.between("600px", "800px")`
        font-size: 18px;

        .image {
            width: 80%;
        }
    `}

    ${media.greaterThan("800px")`
        font-size: 22px;

        .image {
            width: 60%;
        }
    `}
`;

const StyledLessonNameContainer = styled.div`
    margin-bottom: 2em;
    text-align: center;
    line-height: 1.4;
    font-weight: 600;

    ${media.lessThan("400px")`
        font-size: 18px;

        .section-name {
            margin-right: 0.4em;
        }
    `}

    ${media.between("400px", "600px")`
        font-size: 22px;

        .section-name {
            margin-right: 0.6em;
        }
    `}

    ${media.between("600px", "800px")`
        font-size: 24px;

        .section-name {
            margin-right: 1em;
        }
    `}

    ${media.greaterThan("800px")`
        font-size: 36px;
        
        .section-name {
            margin-right: 1em;
        }
    `}
`;

const StyledBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;

    .MathJax_Display,
    .MathJax_Preview {
        width: fit-content;
        display: inline-block!important;
        margin: 0;
    }

    .highlighted {
        background-color: rgb(218, 255, 218) !important;
        border: 3px solid rgb(45, 153, 86);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .ql-video {
        display: block;
        margin: 0 auto;
    }

    ${media.lessThan("400px")`
        margin-bottom: 32px;
        padding: 0px;
        font-size: 16px;

        h3 {
            font-size: 16px;
        }

        .highlighted {
            padding: 16px;
        }

        .ql-video {
            width: 240px;
            height: 135px;
        }
    `}

    ${media.between("400px", "600px")`
        margin-bottom: 40px;
        padding: 0px 16px;
        font-size: 18px;

        h3 {
            font-size: 18px;
        }

        .highlighted {
            padding: 16px;
        }

        .ql-video {
            width: 320px;
            height: 180px;
        }
    `}

    ${media.between("600px", "800px")`
        margin-bottom: 64px;
        padding: 0px 20px;
        font-size: 18px;

        h3 {
            font-size: 18px;
        }

        .highlighted {
            padding: 24px;
        }

        .ql-video {
            width: 400px;
            height: 225px;
        }
    `}

    ${media.between("800px", "1100px")`
        margin-bottom: 72px;
        padding: 0px 24px;
        font-size: 20px;

        h3 {
            font-size: 20px;
        }

        .highlighted {
            padding: 32px;
        }

        .ql-video {
            width: 640px;
            height: 360px;
        }
    `}

    ${media.greaterThan("1100px")`
        margin-bottom: 80px;
        padding: 0px 24px;
        font-size: 22px;

        h3 {
            font-size: 22px;
        }

        .highlighted {
            padding: 40px;
        }

        .ql-video {
            width: 800px;
            height: 450px;
        }
    `}
`;

const StyledAssignHomeworkButtonWrapper = styled.div`
    align-self: flex-end;
    margin-top: 16px;
`;

const StyledNextBlockButtonContainer = styled.div`
    button {
        border: none;
        border-radius: 8px;
        outline: none;
        background-color: #419488;
        color: white;
        cursor: pointer;
        font-weight: 600;
    }

    button:hover {
        background-color: #327c71;
    }

    ${media.lessThan("400px")`
        margin-left: 32px;

        button {
            padding: 10px 20px;
            font-size: 14px;
        }
    `}

    ${media.between("400px", "600px")`
        margin-left: 48px;

        button {
            padding: 12px 24px;
            font-size: 16px;
        }
    `}

    ${media.between("600px", "800px")`
        margin-left: 72px;

        button {
            padding: 12px 24px;
            font-size: 18px;
        }
    `}

    ${media.greaterThan("800px")`
        margin-left: 120px;
        
        button {
            padding: 14px 28px;
            font-size: 22px;
        }
    `}
`;

const StyledExtraMaterialsContainer = styled.div`
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);

    .header {
        font-weight: 600;
    }

    ${media.lessThan("400px")`
        margin: 0px 0px 16px 0px;
        padding: 16px 8px;
        font-size: 14px;

        .header {
            font-size: 16px;
            margin-bottom: 16px;
        }
    `}

    ${media.between("400px", "600px")`
        margin: 0px 16px 24px 16px;
        padding: 20px 12px;
        font-size: 16px;

        .header {
            font-size: 18px;
            margin-bottom: 24px;
        }
    `}

    ${media.between("600px", "800px")`
        margin: 0px 24px 24px 24px;
        padding: 20px 12px;
        font-size: 16px;

        .header {
            font-size: 18px;
            margin-bottom: 32px;
        }
    `}

    ${media.between("800px", "1100px")`
        margin: 0px 32px 24px 32px;
        padding: 24px 16px;
        font-size: 18px;

        .header {
            font-size: 20px;
            margin-bottom: 32px;
        }
    `}

    ${media.greaterThan("1100px")`
        margin: 0px 120px 48px 120px;
        padding: 32px;
        font-size: 18px;

        .header {
            font-size: 20px;
            margin-bottom: 32px;
        }
    `}
`;

const StyledShortStatsContainer = styled.div`
    color: black;
    text-align: center;
    font-weight: 600;

    ${media.lessThan("400px")`
        margin-bottom: 24px;
        font-size: 16px;
    `}

    ${media.between("400px", "600px")`
        margin-bottom: 32px;
        font-size: 18px;
    `}

    ${media.between("600px", "800px")`
        margin-bottom: 40px;
        font-size: 18px;
    `}

    ${media.between("800px", "1100px")`
        margin-bottom: 40px;
        font-size: 20px;
    `}

    ${media.greaterThan("1100px")`
        margin-bottom: 48px;
        font-size: 22px;
    `}
`;

const StyledSendStatsButtonContainer = styled.div`
    display: flex;
    justify-content: center;

    button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: #419488;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
        color: white;
        font-weight: 600;
    }

    ${media.lessThan("400px")`
        button {
            padding: 8px;
            font-size: 14px;
            border-radius: 8px;
        }
    `}

    ${media.between("400px", "600px")`
        button {
            padding: 8px 12px;
            font-size: 16px;
            border-radius: 8px;
        }
    `}

    ${media.between("600px", "800px")`
        button {
            padding: 12px 16px;
            font-size: 18px;
            border-radius: 12px;
        }
    `}

    ${media.greaterThan("800px")`
        button {
            padding: 16px 24px;
            font-size: 22px;
            border-radius: 16px;
        }
    `}
`;

const StyledNextLessonLinkContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .container:hover {
        cursor: pointer;
    }

    .container:hover > .link {
        color: slategrey;
        border-bottom: 1px solid slategrey;
    }

    .link {
        color: black;
        line-height: 1.2;
        text-decoration: none;
        text-transform: lowercase;
    }

    .inactive { 
        cursor: default;
    }

    ${media.lessThan("400px")`
        column-gap: 4px;
        margin-top: 2em;
        
        .container {
            column-gap: 4px;
        }

        .link {
            font-size: 14px;
        }

        .img {
            width: 24px;
            height: 24px;
        }
    `}

    ${media.between("400px", "600px")`
        column-gap: 8px;
        margin-top: 2em;
        
        .container {
            column-gap: 8px;
        }

        .link {
            font-size: 16px;
        }

        .img {
            width: 32px;
            height: 32px;
        }
    `}

    ${media.between("600px", "800px")`
        column-gap: 12px;
        margin-top: 4em;
        
        .container {
            column-gap: 12px;
        }

        .link {
            font-size: 18px;
        }

        .img {
            width: 36px;
            height: 36px;
        }
    `}

    ${media.greaterThan("800px")`
        column-gap: 16px;
        margin-top: 4em;
        
        .container {
            column-gap: 16px; 
        }

        .link {
            font-size: 24px;
        }

        .img {
            width: 48px;
            height: 48px;
        }
    `}
`;

class Lesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGivingHomework: false,
            answering: false,
            loading: false,
            forbidden: false,
            notFound: false,
            courseId: this.props.match.params.courseId,
            lessonId: this.props.match.params.lessonId,
            isLessonStatsModalVisible: false,
            answerCount: 0,
        };

        this.courseId = this.props.match.params.courseId;
        this.lessonId = this.props.match.params.lessonId;
    }

    componentDidMount() {
        this.setLesson(this.courseId, this.lessonId);
        this.checkIfCanAssignHomework();
    }

    componentDidUpdate(prevProps) {
        // compare location match parameters
        const { courseId: prevCourseId = '', lessonId: prevLessonId = '' } = prevProps.match.params;
        const { courseId = '', lessonId = '' } = this.props.match.params;

        if (courseId !== prevCourseId || lessonId !== prevLessonId) {
            // update course and lesson IDs
            this.courseId = courseId;
            this.lessonId = lessonId;

            // scroll to top
            window.scrollTo(0, 0);
            // update and set lesson
            this.setLesson(this.courseId, this.lessonId);
        }

        if (this.props.location.hash === '#glossary') {
            this.props.toggleFormulasModal(true);
        }
        if (this.props.location.hash === '#extraMaterial') {
            this.props.toggleExtraMaterialModal(true);
        }
    }

    checkIfCanAssignHomework = () => {
        this.canAssignHomework = kundelikIntegration.canUserAssignHomework(this.props.role);
    };

    assignHomework = async (courseId, lessonId, blockId) => {
        this.setState({ isGivingHomework: true });

        kundelikIntegration
            .assignHomework(courseId, lessonId, blockId)
            .then(() => {
                this.canAssignHomework = false;
            })
            .catch((error) => {
                alert(error);
            })
            .finally(() => {
                this.setState({ isGivingHomework: false });
            });
    };

    setLesson = async (courseId, lessonId) => {
        // reset state indicators
        this.setState({ loading: true, forbidden: false, notFound: false });

        // fetch lesson from server
        const data = await this.fetchLesson(courseId, lessonId).catch(err => {
            console.error(err);
            switch (err.response.status) {
                case 401:
                    this.props.logout();
                    setTimeout(() => {
                        window.location = "/";
                    }, 500);
                    break;
                case 403:
                    this.setState({ loading: false, forbidden: true });
                    break;
                case 404:
                    this.setState({ loading: false, notFound: true });
                    break;
                default:
                    this.props.logout();
                    return;
            }
        });

        // pick out lesson and user lesson
        const { lesson = {}, userLesson = {} } = data;
        // populate lesson with user answers
        lesson.completed = userLesson.completed;
        lesson.correct = userLesson.correct || 0;
        // lesson.currentBlock = userLesson.currentBlock || 0;
        lesson.currentBlock = lesson.blocks.length - 1;
        // iterate over blocks
        for (let i = 0; i < userLesson.answers.length; i++) {
            let index = lesson.blocks.findIndex((x) => x._id === userLesson.answers[i]._id);
            if (index !== -1) {
                lesson.blocks[index].answer = userLesson.answers[i].answer;
                lesson.blocks[index].correct = userLesson.answers[i].correct;
                lesson.blocks[index].time = userLesson.answers[i].time;
                lesson.blocks[index].hasReanswered = userLesson.answers[i].hasReanswered;
            }
        }

        // set lesson
        this.setState({ loading: false, answering: false, lesson: lesson }, () => {
            // handle progress
            this.props.handleProgress(this.state.lesson.currentBlock, this.state.lesson.length);

            // transform oembed's
            // actually, this transforms oembed's created when attribute previewsInData was false
            setTimeout(() => {
                document.querySelectorAll('oembed[url]').forEach(element => {
                    if (window['iframely']) {
                        window['iframely'].load(element, element.attributes.url.value);
                    }
                });
            }, 2500);

            // move to selected block
            const selectedBlockId = this.props.location.hash;
            if (selectedBlockId) {
                setTimeout(() => {
                    scroller.scrollTo(selectedBlockId.substring(1), {
                        duration: 1500,
                        smooth: "easeInOutCubic",
                        offset: -32,
                    });
                }, 1000);
            }
        });
    }

    fetchLesson = (courseId, lessonId) => new Promise((resolve, reject) => {
        axios
            .get(dashboardRoutes.fetchLesson(courseId, lessonId))
            .then(response => resolve(response.data.message))
            .catch(err => reject(err));
    });

    toggleLessonStatsModalVisibility = (value) => {
        this.setState({ isLessonStatsModalVisible: value });
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    handleAnswering = (value) => {
        this.setState({ answering: value });
    };

    handleAnswer = (index, answer, correct, time) => {
        if (this.state.lesson) {
            const blocks = [...this.state.lesson.blocks];

            blocks[index]["answer"] = answer;
            blocks[index]["correct"] = correct
            blocks[index]["time"] = time;

            this.setState((prevState) => ({
                lesson: {
                    ...prevState.lesson,
                    blocks: blocks,
                },
                answerCount: prevState.answerCount + 1,
            }));
        }
    }

    handleCorrect = (isCorrect) => {
        this.setState((prevState) => ({
            lesson: {
                ...this.state.lesson,
                correct: isCorrect ? prevState.lesson.correct + 1 : prevState.lesson.correct,
            },
        }));
    };

    handleUpdateLastBlock = () => {
        this.setState(
            {
                lesson: {
                    ...this.state.lesson,
                    currentBlock: this.state.lesson.currentBlock + 1,
                },
            },
            () => {
                // scroll
                scroller.scrollTo("last-block", {
                    duration: 750,
                    delay: 100,
                    smooth: "easeInOutCubic",
                    offset: -24,
                });
                // handle progress
                this.props.handleProgress(this.state.lesson.currentBlock, this.state.lesson.length);
                // payload
                const payload = {
                    completed: this.state.lesson.completed,
                    currentBlock: this.state.lesson.currentBlock,
                };
                // axios call
                axios.post(dashboardRoutes.updateBlock(this.courseId, this.lessonId), payload);
            }
        );
    };

    handleCompleted = () => {
        this.setState({ lesson: { ...this.state.lesson, completed: true } }, () => {
            // scroll
            scroller.scrollTo("dashboard-lesson-stars", {
                duration: 750,
                delay: 100,
                smooth: "easeInOutCubic",
                offset: -40,
            });
            // payload
            const payload = {
                completed: this.state.lesson.completed,
                currentBlock: this.state.lesson.currentBlock,
                results: this.state.lesson.correct,
            };
            // axios call
            axios.post(dashboardRoutes.updateBlock(this.courseId, this.lessonId), payload);
        });
    };

    handleLessonRedirect = (id) => {
        const { location } = this.props;
        const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
        window.location = `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${this.courseId}/lesson/${id}`;
    };

    handleUpdateLastLesson = async () => {
        if (this.state.lesson && this.state.lesson.nextLesson._id) {
            // let course = this.props.courses.find((x) => x._id === this.courseId);
            // if (this.state.lesson._id === course.lastLesson._id)

            const payload = { lastLesson: this.state.lesson.nextLesson };

            // update last lesson at server
            // axios call
            await axios.post(dashboardRoutes.updateLesson(this.courseId), payload).catch((error) => {
                alert(`Ошибка: ${error.toString()}`);
            });
            // get user
            this.props.getUser();
            // redirect to next lesson
            setTimeout(() => {
                this.handleLessonRedirect(this.state.lesson.nextLesson._id);
            }, 1000);

            
        }
    };

    handleNextClick = () => {
        if (this.state.lesson) {
            const { currentBlock, length } = this.state.lesson;
            if (currentBlock === length - 1) {
                this.handleCompleted();
            } else {
                this.handleUpdateLastBlock();
            }
        }
    };

    render() {
        const { t } = this.props;

        let forbiddenContainer;
        let notFoundContainer;
        let lessonSectionName = "";
        let lessonName = "Урок";
        let lesson = [];
        let questionNumber = 1;

        if (this.state.forbidden) {
            forbiddenContainer = (
                <StyledErrorContainer>
                    <p>{t("dashboard.course.lesson.forbidden.header")}</p>
                    <p>{t("dashboard.course.lesson.forbidden.text")}</p>
                    <Image className="dashboard-lesson-forbidden-image" centered src={stop} alt="" />
                </StyledErrorContainer>
            );
        }

        if (this.state.notFound) {
            notFoundContainer = (
                <StyledErrorContainer>
                    <p>{t("dashboard.course.lesson.notfound.text")}</p>
                    <Image className="dashboard-lesson-not-found-image" centered src={notFound} alt="" />
                </StyledErrorContainer>
            );
        }

        if (this.state.lesson && !this.state.forbidden && !this.state.notFound) {
            // set lesson name
            lessonName = this.state.lesson.name;
            // set lesson section name
            if (this.state.lesson.section) {
                lessonSectionName = this.state.lesson.section + ". ";
            }

            // add lesson name container
            lesson.push(
                <StyledLessonNameContainer key="dashboard-lesson-name">
                    <span className="section-name">{lessonSectionName}</span>
                    <span>{ReactHtmlParser(lessonName)}</span>
                </StyledLessonNameContainer>
            );

            if (this.state.lesson.blocks && this.state.lesson.blocks.length !== 0) {
                const numberOfLessonBlocks = this.state.lesson.blocks.length;

                // lesson blocks
                for (let i = 0; i <= this.state.lesson.currentBlock; i++) {
                    const blockId = this.state.lesson.blocks[i]._id;
                    // replace placeholder with first name
                    let text = this.state.lesson.blocks[i].text;
                    if (!!text) {
                        text = text.replace("$$placeholder$$", this.props.firstName);
                    }
                    // select component based on block type
                    let item;
                    switch (this.state.lesson.blocks[i].blockType) {
                        case "indefiniteQuestion":
                            item = (
                                <IndefiniteQuestion
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question":
                            item = (
                                <Question
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "text":
                            item = (
                                <TextBlock
                                    block={this.state.lesson.blocks[i]}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    text={text}
                                    userId={this.props.userId}
                                />
                            );
                            break;
                        case "iframe":
                            item = <IframeBlock src={this.state.lesson.blocks[i].iframeSrc} />;
                            break;
                        case "interactive":
                            item = (
                                <InteractiveBlock
                                    highlighted={this.state.lesson.blocks[i].highlighted}
                                    person={this.state.lesson.blocks[i].person}
                                    popupTextList={this.state.lesson.blocks[i].popupTextList}
                                    text={text}
                                />
                            );
                            break;
                        case "question-interactive-fill-slots":
                            item = (
                                <InteractiveQuestion1
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-fill-from-list":
                            item = (
                                <InteractiveQuestion2
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-classification":
                            item = (
                                <InteractiveQuestion3
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-find-pairs":
                            item = (
                                <InteractiveQuestion4
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-find-matches":
                            item = (
                                <InteractiveQuestion5
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-select-words":
                            item = (
                                <InteractiveQuestion6
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-guess-word":
                            item = (
                                <InteractiveQuestion7
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-word-search":
                            item = (
                                <InteractiveQuestion8
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question-interactive-order-words":
                            item = (
                                <InteractiveQuestion9
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleAnswer={this.handleAnswer}
                                    handleCorrect={this.handleCorrect}
                                    demo={this.props.demo}
                                    courseId={this.courseId}
                                    lessonId={this.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        default:
                            item = null;
                    }
                    // push item
                    lesson.push(
                        <StyledBlockContainer
                            key={`dashboard-lesson-block-${blockId}`}
                            id={blockId}
                            name={i === this.state.lesson.currentBlock ? "last-block" : ""}
                        >
                            {item}
                            {this.canAssignHomework && (
                                <StyledAssignHomeworkButtonWrapper>
                                    <Button
                                        primary
                                        size='large'
                                        loading={this.state.isGivingHomework}
                                        onClick={() => this.assignHomework(this.courseId, this.lessonId, blockId)}
                                    >
                                        {t("teacher.actions.assignHomework")}
                                    </Button>
                                </StyledAssignHomeworkButtonWrapper>
                            )}
                        </StyledBlockContainer>
                    );
                }

                // Uncomment this if you need a "next block" button
                // push next block button
                // if (!this.state.lesson.completed && !this.state.answering) {
                //     lesson.push(
                //         <StyledNextBlockButtonContainer key="dashboard-lesson-next-block-button">
                //             <button onClick={this.handleNextClick}>
                //                 {t("dashboard.course.lesson.nextBlock")}
                //             </button>
                //         </StyledNextBlockButtonContainer>
                //     );
                // }
            }

            // Add this condition if you need a block-by-block lesson
            // if (this.state.lesson.completed) {}
            // lesson extra material
            if (this.state.lesson.extraMaterial) {
                lesson.push(
                    <StyledExtraMaterialsContainer key="dashboard-lesson-extra-materials">
                        <div className="header">{t("dashboard.course.lesson.extraMaterials")}:</div>
                        <div className="ck-content" style={{ width: '100%',}}>
                            {ReactHtmlParser(this.state.lesson.extraMaterial)}
                        </div>
                    </StyledExtraMaterialsContainer>
                );
            }

            if (this.state.lesson.questions > 0) { //this.state.lesson.prevLesson._id && this.state.lesson.nextLesson._id
                // stars
                lesson.push(
                    <StarsComponent
                        key="dashboard-lesson-stars"
                        name="dashboard-lesson-stars"
                        questions={this.state.lesson.questions}
                        correct={this.state.lesson.correct}
                    />
                );
                // caption under stars
                lesson.push(
                    <StyledShortStatsContainer key="dashboard-lesson-short-stats">
                        <p>
                            {t("dashboard.course.lesson.totalQuestions")}: {this.state.lesson.questions}
                        </p>
                        <p>
                            {t("dashboard.course.lesson.correctQuestions")}: {this.state.lesson.correct} ({Math.round((this.state.lesson.correct / this.state.lesson.questions) * 100)})%
                        </p>
                    </StyledShortStatsContainer>
                );

                // send lesson stats button
                lesson.push(
                    <StyledSendStatsButtonContainer key="dashboard-lesson-send-stats">
                        <button onClick={() => this.toggleLessonStatsModalVisibility(true)}>
                            {t("dashboard.course.lesson.sendStats")}
                        </button>
                    </StyledSendStatsButtonContainer>
                );

                // lesson stats modal
                lesson.push(
                    <LessonStatsModal
                        open={this.state.isLessonStatsModalVisible}
                        onClose={() => this.toggleLessonStatsModalVisibility(false)}
                        key="dashboard-lesson-footer-stats"
                        extraMaterial={this.state.lesson.extraMaterial}
                        correct={this.state.lesson.correct}
                        demo={this.props.demo}
                        courseId={this.courseId}
                        lessonId={this.lessonId}
                        lessonName={this.state.lesson.name}
                        innerWidth={this.props.innerWidth}
                        section={this.state.lesson.section}
                        questions={this.state.lesson.questions}
                        blocks={this.state.lesson.blocks}
                        answerCount={this.state.answerCount}
                    />
                );
            }

            lesson.push(
                <StyledNextLessonLinkContainer key="dashboard-lesson-lesson-links">
                    {/* prev lesson link */}
                    {this.state.lesson.prevLesson && this.state.lesson.prevLesson._id && (
                        <div className="container" onClick={() => this.handleLessonRedirect(this.state.lesson.prevLesson._id)}>
                            <img className="img" src={prevArrow} alt="" />
                            <span className="link">{t("dashboard.course.lesson.prevLesson")}</span>
                        </div>
                    )}

                    {/* next lesson link */}
                    {this.state.lesson.nextLesson && this.state.lesson.nextLesson._id ? (
                        <div className="container" onClick={this.handleUpdateLastLesson}>
                            <span className="link">{t("dashboard.course.lesson.nextLesson")}</span>
                            <img className="img" src={nextArrow} alt="" />
                        </div>
                    ) : (
                        <div className="container">
                            <span className="link inactive">{t("dashboard.course.lesson.endLesson")}</span>
                        </div>
                    )}
                </StyledNextLessonLinkContainer>
            );
        }

        return (
            <DocumentTitle title={lessonSectionName + lessonName}>
                <StyledLessonContainer>
                    {forbiddenContainer}
                    {notFoundContainer}
                    {this.state.loading ? <Loader active size="large" /> : lesson}
                </StyledLessonContainer>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    firstName: state.user.user.firstName,
    payment: state.user.user.payment,
    courses: state.user.user.courses,
    isTimerVisible: state.ui.isTimerVisible,
    role: state.user.user.scope,
    userId: state.user.user._id,
});

const mapDispatchToProps = (dispatch) => ({
    getUser: () => dispatch(getUser()),
    logout: () => dispatch(logout()),
    toggleFormulasModal: value => dispatch(toggleFormulasModal(value)),
    toggleExtraMaterialModal: value => dispatch(toggleExtraMaterialModal(value))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(Lesson))
);
