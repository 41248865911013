import { animateScroll as scroll } from 'react-scroll';

// scroll to element located by ID
export const scrollToElement = (id, smooth) => {
  // search for element
  const element = document.getElementById(id);

  window.scroll({
    top: element.offsetTop - 40,
    behavior: 'smooth'
  });

  // scroll.scrollTo({
  //     to: offset,
  //     smooth: smooth,
  // });
};

// scroll to page top
export const scrollToTop = () => {
  scroll.scrollToTop({
    duration: 1000,
    smooth: 'easeInOutCubic'
  });
};

// scroll to page bottom
export const scrollToBottom = () => {
  scroll.scrollToBottom({
    duration: 1500,
    smooth: 'easeInOutCubic'
  });
};
