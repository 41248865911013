// modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import axios from 'axios';
import DocumentTitle from 'react-document-title';
import uniqBy from 'lodash/uniqBy';
import { withTranslation } from "react-i18next";
// assets
import { analyticsRoutes } from 'assets/routes';
import { compareUsers } from 'assets/utils';
import { redirect } from 'utils';
// styles
import { Accordion, Container, Header, Icon, Input, List, Loader } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from 'store/User';

class Analytics extends Component {
  state = {
    loading: false,
    searchString: '',
    activeIndex: -1
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    this.setState({ loading: true });
    axios
      .get(analyticsRoutes.getUsers())
      .then(response => {
        this.setState({ loading: false, users: response.data.message });
      })
      .catch(error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
  };

  handleAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex, searchString: '' });
  }

  render() {
    const { activeIndex, searchString } = this.state;
    const { role, t } = this.props;

    let userItems = [];
    let accordionItems = [];

    if (this.state.users && this.state.users.length !== 0) {
      // filter out users
      const filteredUsers = this.state.users.filter(user => user.scope === 'user' || user.scope === 'expert');

      if (role === 'teacher') {
        // set empty object as class to students who have no class
        const transformedUsers = filteredUsers.map(user => ({
          ...user,
          class: user.class ? user.class : { _id: 'none', name: t("admin.analytics.no") }
        }));
        // get unique classes to build accordion items
        const allClasses = transformedUsers.map(user => user.class);
        const uniqueClasses = uniqBy(allClasses, '_id');

        accordionItems = uniqueClasses.map((cl, index) => {
          const isActive = activeIndex === index;
          // get only class students
          const classUsers = transformedUsers.filter(user => user.class && user.class._id === cl._id);
          // filter within class students with search string
          const searchClassUsers = classUsers.filter(value => {
            let name = `${value.lastName} ${value.firstName} ${value.email}`.toLowerCase();
            return name.includes(searchString.toLowerCase());
          });

          // transform class students to components
          const classUserItems = searchClassUsers.map((user, index) => (
            <List.Item key={`admin-analytics-users-${index}`}>
              <List.Icon name="user" />
              <List.Content>
                <List.Header
                  style={{
                    color: '#3697dd',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: '600',
                    marginBottom: '8px'
                  }}
                  onClick={() => redirect(this, `/admin/analytics/${user.email}`)}
                >
                  {`${user.lastName} ${user.firstName} ${user.scope === 'expert' ? '(эксперт)' : ''}`}
                </List.Header>
                <List.Description>{user.email}</List.Description>
              </List.Content>
            </List.Item>
          ));

          return (
            <Fragment key={`unique-class-${cl._id}`}>
              <Accordion.Title
                active={isActive}
                index={index}
                onClick={this.handleAccordion}
              >
                <Icon name='dropdown' />
                {t("admin.analytics.class")}: {cl.name}
              </Accordion.Title>
              <Accordion.Content active={isActive}>
                {isActive && (
                  <Fragment key={`unique-class-${cl._id}-content`}>
                    <div className="admin-analytics-class-subheader">{t("admin.index.users")}: {searchClassUsers.length}</div>
                    <div className="admin-analytics-class-search">
                      <Input
                        fluid
                        icon="search"
                        placeholder={t("admin.users.searchStudents")}
                        name="searchString"
                        value={searchString}
                        onChange={this.handleChange}
                      />
                    </div>
                    <List divided relaxed="very">
                      {classUserItems}
                    </List>
                  </Fragment>
                )}
              </Accordion.Content>
            </Fragment>
          )});
      } else {
        // filter users by name
        const searchUsers = filteredUsers.filter(value => {
          let name = `${value.lastName} ${value.firstName} ${value.email}`.toLowerCase();
          return name.includes(searchString.toLowerCase());
        });
        // sort users
        const sortedUsers = searchUsers.sort(compareUsers);

        // map users to components
        userItems = sortedUsers.map((user, index) => (
          <List.Item key={`admin-analytics-users-${index}`}>
            <List.Icon name="user" />
            <List.Content>
              <List.Header
                style={{
                  color: '#3697dd',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px'
                }}
                onClick={() => redirect(this, `/admin/analytics/${user.email}`)}
              >
                {`${user.lastName} ${user.firstName} ${user.scope === 'expert' ? '(эксперт)' : ''}`}
              </List.Header>
              <List.Description>{user.email}</List.Description>
            </List.Content>
          </List.Item>
        ));
      }
    }

    return (
      <DocumentTitle title="Журнал">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              {t("admin.analytics.studentJournal")}
            </Header>

            {role === 'teacher' ? (
              <Fragment>
                <div className="admin-analytics-subheader">{t("admin.users.classes")}: {accordionItems.length}</div>
                <Accordion fluid styled>
                  {accordionItems}
                </Accordion>
              </Fragment>
            ) : (
              <Fragment>
                <div className="admin-analytics-subheader">{t("admin.index.users")}: {userItems.length}</div>
                <div className="admin-analytics-search">
                  <Input
                    fluid
                    icon="search"
                    placeholder={t("admin.users.searchStudents")}
                    name="searchString"
                    value={searchString}
                    onChange={this.handleChange}
                  />
                </div>
                <List divided relaxed="very">
                  {userItems}
                </List>
              </Fragment>
            )}
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  role: state.user.user.scope
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Analytics);
