// modules
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox, Container, Form, Input, Message, TextArea } from 'semantic-ui-react';
import './index.css';
// components
import AlternativeEditor from 'components/AlternativeEditor';
import ReactAudioPlayer from 'react-audio-player';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

class QuestionBlock extends Component {
  state = {
    block:
      this.props.block && !this.props.new
        ? this.props.block
        : {
          text: '',
          audio: '',
          hint: '',
          recTime: '',
          correctText: '',
          wrongText: '',
          hintingText: '',
          openQuestion: false,
          multiple: false,
          approximate: false,
          testQuestion: false
        },
    options: this.props.block && !this.props.new && !this.props.block.openQuestion ? JSON.parse(this.props.block.options || '[]') : [],
    correctAnswer:
      this.props.block && !this.props.new ? JSON.parse(this.props.block.correctAnswer || '""') : this.props.block.multiple ? [] : '',
    newOption: ''
  };

  handleAddOption = () => {
    // append an option
    this.setState(prevState => ({
      options: [...prevState.options, prevState.newOption],
      newOption: ''
    }));
  };

  handleChangeCorrect = (e, data) => {
    const option = this.state.options[data.index];
    if (this.state.block.multiple) {
      let correctAnswer = [...this.state.correctAnswer];
      if (data.checked) {
        // add option to correct
        if (!correctAnswer.includes(option)) {
          correctAnswer.push(option);
        }
      } else {
        // remove option from correct
        if (correctAnswer.includes(option)) {
          // find index of option in correct answer
          let index = correctAnswer.findIndex(x => x === option);
          correctAnswer.splice(index, 1);
        }
      }
      this.setState({
        correctAnswer: correctAnswer
      });
    } else {
      let correctAnswer = this.state.correctAnswer;
      if (data.checked) {
        correctAnswer = option;
      } else {
        correctAnswer = '';
      }
      this.setState({
        correctAnswer: correctAnswer
      });
    }
  };

  handleDeleteOption = index => {
    // copy options array
    let options = [...this.state.options];
    // remove the option from correct answer (if applicable)
    if (this.state.block.multiple) {
      if (this.state.correctAnswer && this.state.correctAnswer.length !== 0) {
        let correctAnswer = [...this.state.correctAnswer];
        if (correctAnswer.includes(options[index])) {
          correctAnswer = correctAnswer.filter(x => x !== options[index]);
        }
        this.setState({ correctAnswer: correctAnswer });
      }
    } else {
      if (this.state.correctAnswer === options[index]) {
        this.setState({ correctAnswer: '' });
      }
    }
    // remove the option
    options.splice(index, 1);
    this.setState({
      options: options
    });
  };

  handleDeleteAudio = () => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handleChange = (e, data) => {
    let result = data.value;
    if (
      data.name === 'approximate' ||
      data.name === 'multiple' ||
      data.name === 'openQuestion' ||
      data.name === 'testQuestion' ||
      data.name === 'highlighted'
    ) {
      result = data.checked;
    }
    if (data.name === 'openQuestion' && result) {
      this.setState({
        correctAnswer: ''
      });
    } else if (data.name === 'openQuestion' && !result) {
      this.setState({
        correctAnswer: '',
        options: []
      });
    }
    if (data.name === 'multiple' && result) {
      this.setState({
        correctAnswer: [],
        options: []
      });
    } else if (data.name === 'multiple' && !result) {
      this.setState({
        correctAnswer: '',
        options: []
      });
    }
    if (data.name === 'correctAnswer' || data.name === 'newOption') {
      this.setState({
        [data.name]: result
      });
    } else {
      this.setState(prevState => ({
        block: {
          ...prevState.block,
          [data.name]: result
        }
      }));
    }
  };

  handleChangeNewOption = value => {
    this.setState({
      newOption: value
    });
  };

  render() {
    return (
      <div>
        {bold('Текст вопроса:')}
        <AlternativeEditor editorType="full" data={this.state.block.text || ''} onChange={this.handleTextChange} />
        <br />
        {bold('Аудио-вопрос')}
        {this.state.block.audio ? (
          <div style={{ marginBottom: '2em' }}>
            <ReactAudioPlayer src={this.state.block.audio} controls />
            <br />
            <br />
            <Button negative onClick={this.handleDeleteAudio}>
              Удалить аудио вопрос
            </Button>
          </div>
        ) : null}
        <input type="file" onChange={this.props.handleAudioSelect} style={{ marginBottom: '2em' }} />

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}

          <Container>
            <Checkbox
              label="Подсветить блок?"
              toggle
              name="highlighted"
              checked={this.state.block.highlighted || false}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          <Container>
            <Checkbox
              label="Тестовый вопрос?"
              toggle
              name="testQuestion"
              checked={this.state.block.testQuestion || false}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Параметры вопроса:')}

          <Container>
            <Checkbox
              label="Открытый вопрос?"
              toggle
              name="openQuestion"
              checked={this.state.block.openQuestion || false}
              onChange={this.handleChange}
              style={{ fontWeight: 400 }}
            />
          </Container>
          <br />
          {this.state.block.openQuestion ? (
            <Container>
              <Checkbox
                label="Приблизительный ответ? (засчитывается числовой ответ студента +- 10% от правильного ответа)"
                toggle
                name="approximate"
                checked={this.state.block.approximate || false}
                onChange={this.handleChange}
                style={{ fontWeight: 400 }}
              />
            </Container>
          ) : (
            <Container>
              <Checkbox
                label="Несколько вариантов ответа?"
                toggle
                name="multiple"
                checked={this.state.block.multiple || false}
                onChange={this.handleChange}
                style={{ fontWeight: 400 }}
              />
            </Container>
          )}
        </div>

        {this.state.block.openQuestion ? (
          <Container style={{ marginBottom: '1em' }}>
            {bold('Правильный ответ:')}
            <Input fluid name="correctAnswer" value={this.state.correctAnswer || ''} onChange={this.handleChange} />
          </Container>
        ) : (
          <Container style={{ marginBottom: '1em' }}>
            {bold('Варианты ответа:')}
            <Message icon="check" info header="Выбор ответа(-ов)" content="Чтобы выбрать ответ(-ы), нажми на них" />
            <Form style={{ marginBottom: '1em' }}>
              {this.state.options.length !== 0
                ? this.state.options.map((option, index) => (
                  <Form.Field className="admin-block-question-form-option" key={'admin-lesson-question-block-' + this.state.block._id + '-' + index}>
                    <Checkbox
                      checked={
                        this.state.block.multiple
                          ? this.state.correctAnswer.includes(option)
                          : this.state.correctAnswer === option
                      }
                      index={index}
                      radio={!this.state.block.multiple}
                      onChange={this.handleChangeCorrect}
                      value={option}
                      style={{
                        marginRight: '8px'
                      }}
                    />
                    <div className="ck-content" style={{ width: '100%', }}>
                      {ReactHtmlParser(option)}{' '}
                      <span className="admin-block-question-delete-option" onClick={() => this.handleDeleteOption(index)}>
                        (удалить)
                      </span>
                    </div>
                  </Form.Field>
                ))
                : null}
            </Form>
            {bold('Добавить вариант ответа:')}
            <div style={{ marginBottom: '1em' }}>
              <AlternativeEditor editorType="answerOption" data={this.state.newOption || ''} onChange={this.handleChangeNewOption} />
            </div>
            <Button onClick={this.handleAddOption} style={addStyle}>
              Добавить вариант ответа
            </Button>
          </Container>
        )}

        {bold('Подсказка:')}
        <TextArea
          autoHeight
          name="hint"
          value={this.state.block.hint}
          onChange={this.handleChange}
          style={{ width: '100%', minHeight: 100 }}
        />

        {bold('Рекоммендуемое время:')}
        <Input fluid name="recTime" type="number" value={this.state.block.recTime || ''} onChange={this.handleChange} />
        <Message
          info
          icon="stopwatch"
          header="Время решения вопроса"
          content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя"
        />

        <div>
          {bold('Текст при правильном ответе:')}
          <TextArea
            autoHeight
            name="correctText"
            value={this.state.block.correctText || ''}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
          {this.state.block.testQuestion ? null : (
            <div>
              {bold('Текст-подсказка при первом неправильном ответе:')}
              <TextArea
                autoHeight
                name="hintingText"
                value={this.state.block.hintingText || ''}
                onChange={this.handleChange}
                style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
              />
            </div>
          )}
          {bold('Текст при неправильном ответе:')}
          <TextArea
            autoHeight
            name="wrongText"
            value={this.state.block.wrongText || ''}
            onChange={this.handleChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>

        {this.props.new ? (
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={() => this.props.handleNewQuestionBlock(this.state.block, this.state.correctAnswer, this.state.options)}
            style={addStyle}
          >
            Добавить блок
          </Button>
        ) : (
          <>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.props.editing}
              onClick={() => this.props.handleEditQuestionBlock(this.state.block, this.state.correctAnswer, this.state.options)}
              style={editStyle}
            >
              Редактировать блок
            </Button>
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.props.deleting}
              onClick={this.props.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default QuestionBlock;
