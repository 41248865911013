// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
import { handleAnswerForFillingFromList, SLOT_OPTION_CLASSNAME } from "assets/interactiveQuestions";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
    padding-bottom: 12px;
`;

class InteractiveQuestion2 extends Component {
    constructor(props) {
        super(props);

        const { block } = this.props;

        const text = block.text || '';
        const interactive = block.interactive ? JSON.parse(block.interactive) : {};
        const slots = interactive.slots && Array.isArray(interactive.slots) ? interactive.slots.map((item) => item.trim().split(';')) : [];
        const userAnswers = block.answer ? JSON.parse(block.answer) : [];

        const matchedBlanks = text.match(/-[0-9]+-/g) || [];
        const correctAnswers = matchedBlanks.map((item) => {
            const itemWithoutDash = item.replaceAll('-', '');
            const itemAsIndex = parseInt(itemWithoutDash, 10) - 1;
            const resultItem = slots[itemAsIndex][0];
            return resultItem;
        });

        this.state = {
            userAnswers,
            correctAnswers,
            answered: !!block.answer,
            correct: block.correct,
            elapsed: block.time || 0,
            slots,
            showHint: false,
            testQuestion: block.testQuestion,
            hasReanswered: block.hasReanswered
        };

        const replacedText = text.replace(/-[0-9]+-/g, this.replaceSlotsInText);
        this.renderedElements = ReactHtmlParser(replacedText);
    }

    componentDidMount() {
        if (this.props.preview) return;
        if (!this.state.answered) {
            // set answering to false (for parent Lesson component)
            this.props.handleAnswering(true);
        } else {
            // color user answers
            const slotHtmlElements = document.querySelectorAll(`select.${SLOT_OPTION_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
            [...slotHtmlElements].forEach((element, index) => {
                element.value = this.state.userAnswers[index];
                element.classList.add(this.state.correctAnswers[index] === this.state.userAnswers[index] ? "correct" : "incorrect");
            });
        }
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer);
    }

    replaceSlotsInText = (match) => {
        const { answered, slots = [] } = this.state;
        const { block } = this.props;
        const { _id: blockId } = block;

        const index = parseInt(match.replace('-', ''), 10) - 1;
        let options = [];

        if (slots[index].length === 1) {
            options = slots.filter((item) => item.length === 1);
        } else {
            options = slots[index];
        }

        options = ['', ...options];
        const optionsHtml = options
            .sort()
            .map((option) => `<option value='${option}'>${option}</option>`)
            .join('');

        const isDisabled = answered ? 'disabled' : '';

        return `<select ${isDisabled} data-blockid='${blockId}' class='${SLOT_OPTION_CLASSNAME}'>${optionsHtml}</select>`;
    }

    startTimer = () => {
        this.start = new Date();
        this.timer = setInterval(this.tick, 1000);
    };

    handleQuestionVisibility = (isVisible) => {
        if (!isVisible) {
            if (this.timer) clearInterval(this.timer);
        }

        if (isVisible && !this.state.answered) {
            if (this.timer) clearInterval(this.timer);
            this.startTimer();
        }
    };

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000),
        });
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        const { correctAnswers, elapsed } = this.state;
        const { block, courseId, lessonId, index } = this.props;
        const { _id: blockId } = block;

        const { userAnswers, isCorrect } = handleAnswerForFillingFromList(blockId, correctAnswers);
        const stringifiedAnswer = JSON.stringify(userAnswers);

        // stop timer
        if (this.timer) clearInterval(this.timer);

        // set answering to false
        this.props.handleAnswering(false);
        // set answer (not sure whether we need this one)
        this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
        // increment/decrement number of correct answers
        this.props.handleCorrect(isCorrect);

        // handle coloring of user answers
        const slotHtmlElements = document.querySelectorAll(`select.${SLOT_OPTION_CLASSNAME}[data-blockid='${blockId}']`) || [];
        const slotElements = [...slotHtmlElements];
        
        const length = Math.min(userAnswers.length, correctAnswers.length);
        for (let i = 0; i < length; i++) {
            const element = slotElements[i];
            element.classList.add(correctAnswers[i] === userAnswers[i] ? "correct" : "incorrect");
            element.setAttribute('disabled', '');
        }

        // answer payload
        const payload = {
            answer: stringifiedAnswer,
            correct: isCorrect,
            time: elapsed,
        };

        // axios call
        const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
        axios.post(answerRoute, payload).then(() => {
            this.setState({
                answered: true,
                correct: isCorrect,
                userAnswers,
            });
        });
    };

    onReanswerClick = () => {
        // handle de-coloring of user answers
        const slotHtmlElements = document.querySelectorAll(`select.${SLOT_OPTION_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
        const slotElements = [...slotHtmlElements];

        slotElements.forEach((element) => {
            element.classList.remove('correct');
            element.classList.remove('incorrect');
            element.removeAttribute('disabled');
        });

        this.setState({
            answered: false,
            correct: undefined,
            elapsed: 0,
            hasReanswered: true
        }, () => {
            // start the timer again
            this.startTimer();
        });
    }

    render() {
        const { preview, t } = this.props;

        let answerButton = (
            <Button
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                onClick={this.handleAnswer}
            >
                {t("dashboard.course.lesson.question.answer")}
            </Button>
        );

        let reanswerButton = (
            <Button
                primary
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                onClick={this.onReanswerClick}
            >
                {t("dashboard.course.lesson.question.reanswer")}
            </Button>
        )

        if (preview) {
            answerButton = null;
            reanswerButton = null;
        }
        if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
            reanswerButton = null;
        }

        let hint;
        let explanation;
        let controls;

        // display hint
        if (this.props.block.hint) {
            hint = (
                <div className="dashboard-lesson-block-interactive-question-hint">
                    <Button
                        className="dashboard-lesson-block-interactive-question-hint-button"
                        onClick={this.handleHint}
                        size={
                            this.props.innerWidth > 700
                                ? "large"
                                : this.props.innerWidth > 600
                                ? "medium"
                                : this.props.innerWidth > 500
                                ? "tiny"
                                : "mini"
                        }
                    >
                        {this.state.showHint
                            ? t("dashboard.course.lesson.question.hideHint")
                            : t("dashboard.course.lesson.question.showHint")}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-lesson-block-interactive-question-hint-text">
                            <div className="ck-content" style={{ width: '100%',}}>
                                {ReactHtmlParser(this.props.block.hint)}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }

        // display explanation for answered question
        if (this.state.answered) {
            // extract captions for three scenarios
            const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
            // explanation text
            let explanationText = "";
            if (this.state.correct) {
                explanationText = correctText;
            } else {
                explanationText = this.state.hasReanswered ? wrongText : hintingText;
            }
            // replace name
            if (!!explanationText) {
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (this.props.block.time) {
                explanationText = explanationText.replace("$$time$$", this.props.block.time);
            }
            // replace rec time
            if (this.props.block.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
            }
            if (explanationText != "") {
            explanation = (
                    <div
                        className={
                            this.state.correct === true
                                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                                : "dashboard-lesson-block-interactive-question-explanation-wrong"
                        }
                        id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
                    >
                        <div className="ck-content" style={{ width: '100%',}}>
                            {ReactHtmlParser(explanationText)}
                        </div>
                    </div>
                );
            }
        }

        // interactive question controls
        controls = (
            <div className="dashboard-lesson-block-interactive-question-controls-container">
                {this.state.answered ? (
                    <div>{reanswerButton}</div>
                ) : (
                    <div>{answerButton}</div>
                )}

                <StyledTimerContainer>
                    <Statistic
                        className="dashboard-lesson-block-interactive-question-timer"
                        floated="right"
                        size="tiny"
                    >
                        <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                        <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
                    </Statistic>
                </StyledTimerContainer>
            </div>
        );

        return (
            <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
                <div
                    className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
                    id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
                >
                    <div className="dashboard-lesson-block-interactive-question-text">
                        <div className="ck-content" style={{ width: '100%' }}>{this.renderedElements}</div>
                    </div>
                    {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
                    {hint}
                    {controls}
                    {explanation}
                </div>
            </VisibilitySensor>
        );
    }
}

const mapStateToProps = state => {
    return {
        locale: state.config.locale
    };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion2));
