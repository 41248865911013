// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import "./index.css";
// components
// redux

class Materials extends Component {
    state = {
        courseId: this.props.match.params.courseId,
        loading: false,
    };

    componentDidMount() {
        this.fetchMaterials(this.state.courseId);
    }

    fetchMaterials = (courseId) => {
        this.setState({ loading: true });
        axios
            .get(dashboardRoutes.fetchLessons(courseId))
            .then((response) => {
                this.setState({ loading: false, lessons: response.data.message });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    render() {
        let materials = [];
        if (this.state.lessons && this.state.lessons.length !== 0) {
            for (let i = 0; i < this.state.lessons.length; i++) {
                let lesson = this.state.lessons[i];
                // check if lesson has extra materials
                if (lesson.extraMaterial) {
                    materials.push(
                        <div className="dashboard-materials-container-lesson" key={`dashboard-materials-lesson-${i}`}>
                            <div className="dashboard-materials-container-lesson-header">
                                Урок {lesson.section}: {lesson.name}
                            </div>
                            <div className="ck-content" style={{ width: '100%',}}>
                                {ReactHtmlParser(lesson.extraMaterial)}
                            </div>
                        </div>
                    );
                }
            }
        }

        return (
            <DocumentTitle title="Дополнительные материалы уроков">
                <div className="dashboard-materials-container">
                    <div className="dashboard-materials-container-header">Дополнительные материалы уроков</div>
                    {materials}
                </div>
            </DocumentTitle>
        );
    }
}

export default withRouter(
    connect(
        (store) => ({}),
        {}
    )(Materials)
);
