// modules
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import { authRoutes } from 'assets/routes';
import kundelikLogo from 'assets/images/kundelik-logo.png';
// styles
import { Accordion, Button, Icon, Input, Message } from 'semantic-ui-react';
// components
// redux
import { getUser } from 'store/User';
import { setCurrentCourse } from 'store/UI';
// utils
import kundelikIntegration from '../../../services/kundelik-integration';

const StyledWrapper = styled.div`
	font-weight: 500;

	${media.lessThan('small')`
		font-size: 12px;
	`}

	${media.between('small', 'medium')`
		font-size: 14px;
	`}

	${media.between('medium', 'large')`
		font-size: 16px;
	`}

	${media.greaterThan('large')`
		font-size: 18px;
	`}
`;

const StyledBlock = styled.div`
  margin-bottom: 2em;
`;

const StyledLink = styled(Link)`
  color: #4682e0;
`;

export const StyledLinkKundelikIdWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 32px;
    width: fit-content;
`;

export const StyledLinkKundelikIdButton = styled(Button)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  margin-top: 32px;

  & > img {
    width: 72px;
  }
`;

class Profile extends Component {
  state = {
    activeIndex: -1,
    changed: false,
    changing: false,
    loading: false,
    password: '',
    firstName: this.props.user.firstName || '',
    lastName: this.props.user.lastName || '',
    user: this.props.user,
  };

  componentDidMount() {
    const { t } = this.props;
    const { hash } = this.props.location;

    this.props.setCurrentCourse(null);
    this.fetchUser();
    kundelikIntegration.checkKundelikTokenAndLinkAccount(hash, this.fetchUser, t('dashboard.profile.kundelikIdAlreadyUsed'));
  }

  fetchUser = () => {
    this.setState({ loading: true });
    axios
      .get(authRoutes.getUser())
      .then(response => {
        const user = response.data.message;
        this.setState({ loading: false, user: user });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
  };

  handleAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleDismiss = () => {
    this.setState({ changed: false });
  };

  handleUpdatePassword = async () => {
    this.setState({ changing: true });
    const payload = {
      value: this.state.password
    };
    await axios.post(authRoutes.edit('password'), payload).catch(error => {
      this.setState({ changing: false });
    });
    this.setState({ changed: true, changing: false, password: '' }, () => {
      setTimeout(this.handleDismiss, 3000);
    });
  };

  handleUpdateFirstName = () => {
    this.setState({ changing: true });
    const payload = {
      value: this.state.firstName
    };
    axios.post(authRoutes.edit('firstName'), payload).catch(error => {
      this.setState({ changing: false });
    });
    this.setState({ changed: true, changing: false }, () => {
      setTimeout(this.handleDismiss, 3000);
    });
    this.fetchUser();
    this.props.getUser();
  };

  handleUpdateLastName = () => {
    this.setState({ changing: true });
    const payload = {
      value: this.state.lastName
    };
    axios.post(authRoutes.edit('lastName'), payload).catch(error => {
      this.setState({ changing: false });
    });
    this.setState({ changed: true, changing: false }, () => {
      setTimeout(this.handleDismiss, 3000);
    });
    this.fetchUser();
    this.props.getUser();
  };

  handleLinkKundelikAccount = () => {
    kundelikIntegration.authenticate(window.location.href);
  };

  handleUnlinkKundelikAccount = () => {
    axios
      .post(authRoutes.unlinkKundelikId())
      .then(() => {
        this.fetchUser();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          alert(this.props.t('dashboard.profile.kundelikIdAlreadyUsed'));
        }
      });
  };

  render() {
    const { activeIndex } = this.state;
    const { location, t } = this.props;

    const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
    const isLinkedToKundelik = this.state.user && this.state.user.kundelikId;

    return (
      <DocumentTitle title={t('dashboard.profile.title')}>
        <StyledWrapper>
          <h1>{t('dashboard.profile.title')}</h1>
          <StyledBlock>
            <StyledLink to={`${isInsideAdmin ? '/admin' : ''}/dashboard/courses`}>{t('dashboard.profile.back')}</StyledLink>
          </StyledBlock>

          <StyledBlock>
            <Accordion fluid>
              <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordion}>
                <Icon name="dropdown" />
                {t('dashboard.profile.changePassword.header')}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <Input
                  fluid
                  name="password"
                  placeholder={t('password')}
                  value={this.state.password}
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Button loading={this.state.changing} onClick={this.handleUpdatePassword} style={{ marginBottom: '1em' }}>
                  {t('dashboard.profile.changePassword.header')}
                </Button>
              </Accordion.Content>

              <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleAccordion}>
                <Icon name="dropdown" />
                {t('dashboard.profile.changeFirstName.header')}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <Input
                  fluid
                  name="firstName"
                  placeholder={t('firstName')}
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Button loading={this.state.changing} onClick={this.handleUpdateFirstName} style={{ marginBottom: '1em' }}>
                  {t('dashboard.profile.changeFirstName.header')}
                </Button>
              </Accordion.Content>

              <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleAccordion}>
                <Icon name="dropdown" />
                {t('dashboard.profile.changeLastName.header')}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <Input
                  fluid
                  name="lastName"
                  placeholder={t('lastName')}
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  style={{ marginBottom: '1em' }}
                />
                <Button loading={this.state.changing} onClick={this.handleUpdateLastName} style={{ marginBottom: '1em' }}>
                  {t('dashboard.profile.changeLastName.header')}
                </Button>
              </Accordion.Content>
            </Accordion>

            <StyledLinkKundelikIdWrapper fluid>
              {isLinkedToKundelik ? (
                <>
                  <div><b>Kundelik.kz ID:</b> {this.state.user.kundelikId}</div>
                  <StyledLinkKundelikIdButton
                    disabled={this.state.loading}
                    onClick={this.handleUnlinkKundelikAccount}
                  >
                    <Trans
                      i18nKey='dashboard.profile.unlinkKundelikId'
                      components={[<img src={kundelikLogo} alt='' />]}
                    />
                  </StyledLinkKundelikIdButton>
                </>
              ) : (
                <StyledLinkKundelikIdButton
                  disabled={this.state.loading}
                  onClick={this.handleLinkKundelikAccount}
                >
                  <Trans
                    i18nKey='dashboard.profile.linkKundelikId'
                    components={[<img src={kundelikLogo} alt='' />]}
                  />
                </StyledLinkKundelikIdButton>
              )}
            </StyledLinkKundelikIdWrapper>

            <Message
              hidden={!this.state.changed}
              positive
              onDismiss={this.handleDismiss}
              header={t('dashboard.profile.msg.header')}
              content={t('dashboard.profile.msg.content')}
            />
          </StyledBlock>
        </StyledWrapper>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUser()),
  setCurrentCourse: course => dispatch(setCurrentCourse(course))
});

export default withRouter(compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Profile));
