// modules
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

// assets
import kundelikLogo from 'assets/images/kundelik-logo.png';
import login_img from "../../../assets/images/landing/login.svg";
import password_img from "../../../assets/images/landing/password.svg";

// components
// redux
import { forgotPassword, login } from '../../../store/User';

// utils
import kundelikIntegration from '../../../services/kundelik-integration';
import { userRoles } from '../../../assets/utils';

import './Modal.css';
import './Login.css';
import { Divider } from 'semantic-ui-react';
import { useColor } from '../Utils/ColorContext';



const LoginModal = ({
    forgotPasswordDone,
    forgotPasswordError,
    history,
    kundelikId = '',
    loginDone,
    loginStatus,
    makeForgotPassword,
    makeLogin,
    role,
    onClose,
    t
  }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, togglePasswordVisibility] = useState(false);
    const [isForgotPasswordShown, toggleForgotPassword] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState('');
    const [forgotPasswordMsg, setForgotPasswordMsg] = useState('');
  
    useEffect(() => {
      if (loginDone && role) {
        const redirectUrl = userRoles.includes(role) ? '/dashboard/courses' : '/admin';
        history.push(redirectUrl);
      }
    }, [loginDone, role]);
  
    useEffect(() => {
      if (loginStatus) {
        switch (loginStatus) {
          case 401:
            setLoginErrorMsg(t('errors.401'));
            break;
          case 403:
            setLoginErrorMsg(t('errors.403'));
            break;
          case 404:
            setLoginErrorMsg(t('errors.404'));
            break;
          case 503:
            setLoginErrorMsg(t('errors.503'));
            break;
          default:
            setLoginErrorMsg(t('errors.default'));
        }
      }
    }, [loginStatus]);
  
    useEffect(() => {
      if (forgotPasswordDone) {
        setForgotPasswordMsg(<div className="auth-modal-success-msg">{t('landing.forgotmodal.msg.success')}</div>);
      }
    }, [forgotPasswordDone]);
  
    useEffect(() => {
      if (forgotPasswordError) {
        setForgotPasswordMsg(<div className="auth-modal-error-msg">{t('landing.forgotmodal.msg.error')}</div>);
      }
    }, [forgotPasswordError]);
  
    const handleForgot = event => {
      event.preventDefault();
      makeForgotPassword(email).catch(err => console.error(err));
    };
  
    const handleLogin = event => {
      event.preventDefault();
      makeLogin(email, password, kundelikId).catch(err => console.error(err));
    };
  
    const handleKundelikLogin = () => {
      kundelikIntegration.authenticate();
    }

    //
    const [showPassword, setShowPassword] = useState(false);
    const { color } = useColor();
    const headerTextStyle = {
        color: color === '#3C3C56' ? 'white' : 'black',
        // другие стили для текста заголовка
    };
    const titleStyle = {
        color: color === '#3C3C56' ? 'white' : '#7B6CEA',
    }
  
    return (
      <>
        {isForgotPasswordShown ? (
                <>
                    <form className="formlogin" onSubmit={handleForgot}>
                        <h1 style={titleStyle}>{t('landing.forgotmodal.header')}</h1>

                        <div className="landing-modal-form-note">{t('landing.forgotmodal.text1')}</div>
                        <div className="landing-modal-form-note">{t('landing.forgotmodal.text2')}</div>

                        {forgotPasswordMsg && <div className="landing-modal-message error">{forgotPasswordMsg}</div>}

                        <div className='login'>
                            <img className='logimg' src={login_img} alt="login"/>
                            <label htmlFor="email"></label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={email}
                              onChange={event => setEmail(event.target.value)}
                              placeholder='Email'/>
                        </div>

                        <div className="landing-modal-form-toggle-login" onClick={() => toggleForgotPassword(false)}>
                            <span>{t('landing.forgotmodal.login')}</span>
                        </div>
                        
                        <div className='buttonsubmit'>
                            <button type="submit">{t('send')}</button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <form className="formlogin" onSubmit={handleLogin}>
                        <h1 style={titleStyle}>{t('landing.loginmodal.header')}</h1>

                        {loginErrorMsg && <div className="landing-modal-message error">{loginErrorMsg}</div>}

                        {kundelikId && (
                          <div className='login'>
                            <img className='logimg' src={login_img} alt="login"/>
                            <label htmlFor="kundelikId"></label>
                            <input disabled type="text" id="kundelikId" name="kundelikId" placeholder='Kundelik ID' value={`Kundelik ID: ${kundelikId}`} />
                          </div>
                        )}

                        <div className='login'>
                            <img className='logimg' src={login_img} alt="login"/>
                            <label htmlFor="email"></label>
                            <input type="text" id="email" name="email" placeholder='Логин' value={email} onChange={event => setEmail(event.target.value)}/>
                        </div>

                        <div className='password'>
                            <label htmlFor="password"></label>
                            <img className='logimg' src={password_img} alt="password"/>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              id="password" name="password"
                              value={password}
                              required
                              placeholder={t('password')}
                              onChange={event => setPassword(event.target.value)}/>
                        </div>

                        <div className='helppassword' >
                            <div className='Show'>
                                <input type="checkbox" id="showPassword" name="isPasswordVisible" value={isPasswordVisible} checked={showPassword} onChange={() => setShowPassword(!showPassword)}/>
                                <label htmlFor="showPassword" style={headerTextStyle}>{t('landing.registermodal.showpassword')}</label>
                            </div>
                            <div className="forget" onClick={() => toggleForgotPassword(true)} style={headerTextStyle}>{t('landing.loginmodal.forgot')}</div>
                        </div>
                        
                        <div className='buttonsubmit'>
                            <button type="submit">{t('landing.login')}</button>
                        </div>

                        <Divider />
                        <div className="landing-modal-login-kundelik" onClick={handleKundelikLogin}>
                            <Trans i18nKey='landing.loginWith' components={[<img src={kundelikLogo} alt='' />]}/>
                        </div>
                    </form>
                </>
            )}
        </>
    );
  };

  const mapStateToProps = state => ({
    role: state.user.user.scope,
    forgotPasswordDone: state.user.forgotPassword.done,
    forgotPasswordError: state.user.forgotPassword.fail,
    loginDone: state.user.login.done,
    loginError: state.user.login.fail,
    loginStatus: state.user.login.status
  });
  
  const mapDispatchToProps = dispatch => ({
    makeForgotPassword: email => dispatch(forgotPassword(email)),
    makeLogin: (email, password, kundelikId) => dispatch(login(email, password, kundelikId))
  });
  
  export const WrappedLoginModal = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withTranslation()
  )(withRouter(LoginModal));