// modules
import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import menu from 'assets/images/menu.svg';
// components
import SidebarMenu from '../SidebarMenu';
import NavigationButton from 'components/NavigationButton';

const MenuNavigationContainer = styled.div`
  position: fixed;
  display: flex;

  @media print {
    display: none;
  }

  ${media.lessThan('600px')`
    position: relative;
    align-self: center;
    padding: 0px 16px;
  `}

  ${media.greaterThan('600px')`
    align-self: flex-start;
    margin-right: 24px;
    padding: 24px 0px;
    height: 100%;
  `}
`;

const MenuNavigation = () => {
  const [isSidebarMenuShown, toggleSidebarMenu] = useState(false);

  return (
    <MenuNavigationContainer>
      <SidebarMenu open={isSidebarMenuShown} toggleSidebarMenu={toggleSidebarMenu} />

      <div>
        <NavigationButton id="menu-navigation-button" backgroundColor="#eaeaea" onClick={() => toggleSidebarMenu(true)}>
          <img src={menu} alt="" />
        </NavigationButton>
      </div>
    </MenuNavigationContainer>
  );
};

export default MenuNavigation;
