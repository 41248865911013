import store from '../store/Store';
import { setLocale } from '../store/Config';
import { logout } from '../store/User';

// redirect to a given path using specified context (for router)
export const redirect = (context, path) => {
  context.props.history.push(path);
};

// set locale and reload page
export const changeLocale = locale => {
  // dispatch set locale action
  store.dispatch(setLocale(locale));
  // refresh page
  window.location.reload();
};

// logout and scroll to page top
export const logoutAndScrollToTop = (...next) => {
  // dispatch logout action
  store.dispatch(logout());
  // scroll to page top
  window.scrollTo(0, 0);
};
