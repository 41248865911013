// modules
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';

const TermsModal = ({ onClose, t }) => (
  <Modal size="small" open closeOnDimmerClick={false} onClose={onClose} >
    <div className="landing-modal">
      <div className="landing-modal-cross" onClick={onClose}>
        <img src={cross} alt="Cross icon" />
      </div>

      <div className="landing-modal-header">
        <span>{t("landing.header.terms")}</span>
      </div>

      <div className="landing-modal-terms">
        {ReactHtmlParser(t('landing.terms.content'))}
      </div>
    </div>
  </Modal>
);

export const WrappedTermsModal = withTranslation()(TermsModal);
