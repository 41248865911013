// modules
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
// assets
import { practicesRoutes } from "assets/routes";
// styles
import { Button, Checkbox, Form, Image, Statistic } from "semantic-ui-react";
import "./index.css";
// components
// redux

class Question extends Component {
    state = {
        answer: this.props.question.answer ? this.props.question.answer : [],
        answered: !!this.props.question.answer,
        correct: this.props.question.correct,
        elapsed: 0,
        approximate: !!this.props.question.approximate,
        indefinite: !!this.props.question.indefinite,
        multiple: this.props.question.correctAnswer.length > 1 ? true : false,
        openQuestion: !!this.props.question.openQuestion,
        showHint: false,
        testQuestion: this.props.question.testQuestion
    };

    componentDidMount() {
        if (!this.state.answered) {
            // start the timer
            this.timer = setInterval(this.tick, 1000);
            this.start = new Date();
            // set answering to false (for parent Practice component)
            this.props.handleAnswering(true);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000)
        });
    };

    handleChange = (e, data) => {
        if (data.name === "open" || data.name === "closed") {
            this.setState({
                answer: data.value
            });
        } else if (data.name === "closed") {
            this.setState({
                answer: [data.value]
            });
        } else if (data.name === "multiple") {
            let answer = [...this.state.answer];
            if (data.checked) {
                if (!answer.includes(data.value)) {
                    answer.push(data.value);
                    this.setState({ answer: answer });
                }
            } else {
                answer = answer.filter(x => x !== data.value);
                this.setState({ answer: answer });
            }
        } else {
            this.setState({
                [data.name]: data.value
            });
        }
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        // stop timer
        clearInterval(this.timer);
        // pick necessary fields and correct answer
        let lessonId = this.props.id;
        let questionId = this.props.question._id;
        let correct = false;
        let { answer, elapsed } = this.state;
        let correctAnswer = this.props.question.correctAnswer;
        // record answer
        if (this.state.openQuestion) {
            // lowercase answer
            answer = answer.toLowerCase();
            // remove whitespaces
            answer = answer.replace(/\s/g, "");
            if (isNaN(correctAnswer)) {
                // text answer
                if (answer === correctAnswer) {
                    correct = true;
                }
            } else {
                // number answer
                let corr = Number(correctAnswer);
                if (this.state.approximate) {
                    // approximate
                    let corrDown = corr - corr * 0.1;
                    let corrUp = corr + corr * 0.1;
                    answer = Number(answer);
                    if (answer >= corrDown && answer <= corrUp) {
                        correct = true;
                    }
                } else {
                    if (answer === correctAnswer) {
                        correct = true;
                    }
                }
            }
        } else {
            if (this.state.multiple) {
                let multipleCorrect = false;
                if (this.state.answer.length === correctAnswer.length) {
                    multipleCorrect = true;
                    for (let i = 0; i < correctAnswer.length; i++) {
                        if (!this.state.answer.includes(correctAnswer[i])) {
                            multipleCorrect = false;
                            break;
                        }
                    }
                }
                correct = multipleCorrect;
            } else {
                if (correctAnswer.includes(answer)) {
                    correct = true;
                }
            }
        }
        // set answering to false (for parent Lesson component)
        this.props.handleAnswering(false);
        if (correct) {
            // increment correct counter
            this.props.handleCorrect();
        }
        // mark time (for parent Lesson component)
        this.props.handleMarkTime(this.props.index, elapsed);
        // answer payload
        const payload = {
            answer: JSON.stringify(answer),
            correct: correct,
            time: elapsed
        };
        // axios call
        axios.post(practicesRoutes.answer(lessonId, questionId), payload).then(() => {
            this.setState({ answered: true, correct: correct });
        });
    };

    render() {
        let header = `Задание ${this.props.index + 1}`;
        let hint;
        let question;
        let image;
        let explanation;
        // display hint
        if (this.props.question.hint) {
            hint = (
                <div className="dashboard-practice-question-hint">
                    <Button onClick={this.handleHint} style={{ marginBottom: "2em" }}>
                        {this.state.showHint ? "Скрыть подсказку" : "Показать подсказку"}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-practice-question-hint-text">{this.props.question.hint}</div>
                    ) : null}
                </div>
            );
        }
        // display image
        if (this.props.question.image) {
            image = (
                <Image
                    className="dashboard-practice-question-image"
                    centered
                    size="huge"
                    src={this.props.question.image}
                    alt=""
                />
            );
        }
        // display explanataion for answered question
        if (this.state.answered && !this.state.testQuestion) {
            // explanation text
            let explanationText = "";
            if (this.state.indefinite) {
                explanationText = this.props.question.explanationText;
            } else {
                if (this.state.correct) {
                    explanationText = this.props.question.correctText;
                } else {
                    explanationText = this.props.question.wrongText;
                }
            }
            // replace name
            if (!!explanationText) {
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (!!this.props.question.time) {
                explanationText = explanationText.replace("$$time$$", this.props.question.time);
            }
            // replace rec time
            if (!!this.props.question.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.question.recTime);
            }
            explanation = (
                <div
                    className={
                        this.state.indefinite
                            ? "dashboard-practice-question-explanation"
                            : this.state.correct === true
                            ? "dashboard-practice-question-explanation-correct"
                            : "dashboard-practice-question-explanation-wrong"
                    }
                >
                    {ReactHtmlParser(explanationText)}
                </div>
            );
        }
        // question form
        if (this.state.openQuestion || this.state.indefinite) {
            // open question form
            question = (
                <Form autoComplete="off" className="dashboard-practice-question-form" onSubmit={this.handleAnswer}>
                    <div className="dashboard-practice-question-form-instruction">Твой ответ</div>
                    <Form.Input
                        fluid
                        name="open"
                        required
                        placeholder={this.props.question.approximate ? "Приблизительный ответ" : "Ответ"}
                        readOnly={this.state.answered}
                        size="large"
                        value={this.state.answer || ""}
                        onChange={this.handleChange}
                    />
                    {this.state.answered ? null : this.props.showTimer ? (
                        <div>
                            <Statistic floated="right" size="tiny">
                                <Statistic.Label>Время</Statistic.Label>
                                <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                            </Statistic>
                            <Form.Button size="large" type="submit">
                                Ответить
                            </Form.Button>
                        </div>
                    ) : (
                        <Form.Button size="large" type="submit">
                            Ответить
                        </Form.Button>
                    )}
                </Form>
            );
        } else {
            // closed question form
            let options = this.props.question.options;
            if (this.state.multiple) {
                // multiple choice (checkboxes)
                question = (
                    <Form autoComplete="off" className="dashboard-practice-question-form" onSubmit={this.handleAnswer}>
                        <div className="dashboard-practice-question-form-instruction">Выбери подходящие варианты</div>
                        {options.map((option, index) => (
                            <Form.Field key={"dashboard-practice-question-" + this.props.question._id + "-" + index}>
                                <Checkbox
                                    checked={this.state.answer.includes(option)}
                                    label={option}
                                    name="multiple"
                                    readOnly={this.state.answered}
                                    style={{ fontSize: 18, fontStyle: "italic" }}
                                    value={option}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ))}
                        {this.state.answered ? null : this.props.showTimer ? (
                            <div>
                                <Statistic floated="right" size="tiny">
                                    <Statistic.Label>Время</Statistic.Label>
                                    <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                                </Statistic>
                                <Form.Button size="large" type="submit">
                                    Ответить
                                </Form.Button>
                            </div>
                        ) : (
                            <Form.Button size="large" type="submit">
                                Ответить
                            </Form.Button>
                        )}
                    </Form>
                );
            } else {
                // one choice (radio buttons)
                question = (
                    <Form autoComplete="off" className="dashboard-practice-question-form" onSubmit={this.handleAnswer}>
                        <div className="dashboard-practice-question-form-instruction">Выбери один из вариантов</div>
                        {options.map((option, index) => (
                            <Form.Field key={"dashboard-practice-question-" + this.props.question._id + "-" + index}>
                                <Checkbox
                                    checked={this.state.answer === option}
                                    label={option}
                                    name="closed"
                                    radio
                                    readOnly={this.state.answered}
                                    style={{ fontSize: 18, fontStyle: "italic" }}
                                    value={option}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ))}
                        {this.state.answered ? null : this.props.showTimer ? (
                            <div>
                                <Statistic floated="right" size="tiny">
                                    <Statistic.Label>Время</Statistic.Label>
                                    <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                                </Statistic>
                                <Form.Button size="large" type="submit">
                                    Ответить
                                </Form.Button>
                            </div>
                        ) : (
                            <Form.Button size="large" type="submit">
                                Ответить
                            </Form.Button>
                        )}
                    </Form>
                );
            }
        }

        return (
            <div
                className="dashboard-practice-question"
                id={`practice-${this.props.id}-question-${this.props.question._id}`}
            >
                <div className="dashboard-practice-question-header">{header}</div>
                <div className="dashboard-practice-question-text">{ReactHtmlParser(this.props.text)}</div>
                <div className="dashboard-practice-question-image">{image}</div>
                {hint}
                {question}
                {explanation}
            </div>
        );
    }
}

export default Question;
