// modules
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import { dashboardRoutes } from '../../../../assets/routes';
// styles
// components
import { Button, Loader, Progress } from 'semantic-ui-react';
// redux

const StyledContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

class Settings extends Component {
  state = {
    loading: false
  };

  componentDidMount() {}

  resetCourse = () => {
    const { courseId, location, t } = this.props;

    const r = window.confirm(t('alert'));

    if (r) {
      this.setState({ loading: true });
  
      // edit course
      axios.post(dashboardRoutes.resetExpertCourseProgress(courseId)).then(
        () => {
          this.setState({ loading: false }, () => {
            const isInsideAdmin = location && location.pathname && location.pathname.startsWith('/admin');
            window.location = `${isInsideAdmin ? '/admin' : ''}/dashboard/courses/${courseId}`;
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    }
  };

  renderLoader = () => {
    const { loading } = this.state;

    if (loading) {
      return <Loader active size="large" />;
    }
  };

  render() {
    const { loading } = this.state;
    const { course, t, user } = this.props;

    const courseLessons = (course && course.lessons) ? course.lessons.map(item => item._id) : [];
    const userCourse = user.courses.find(item => item.course === course._id);
    const courseLessonsCount = course && courseLessons ? courseLessons.length : 0;
    let passedLessonsCount = 0;
    if (userCourse) {
      passedLessonsCount = course && courseLessons ? courseLessons.filter(item => userCourse.lessons.find(x => x._id === item)).length : 0;
    }

    return (
      <DocumentTitle title={t('dashboard.course.settings.header')}>
        <main>
          <h1>{t('dashboard.course.settings.header')}</h1>
          {this.renderLoader()}
          {!loading && (
            <StyledContainer>
              <p>
                <b>{t('dashboard.course.settings.courseProgress')}</b>
              </p>
              <Progress color='olive' size='medium' progress percent={Math.round(passedLessonsCount / courseLessonsCount * 100)} />
              <Button primary onClick={this.resetCourse}>
                {t('dashboard.course.settings.resetCourse')}
              </Button>
            </StyledContainer>
          )}
        </main>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Settings)));
