// modules
import React, {useState,useEffect,useRef} from 'react';
import { withTranslation } from "react-i18next";

import ToggleButton from "./ToggleButton";
import {useColor} from '../Utils/ColorContext';
import './WelcomePage.css';

function Footer() {
    const {color} = useColor();

    const headerStyle = {
        backgroundColor: color === '#3C3C56' ? color : 'white',

        transition: 'background-color 1s, color 1s, box-shadow 1s',

    };
    return (
        <div className="Footer">
            <div className='Info-button' style={headerStyle}>
                <div className='Company-name'>© 2023</div>
                <div className='nav-and-lang'><ToggleButton/></div>
            </div>

        </div>
    );
}

export default Footer;