// modules
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import media from 'styled-media-query';
import DocumentTitle from 'react-document-title';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// assets
import { capitalize } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
import { languages, roles } from 'assets/strings';
import { compareUsers } from 'assets/utils';
// styles
import { Button, Container, Header, Icon, Input, List, Loader } from 'semantic-ui-react';
// components
import AddUsersModal from './AddUsersModal';
// redux

const StyledListItemHeader = styled(List.Header)`
  margin-bottom: 8px;
  color: #3697dd;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 8px;
`;

const StyledCreateButton = styled.button`
  margin: 12px 0;
  padding: 12px 16px;
  background-color: ${props => props.color};
  border-radius: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('600px')`
    margin: 1em 0 0.4em 0;
    font-size: 12px;
  `}
`;

const StyledSubheader = styled.h3`
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;

  ${media.lessThan('600px')`
    font-size: 16px;
  `}
`;

const StyledClassesContainer = styled.div`
  margin: 16px 0;
`;

const StyledClassesList = styled(List)`
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
`;

const StyledStudentsContainer = styled.div`
  margin: 16px 0;
`;

const StyledStudentsSearch = styled.div`
  margin-bottom: 24px;

  ${media.lessThan('600px')`
    margin-bottom: 16px;
  `}
`;

const StyledStudentsList = styled(List)`
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
`;

function LoaderComponent() {
  return <Loader active size="large" />;
}

function Users({ history, t }) {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, openModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [classes, setClasses] = useState([]);
  const [users, setUsers] = useState([]);
  // store selectors
  const role = useSelector(state => state.user.user.scope);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    setLoading(true);

    Promise.all(
      role === 'teacher'
        ? [fetcher(adminRoutes.classes.getClasses(), setClasses), fetcher(adminRoutes.users.getUsers(), setUsers)]
        : [fetcher(adminRoutes.users.getUsers(), setUsers)]
    )
      .then(() => setLoading(false))
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }

  function refreshUsers() {
    fetcher(adminRoutes.users.getUsers(), setUsers).catch(err => console.error(err));
  }

  function fetcher(route, setter) {
    return new Promise((resolve, reject) => {
      axios
        .get(route)
        .then(response => {
          setter(response.data.message);
          resolve();
        })
        .catch(err => reject(err.response));
    });
  }

  function removeFromStudents(id) {
    const r = window.confirm(t("admin.users.removeFromMyStudentsConfirm"));
    if (r) {
      axios
        .post(adminRoutes.users.removeStudentFromTeacher(id))
        .then(async () => {
          await fetcher(adminRoutes.users.getUsers(), setUsers);
        })
        .catch(err => {
          console.error(err);
          alert(t("admin.users.errorHasOccurred"));
        });
    }
  }

  const classItems = useMemo(() => {
    return classes.map((cl, index) => {
      return (
        <List.Item key={`admin-classes-${index}`}>
          <List.Icon name="group" />
          <List.Content>
            <StyledListItemHeader onClick={() => history.push(`/admin/classes/${cl._id}`)}>
              {`${cl.name} (${t(cl.language)})`}
            </StyledListItemHeader>
            <List.Description>{cl.description}</List.Description>
          </List.Content>
        </List.Item>
      );
    });
  }, [classes]);

  const userItems = useMemo(() => {
    const filteredUsers = users.filter(user => user.scope === 'user' || user.scope === 'expert');
    const searchUsers = filteredUsers.filter(user => {
      let name = `${user.lastName} ${user.firstName} ${user.email}`.toLowerCase();
      return name.includes(searchString.toLowerCase());
    });
    const sortedUsers = searchUsers.sort(compareUsers);

    return sortedUsers.map((user, index) => {
      // const verified = user.verification ? user.verification.status : false;
      // const paid = user.payment ? user.payment.completed : false;

      return (
        <List.Item key={`admin-users-${index}`}>
          {role === 'teacher' && (
            <List.Content floated="right">
              <Button icon negative size="small" labelPosition="right" onClick={() => removeFromStudents(user._id)}>
                <Icon name="remove" />
                {t("admin.users.removeFromMyStudents")}
              </Button>
            </List.Content>
          )}
          <List.Icon name="user" />
          <List.Content>
            <StyledListItemHeader onClick={() => history.push(`/admin/users/${user.email}`)}>
              {`${user.lastName} ${user.firstName}`}
            </StyledListItemHeader>
            <List.Description>
              <div><strong>Email (логин):</strong> {user.email}</div>
              {user.class && user.class.name && <div><strong>{t("admin.analytics.class")}:</strong> {user.class.name}</div>}
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });
  }, [searchString, users]);

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <DocumentTitle title="Управление учениками">
      <Container>
        <AddUsersModal
          open={isModalOpen}
          onClose={() => openModal(false)}
          fetcher={fetcher}
          parentRefresher={refreshUsers}
        />

        <Header size="large" textAlign="center">
          {t("admin.users.studentManagement")}
        </Header>

        {role === 'teacher' && (
          <Fragment>
            <StyledButtonContainer>
              <StyledCreateButton color="#46b761" onClick={() => openModal(true)}>
                {t("admin.users.addStudent")}
              </StyledCreateButton>
              {/* <StyledCreateButton color="#009b72" onClick={() => history.push('/admin/users/new')}>
                Создать нового ученика
              </StyledCreateButton> */}
              <StyledCreateButton color="#3a8fb1" onClick={() => history.push('/admin/classes/new')}>
                {t("admin.users.createNewClass")}
              </StyledCreateButton>
            </StyledButtonContainer>

            <StyledClassesContainer>
              <StyledSubheader>{t("admin.users.classes")}: {classItems.length}</StyledSubheader>
              <StyledClassesList divided relaxed="very">
                {classItems}
              </StyledClassesList>
            </StyledClassesContainer>
          </Fragment>
        )}

        <StyledStudentsContainer>
          <StyledSubheader>{t("admin.index.users")}: {userItems.length}</StyledSubheader>
          <StyledStudentsSearch>
            <Input
              fluid
              icon="search"
              placeholder={t("admin.users.searchStudents")}
              value={searchString}
              onChange={(e, data) => setSearchString(data.value)}
            />
          </StyledStudentsSearch>
          <StyledStudentsList divided relaxed="very">
            {userItems}
          </StyledStudentsList>
        </StyledStudentsContainer>
      </Container>
    </DocumentTitle>
  );
}

export default withRouter(compose(
  withTranslation()
)(Users));
