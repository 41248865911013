// modules
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// components
import { Dropdown } from 'semantic-ui-react';
// redux
import { setLocale } from 'store/Config';

const LanguageSelect = ({ changeLocale, locale, t }) => {
  const languageOptions = [{ key: 'kk', value: 'kk', text: t('kk') }, { key: 'ru', value: 'ru', text: t('ru') }];
  const onChange = (_, { value }) => changeLocale(value);

  return <Dropdown selection options={languageOptions} value={locale} onChange={onChange} />;
};

const mapStateToProps = state => ({
  locale: state.config.locale
});

const mapDispatchToProps = dispatch => ({
  changeLocale: locale => {
    dispatch(setLocale(locale));
    window.location.reload();
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(LanguageSelect);
