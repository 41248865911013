export const SLOT_CLASSNAME = 'dashboard-lesson-block-interactive-question-slot';
export const SLOT_OPTION_CLASSNAME = 'dashboard-lesson-block-interactive-question-slot-option'
export const GROUP_ELEMENT_CLASSNAME = 'dashboard-lesson-block-interactive-question-group-element'
export const GROUP_SLOT_CLASSNAME = 'dashboard-lesson-block-interactive-question-group-slot';
export const PAIR_FIRST_ELEMENT_CLASSNAME = 'dashboard-lesson-block-interactive-question-pair-first-element';
export const PAIR_ELEMENT_CLASSNAME = 'dashboard-lesson-block-interactive-question-pair-element';
export const MATCH_SLOT_CLASSNAME = 'dashboard-lesson-block-interactive-question-match-slot';
export const MATCH_ELEMENT_CLASSNAME = 'dashboard-lesson-block-interactive-question-match-element';
export const SELECT_WORDS_CLASSNAME = 'dashboard-lesson-block-interactive-question-select-words';

export function handleAnswerForFillingSlots(blockId, correctAnswers) {
    let isCorrect = true;

    const slotHtmlElements = document.querySelectorAll(`div.${SLOT_CLASSNAME}[data-blockid='${blockId}']`) || [];
    const userAnswers = [...slotHtmlElements].map((input) => input.textContent);

    const length = Math.min(userAnswers.length, correctAnswers.length);
    for (let i = 0; i < length; i++) {
        if (!correctAnswers[i].includes(userAnswers[i])) {
            isCorrect = false;
            break;
        }
    }

    return {
        userAnswers,
        isCorrect,
    };
}

export function handleAnswerForFillingFromList(blockId, correctAnswers) {
    let isCorrect = true;

    const slotHtmlElements = document.querySelectorAll(`select.${SLOT_OPTION_CLASSNAME}[data-blockid='${blockId}']`) || [];
    const userAnswers = [...slotHtmlElements].map((input) => input.value);

    const length = Math.min(userAnswers.length, correctAnswers.length);
    for (let i = 0; i < length; i++) {
        if (correctAnswers[i] !== userAnswers[i]) {
            isCorrect = false;
            break;
        }
    }

    return {
        userAnswers,
        isCorrect,
    };
}