// modules
import React from 'react';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Checkbox, Container, Input, Message, TextArea } from 'semantic-ui-react';
//
import AlternativeEditor from 'components/AlternativeEditor';
// redux

const IndefiniteQuestionBlock = props => {
  return (
    <div>
      {bold('Текст вопроса:')}
      <Container style={{ marginBottom: '1em' }}>
        <AlternativeEditor editorType="full" data={props.block.text || ''} onChange={props.onTextChange} />
      </Container>

      <div style={{ marginBottom: '2em' }}>
        {bold('Дополнительные настройки блока:')}

        <Container>
          <Checkbox
            label="Подсветить блок?"
            toggle
            name="highlighted"
            checked={props.block.highlighted || false}
            onChange={props.onChange}
            style={{ fontWeight: 400 }}
          />
        </Container>
        <br />
        <Container>
          <Checkbox
            label="Тестовый вопрос?"
            toggle
            name="testQuestion"
            checked={props.block.testQuestion || false}
            onChange={props.onChange}
            style={{ fontWeight: 400 }}
          />
        </Container>
      </div>

      {bold('Подсказка:')}
      <TextArea
        autoHeight
        name="hint"
        value={props.block.hint || ''}
        onChange={props.onChange}
        style={{ width: '100%', minHeight: 100 }}
      />
      
      {bold('Рекоммендуемое время:')}
      <Input fluid name="recTime" type="number" value={props.block.recTime || ''} onChange={props.onChange} />
      
      <Message
        info
        icon="stopwatch"
        header="Время решения вопроса"
        content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном ответе), введи $$time$$, программа сама заменит время на результат пользователя. Если вставить $$rec_time$$, программа заменит это на рекоммендуемое время"
      />

      {props.block.testQuestion ? null : (
        <div>
          {bold('Текст после ответа на вопрос:')}
          <TextArea
            autoHeight
            name="explanationText"
            value={props.block.explanationText}
            onChange={props.onChange}
            style={{ marginBottom: '1em', width: '100%', minHeight: 100 }}
          />
        </div>
      )}
    </div>
  );
};

export default IndefiniteQuestionBlock;
