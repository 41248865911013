// modules
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
// styles
import { Modal } from 'semantic-ui-react';
// components
import MathjaxWrapper from 'components/MathjaxWrapper';
const InstructionModal = ({ onClose, open, t, text }) => (
  <Modal closeIcon open={open} onClose={onClose} size="large">
    <Modal.Header>{t('dashboard.course.navigation.instruction.header')}</Modal.Header>
    <Modal.Content className="ck-content" style={{ width: '100%',}}>
      <MathjaxWrapper text={ReactHtmlParser(t('dashboard.course.navigation.instruction.text'))} />
    </Modal.Content>
  </Modal>
);

export default withTranslation()(InstructionModal);
