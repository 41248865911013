// modules
import React, { Component } from 'react';
// assets
import { bold } from 'assets/formatUtils';
// styles
import { Button, Checkbox } from 'semantic-ui-react';
// components
import AlternativeEditor from 'components/AlternativeEditor';
import ReactAudioPlayer from 'react-audio-player';
// redux

class TextBlock extends Component {
  render() {
    return (
      <div>
        <div style={{ marginBottom: '2em' }}>
          {bold('Текст в блоке:')}
          <AlternativeEditor editorType='full' data={this.props.block.text || ''} onChange={this.props.onTextChange} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Аудиофайл:')}
          {this.props.block.audio && (
            <div style={{ marginBottom: '1em' }}>
              <ReactAudioPlayer src={this.props.block.audio} controls style={{ marginBottom: '1em' }} />
              <br />
              <Button negative onClick={this.props.onAudioDelete}>
                Удалить аудио вопрос
              </Button>
            </div>
          )}
          <input type='file' onChange={this.props.onAudioSelect} />
        </div>

        <div style={{ marginBottom: '2em' }}>
          {bold('Дополнительные настройки блока:')}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
            <Checkbox
              label='Добавить микрофон'
              toggle
              name='hasVoiceRecorder'
              checked={this.props.block.hasVoiceRecorder || false}
              onChange={this.props.onChange}
              style={{ fontWeight: 400 }}
            />
            <Checkbox
              label='Подсветить блок?'
              toggle
              name='highlighted'
              checked={this.props.block.highlighted || false}
              onChange={this.props.onChange}
              style={{ fontWeight: 400 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TextBlock;
