// modules
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
import { Trans, withTranslation } from 'react-i18next';
// assets
import { authRoutes } from 'assets/routes';
import kundelikLogo from 'assets/images/kundelik-logo.png';
// styles
import { Container, Header, Loader } from 'semantic-ui-react';
// components
import { StyledLinkKundelikIdWrapper, StyledLinkKundelikIdButton } from '../../Dashboard/Profile';
// redux
import { logout } from 'store/User';
// utils
import kundelikIntegration from '../../../services/kundelik-integration';

class Settings extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    const { t } = this.props;
    const { hash } = this.props.location;

    this.fetchUser();
    kundelikIntegration.checkKundelikTokenAndLinkAccount(hash, this.fetchUser, t('dashboard.profile.kundelikIdAlreadyUsed'));
  }

  fetchUser = () => {
    this.setState({ loading: true });

    axios
      .get(authRoutes.getUser())
      .then(response => {
        this.setState({ loading: false, user: response.data.message });
      })
      .catch(error => {
        this.setState({ loading: false });

        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  handleLinkKundelikAccount = () => {
    kundelikIntegration.authenticate(window.location.href);
  };

  handleUnlinkKundelikAccount = () => {
    axios
      .post(authRoutes.unlinkKundelikId())
      .then(() => {
        this.fetchUser();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          alert(this.props.t('dashboard.profile.kundelikIdAlreadyUsed'));
        }
      });
  };

  render() {
    const { t } = this.props;
    const isLinkedToKundelik = this.state.user && this.state.user.kundelikId;

    return (
      <DocumentTitle title="Настройки">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              {t('admin.settings.title')}
            </Header>

            <StyledLinkKundelikIdWrapper fluid>
              {isLinkedToKundelik ? (
                <>
                  <div><b>Kundelik.kz ID:</b> {this.state.user.kundelikId}</div>
                  <StyledLinkKundelikIdButton
                    disabled={this.state.loading}
                    onClick={this.handleUnlinkKundelikAccount}
                  >
                    <Trans
                      i18nKey='dashboard.profile.unlinkKundelikId'
                      components={[<img src={kundelikLogo} alt='' />]}
                    />
                  </StyledLinkKundelikIdButton>
                </>
              ) : (
                <StyledLinkKundelikIdButton
                  disabled={this.state.loading}
                  onClick={this.handleLinkKundelikAccount}
                >
                  <Trans
                    i18nKey='dashboard.profile.linkKundelikId'
                    components={[<img src={kundelikLogo} alt='' />]}
                  />
                </StyledLinkKundelikIdButton>
              )}
            </StyledLinkKundelikIdWrapper>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Settings);
