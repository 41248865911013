// modules
// modules
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// components
import { Button, Icon, Input, List, Loader, Modal } from 'semantic-ui-react';
// assets
import { adminRoutes } from 'assets/routes';

const StyledListItemHeader = styled(List.Header)`
  margin-bottom: 8px;
  color: #3697dd;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const AddUsersModal = ({ fetcher, onClose, open, parentRefresher, t }) => {
    const [loading, setLoading] = useState(false);
    const [teacherStudents, setTeacherStudents] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(() => {
        if (open) {
            setSearchString('');
            setSearchResult([]);
        }
    }, [open]);

    const searchStudentByEmail = (email) => {
        setLoading(true);
        axios
            .get(adminRoutes.users.getUser(email))
            .then((response) => {
                const student = response.data.message;
                if (student) {
                    const isStudentAlreadyAdded = searchResult.some((item) => item._id === student._id);
                    if (!isStudentAlreadyAdded) {
                        setSearchResult([...searchResult, student]);
                    } else {
                        alert("Student is already in");
                    }
                } else {
                    alert("404 not found");
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                alert("Exception");
                setLoading(false);
            });
    };

    const handleSearchInputChange = (value) => {
        setSearchString(value);
        // clear timeout
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // set new timeout for 500 ms
        setSearchTimeout(setTimeout(() => searchStudentByEmail(value), 1500));
    };

    const addStudentToTeacher = (studentId) => {
        axios
            .post(adminRoutes.users.addStudentToTeacher(studentId))
            .then(async () => {
                parentRefresher();
                setSearchResult(prevSearchResult => {
                    const updatedSearchResult = prevSearchResult.filter(student => student._id !== studentId);
                    return updatedSearchResult;
                });
            })
            .catch(err => {
                console.error(err);
                alert(t("admin.users.errorHasOccurred"));
            });
    };

    const addStudent = (studentEmail) => {
        // searching students
        axios
            .get(adminRoutes.users.getUser(studentEmail))
            .then((response) => {
                const student = response.data.message;
                if (student) {
                    // is student already added? check
                    const isStudentAlreadyAdded = teacherStudents.some((item) => item._id === student._id);

                    if (!isStudentAlreadyAdded) {
                        // Added student to available list
                        setTeacherStudents([...teacherStudents, student]);
                        // Add student to teacher
                        addStudentToTeacher(student._id);
                    } else {
                        alert("Student added");
                    }
                } else {
                    alert("Wrong email");
                }
            })
            .catch((err) => {
                console.error(err);
                alert("Exception");
            });
    };

    const allStudentItems = useMemo(() => {
        const filteredSearchResult = searchResult.filter((student) => {
            let searchField = `${student.lastName} ${student.firstName} ${student.email}`.toLowerCase();
            return searchField.includes(searchString.toLowerCase());
        });

        const teacherStudentIds = teacherStudents.map((item) => item._id);
        const availableStudents = filteredSearchResult.filter((student) => {
            return !teacherStudentIds.includes(student._id);
        });
        return availableStudents.map((student, index) => (
            <List.Item key={`teacher-users-${index}`}>
                <List.Content floated="right">
                    <Button
                        icon
                        primary
                        size="small"
                        labelPosition="right"
                        onClick={() => addStudent(student.email)}
                    >
                        <Icon name="add" />
                        {t("admin.users.addStudent")}
                    </Button>
                </List.Content>
                <List.Icon name="user" />
                <List.Content>
                    <StyledListItemHeader>{`${student.lastName} ${student.firstName}`}</StyledListItemHeader>
                    <List.Description>{student.email}</List.Description>
                </List.Content>
            </List.Item>
        ));
    }, [searchResult, teacherStudents, searchString]);

    const addedStudentsItems = useMemo(() => {
        return teacherStudents.map((student, index) => (
            <List.Item key={`added-students-${index}`}>
                <List.Icon name="user" />
                <List.Content>
                    <StyledListItemHeader>{`${student.lastName} ${student.firstName}`}</StyledListItemHeader>
                    <List.Description>{student.email}</List.Description>
                </List.Content>
            </List.Item>
        ));
    }, [teacherStudents]);

    return (
        <Modal closeIcon open={open} onClose={onClose}>
            <Modal.Header>{t("admin.users.addExistingStudent")}</Modal.Header>

            {loading ? (
                <Loader active size="large">
                    {t("admin.users.loadingStudents")}...
                </Loader>
            ) : (
                <Modal.Content>
                    <Input
                        fluid
                        icon="search"
                        placeholder={t("admin.users.searchForStudents")}
                        value={searchString}
                        onChange={(e, data) => handleSearchInputChange(data.value)}
                    />
                    <List>
                        {allStudentItems}
                    </List>
                    <List>
                        {addedStudentsItems}
                    </List>
                </Modal.Content>
            )}
        </Modal>
    );
};

export default compose(withTranslation())(AddUsersModal);