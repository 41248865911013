// modules
import React, { Component } from 'react';
import axios from 'axios';
// assets
import { bold } from 'assets/formatUtils';
import { adminRoutes } from 'assets/routes';
import { blockOptions, mongoObjectId } from 'assets/utils';
// styles
import { Button, Dropdown, Icon, Message } from 'semantic-ui-react';
// components
import IframeBlock from './IframeBlock';
import IndefiniteQuestionBlock from './IndefiniteQuestionBlock';
import QuestionBlock from './QuestionBlock';
import TextBlock from './TextBlock';
import InteractiveBlock from './InteractiveBlock';
import InteractiveFillSlotsBlock from './InteractiveFillSlotsBlock';
import InteractiveClassification from './InteractiveClassification';
import InteractiveFindPairs from './InteractiveFindPairs';
import InteractiveGuessWord from './InteractiveGuessWord';
import InteractiveWordSearch from './InteractiveWordSearch';
import InteractiveOrderWords from './InteractiveOrderWords';
import BlockPreview from '../BlockPreview';
// redux

// add style
const addStyle = {
  backgroundColor: '#0dab76',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginTop: '1em',
  marginBottom: '1em'
};
// edit style
const editStyle = {
  backgroundColor: '#1099d3',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};
// delete style
const deleteStyle = {
  backgroundColor: '#cc515d',
  color: '#fff',
  borderRadius: '0px',
  boxShadow: '0px 0px 48px 0px rgba(0, 0, 0, 0.4)',
  marginBottom: '1em'
};

// persons
// const persons = [
//   {
//     text: 'Нет',
//     value: ''
//   },
//   {
//     text: 'John',
//     value:
//       'https://store.playstation.com/store/api/chihiro/00_09_000/container/IS/en/999/EP0149-CUSA09988_00-AV00000000000001/1553560094000/image?w=240&h=240&bg_color=000000&opacity=100&_version=00_09_000'
//   },
//   {
//     text: 'Jack',
//     value: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS36JfuWE1Cr8skNvMG8-XzDVesPMJs1Hjciw&usqp=CAU'
//   }
// ];

class Block extends Component {
  // load state from props
  state = {
    block: this.props.block ? this.props.block : {},
    showPreview: false,
    editing: false,
    deleting: false,
    uploading: false
  };

  // optimizing render strategy
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.block !== nextState.block ||
      this.props.block !== nextProps.block ||
      this.state.showPreview !== nextState.showPreview
    ) {
      return true;
    }
    return false;
  }

  // load updated block from props to state
  componentDidUpdate(prevProps) {
    if (this.props.block !== this.state.block && this.props.block !== prevProps.block) {
      this.setState({
        block: this.props.block
      });
    }
  }

  handleChange = (e, data) => {
    if (this.props.new) {
      // handling new block
      if (data.name === 'blockType') {
        this.setState(prevState => ({
          block: {
            ...prevState.block,
            _id: mongoObjectId()
          }
        }));

        // create array with 5 strings for interactive block
        if (data.value === 'interactive') {
          const popupTextList = ['', '', '', '', ''];
          this.setState(prevState => ({
            block: {
              ...prevState.block,
              popupTextList: popupTextList
            }
          }));
        }
      }
    }

    let result = data.value;
    if (data.name === 'testQuestion' || data.name === 'highlighted' || data.name === 'hasVoiceRecorder') {
      result = data.checked;
    }
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        [data.name]: result
      }
    }));
  };

  handleTextChange = value => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        text: value
      }
    }));
  };

  handlePopupTextChange = (e, data) => {
    const index = parseInt(data.name);
    const value = data.value;

    // copy popup text array
    const popupTextList = [...this.state.block.popupTextList];
    // replace specific value in array
    popupTextList.splice(index, 1, value);

    this.setState(prevState => ({
      block: {
        ...prevState.block,
        popupTextList: popupTextList
      }
    }));
  };

  handleEdit = () => {
    const r = window.confirm('Редактировать этот блок?');
    if (r) {
      this.setState({ editing: true });
      axios.post(adminRoutes.lessons.editBlock(this.props.lessonId, this.props.index), { block: this.state.block }).then(
        () => {
          this.setState({ editing: false });
          this.props.fetchLesson(this.props.lessonId);
        },
        () => {
          this.setState({ editing: false });
        }
      );
    }
  };

  handleDelete = () => {
    const r = window.confirm('Удалить этот блок?');
    if (r) {
      this.setState({ deleting: true });
      axios.delete(adminRoutes.lessons.deleteBlock(this.props.lessonId, this.props.index)).then(
        () => {
          this.setState({ deleting: false });
          this.props.fetchLesson(this.props.lessonId);
        },
        () => {
          this.setState({ deleting: false });
        }
      );
    }
  };

  handleNewQuestionBlock = (block, correctAnswer, options) => {
    if (!this.state.block.openQuestion) {
      if (correctAnswer.length === 0) {
        alert('Правильный ответ не выбран');
        return;
      }
      // if (this.state.block.multiple) {
      //     if (correctAnswer.length === 0) {
      //         alert("Правильный ответ не выбран");
      //         return;
      //     }
      // } else if (!this.state.block.multiple) {
      //     if (correctAnswer === "") {
      //         alert("Правильный ответ не выбран");
      //         return;
      //     }
      // }
      // stringify and set options
      block.options = JSON.stringify(options);
    }
    // stringify and set correct answer(-s)
    block.correctAnswer = JSON.stringify(correctAnswer);
    // set _id and block type
    block._id = this.state.block._id;
    block.blockType = this.state.block.blockType;

    this.setState(
      {
        block: block
      },
      () => {
        this.props.handleNewBlock(this.state.block);
        this.setState({
          block: {}
        });
      }
    );
  };

  handleEditQuestionBlock = (block, correctAnswer, options) => {
    if (!this.state.block.openQuestion) {
      if (this.state.block.multiple) {
        if (correctAnswer.length === 0) {
          alert('Правильный ответ не выбран');
          return;
        }
      } else if (!this.state.block.multiple) {
        if (correctAnswer === '') {
          alert('Правильный ответ не выбран');
          return;
        }
      }
      // stringify and set options
      block.options = JSON.stringify(options);
    }
    // stringify and set correct answer(-s)
    block.correctAnswer = JSON.stringify(correctAnswer);
    // set _id and block type
    block._id = this.state.block._id;
    block.blockType = this.state.block.blockType;
    // set audio
    block.audio = this.state.block.audio;

    this.setState(
      {
        block: block
      },
      () => {
        this.handleEdit();
      }
    );
  };

  handleNewInteractiveBlock = (block, interactiveJson) => {
    // set _id and block type
    block._id = this.state.block._id;
    block.blockType = this.state.block.blockType;
    // stringify and set interactive props
    block.interactive = JSON.stringify(interactiveJson);

    this.setState(
      {
        block: block
      },
      () => {
        this.props.handleNewBlock(this.state.block);
        this.setState({
          block: {}
        });
      }
    );
  };

  handleEditInteractiveBlock = (block, interactiveJson) => {
    // set _id and block type
    block._id = this.state.block._id;
    block.blockType = this.state.block.blockType;
    // stringify and set interactive props
    block.interactive = JSON.stringify(interactiveJson);

    this.setState(
      {
        block: block
      },
      () => {
        this.handleEdit();
      }
    );
  };

  handleImageSelect = async e => {
    // create a new form data
    const data = new FormData();
    // append file
    data.append('file', e.target.files[0]);
    // get file extension
    let extension = e.target.files[0].name.split('.');
    extension = extension[extension.length - 1];
    // append filename
    data.append('filename', `lesson-${this.props.lessonId}-block-${this.state.block._id}.${extension}`);
    // set uploading to true
    this.setState({ uploading: true });
    // axios call
    const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch(error => {
      this.setState({ uploading: false });
      return;
    });
    // set picture to state
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        picture: response.data.message.link
      },
      uploading: false
    }));
  };

  handleAudioSelect = async e => {
    // create a new form data
    const data = new FormData();
    // append file
    data.append('file', e.target.files[0]);
    // get file extension
    let extension = e.target.files[0].name.split('.');
    extension = extension[extension.length - 1];
    // append filename
    data.append('filename', `lesson-${this.props.lessonId}-block-${this.state.block._id}.${extension}`);
    // set uploading to true
    this.setState({ uploading: true });
    // axios call
    const response = await axios.post(adminRoutes.upload.uploadAudio(), data).catch(error => {
      this.setState({ uploading: false });
      return;
    });
    // set audio to state
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: response.data.message.link
      },
      uploading: false
    }));
  };

  handleAudioDelete = async e => {
    this.setState(prevState => ({
      block: {
        ...prevState.block,
        audio: ''
      }
    }));
  };

  togglePreview = () => {
    this.setState({
      showPreview: !this.state.showPreview
    });
  };

  render() {
    const { showPreview } = this.state;

    let block;
    if (this.state.block.blockType) {
      switch (this.state.block.blockType) {
        case 'question':
          block = (
            <QuestionBlock
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={this.props.new}
              handleAudioSelect={this.handleAudioSelect}
              // handleDeleteAudio={this.handleAudioDelete}
              handleNewQuestionBlock={this.handleNewQuestionBlock}
              handleEditQuestionBlock={this.handleEditQuestionBlock}
              handleDelete={this.handleDelete}
            />
          );
          break;
        case 'indefiniteQuestion':
          block = (
            <IndefiniteQuestionBlock
              block={this.state.block}
              onTextChange={this.handleTextChange}
              onChange={this.handleChange}
            />
          );
          break;
        case 'text':
          block = (
            <TextBlock
              block={this.state.block}
              onChange={this.handleChange}
              onTextChange={this.handleTextChange}
              onAudioSelect={this.handleAudioSelect}
              onAudioDelete={this.handleAudioDelete}
            />
          );
          break;
        case 'iframe':
          block = <IframeBlock block={this.state.block} onChange={this.handleChange} />;
          break;
        case 'interactive':
          block = (
            <InteractiveBlock
              block={this.state.block}
              onChange={this.handleChange}
              onTextChange={this.handleTextChange}
              onPopupTextChange={this.handlePopupTextChange}
            />
          );
          break;
        case 'question-interactive-fill-slots':
        case 'question-interactive-fill-from-list':
        case 'question-interactive-find-matches':
        case 'question-interactive-select-words':
          block = (
            <InteractiveFillSlotsBlock
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        case 'question-interactive-classification':
          block = (
            <InteractiveClassification
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        case 'question-interactive-find-pairs':
          block = (
            <InteractiveFindPairs
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        case 'question-interactive-guess-word':
          block = (
            <InteractiveGuessWord
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        case 'question-interactive-word-search':
          block = (
            <InteractiveWordSearch
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        case 'question-interactive-order-words':
          block = (
            <InteractiveOrderWords
              block={this.state.block}
              editing={this.state.editing}
              deleting={this.state.deleting}
              new={Boolean(this.props.new)}
              handleEdit={this.handleEditInteractiveBlock}
              handleAdd={this.handleNewInteractiveBlock}
              handleDelete={this.handleDelete}
              handleAudioSelect={this.handleAudioSelect}
            />
          );
          break;
        default:
          block = null;
      }
    }

    let buttons = [];
    if (this.props.new) {
      if (this.state.block && this.state.block.blockType && !this.state.block.blockType.startsWith('question')) {
        buttons.push(
          <Button
            key={'admin-lesson-' + this.state.block._id + '-add-button'}
            type="button"
            onClick={() => {
              if (this.state.block.blockType) {
                this.props.handleNewBlock(this.state.block);
                this.setState({ block: {} });
              } else {
                alert('Тип блока не был выбран');
              }
            }}
            style={addStyle}
          >
            Добавить блок
          </Button>
        );
      }
    } else {
      if (this.state.block && this.state.block.blockType) {
        if (!this.state.block.blockType.startsWith('question')) {
          buttons.push(
            <Button
              key={'admin-lesson-' + this.state.block._id + '-edit-button'}
              loading={this.state.editing}
              onClick={this.handleEdit}
              style={editStyle}
            >
              Редактировать блок
            </Button>
          );

          buttons.push(
            <Button
              key={'admin-lesson-' + this.state.block._id + '-delete-button'}
              loading={this.state.deleting}
              onClick={this.handleDelete}
              style={deleteStyle}
            >
              Удалить блок
            </Button>
          );
        }
      }
    }

    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          {bold('Тип блока:')}
          <Dropdown
            fluid
            selection
            options={blockOptions}
            name="blockType"
            value={this.state.block.blockType}
            onChange={this.handleChange}
          />
        </div>
        <div style={{ marginBottom: '1em' }}>{block}</div>
        <Message
          info
          icon="hand pointer outline"
          header="Обращение к пользователю"
          content="Если хочешь обратиться к пользователю, напиши $$placeholder$$, и я заменю это его/её никнеймом"
        />
        {/* <div style={{ marginBottom: '1em' }}>
          {bold('Выберите собеседника:')}
          <Dropdown
            fluid
            selection
            options={persons}
            name="person"
            value={this.state.block.person}
            onChange={this.handleChange}
            style={{ marginBottom: '1em ' }}
          />
          <Image circular size="small" src={this.state.block.person ? this.state.block.person : ''} />
        </div> */}
        <div style={{ marginBottom: '1em' }}>
          {bold('Предпросмотр блока:')}
          <Button icon labelPosition="right" onClick={this.togglePreview} style={{ marginBottom: '1em' }}>
            <Icon name={showPreview ? 'eye slash' : 'eye'} />
            {showPreview ? 'Скрыть' : 'Показать'}
          </Button>
          {showPreview && (
            <BlockPreview
              block={this.state.block}
              index={this.props.index}
              questionNumber={this.props.questionNumber}
              courseId={this.props.courseId}
              lessonId={this.props.lessonId}
              section={this.props.section}
            />
          )}
        </div>
        {buttons}
      </div>
    );
  }
}

export default Block;
