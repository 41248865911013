// modules
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
// styles
// components
import IndefiniteQuestion from '../../../Dashboard/Course/Lesson/IndefiniteQuestion';
import Question from '../../../Dashboard/Course/Lesson/Question';
import TextBlock from '../../../Dashboard/Course/Lesson/TextBlock';
import IframeBlock from '../../../Dashboard/Course/Lesson/IframeBlock';
import InteractiveBlock from '../../../../components/InteractiveBlock';
import InteractiveQuestion1 from '../../../../components/InteractiveQuestions/InteractiveQuestion1';
import InteractiveQuestion2 from '../../../../components/InteractiveQuestions/InteractiveQuestion2';
import InteractiveQuestion3 from '../../../../components/InteractiveQuestions/InteractiveQuestion3';
import InteractiveQuestion4 from '../../../../components/InteractiveQuestions/InteractiveQuestion4';
import InteractiveQuestion5 from '../../../../components/InteractiveQuestions/InteractiveQuestion5';
import InteractiveQuestion6 from '../../../../components/InteractiveQuestions/InteractiveQuestion6';
import InteractiveQuestion7 from '../../../../components/InteractiveQuestions/InteractiveQuestion7';
import InteractiveQuestion8 from '../../../../components/InteractiveQuestions/InteractiveQuestion8';
import InteractiveQuestion9 from '../../../../components/InteractiveQuestions/InteractiveQuestion9';
// redux

const StyledBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;

  .MathJax_Display,
  .MathJax_Preview {
    width: fit-content;
    display: inline-block!important;
    margin: 0;
  }

  .highlighted {
    background-color: rgb(218, 255, 218) !important;
    border: 3px solid rgb(45, 153, 86);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .ql-video {
    display: block;
    margin: 0 auto;
  }

  ${media.lessThan('400px')`
    font-size: 16px;

    h3 {
      font-size: 16px;
    }

    .highlighted {
      padding: 16px;
    }

    .ql-video {
      width: 240px;
      height: 135px;
    }
  `}

  ${media.between('400px', '600px')`
    font-size: 18px;

    h3 {
      font-size: 18px;
    }

    .highlighted {
      padding: 16px;
    }

    .ql-video {
      width: 320px;
      height: 180px;
    }
  `}

  ${media.between('600px', '800px')`
    font-size: 18px;

    h3 {
      font-size: 18px;
    }

    .highlighted {
      padding: 24px;
    }

    .ql-video {
      width: 400px;
      height: 225px;
    }
  `}

  ${media.between('800px', '1100px')`
    font-size: 20px;

    h3 {
      font-size: 20px;
    }

    .highlighted {
      padding: 28px;
    }

    .ql-video {
      width: 640px;
      height: 360px;
    }
  `}

  ${media.greaterThan('1100px')`
    font-size: 22px;

    h3 {
      font-size: 22px;
    }

    .highlighted {
      padding: 32px;
    }

    .ql-video {
      width: 800px;
      height: 450px;
    }
  `}
`;

const BlockPreview = ({ block, index, questionNumber, courseId, lessonId, section }) => {
  let blockPreview;
  let text = block.text || '';

  switch (block.blockType) {
    case 'indefiniteQuestion':
      blockPreview = (
        <IndefiniteQuestion
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question':
      blockPreview = (
        <Question
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'text':
      blockPreview = (
        <TextBlock
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          text={text}
          userId='test-user-id'
        />
      );
      break;
    case 'iframe':
      blockPreview = <IframeBlock preview src={block.iframeSrc} />;
      break;
    case 'interactive':
      blockPreview = (
        <InteractiveBlock
          preview
          highlighted={block.highlighted}
          person={block.person}
          popupTextList={block.popupTextList}
          text={text}
        />
      );
      break;
    case 'question-interactive-fill-slots':
      blockPreview = (
        <InteractiveQuestion1
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-fill-from-list':
      blockPreview = (
        <InteractiveQuestion2
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-classification':
      blockPreview = (
        <InteractiveQuestion3
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-find-pairs':
      blockPreview = (
        <InteractiveQuestion4
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-find-matches':
      blockPreview = (
        <InteractiveQuestion5
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-select-words':
      blockPreview = (
        <InteractiveQuestion6
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-guess-word':
      blockPreview = (
        <InteractiveQuestion7
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-word-search':
      blockPreview = (
        <InteractiveQuestion8
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    case 'question-interactive-order-words':
      blockPreview = (
        <InteractiveQuestion9
          preview
          block={block}
          courseId={courseId}
          lessonId={lessonId}
          id={lessonId}
          index={index}
          section={section}
          questionNumber={questionNumber}
          text={text}
        />
      );
      questionNumber++;
      break;
    default:
      blockPreview = null;
  }

  return <StyledBlockContainer key={`lesson-${lessonId}-${index}`}>{blockPreview}</StyledBlockContainer>;
};

export default BlockPreview;
