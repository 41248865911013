// modules
import React from 'react';
import styled from 'styled-components';
// assets
// styles
import { Button, Input } from 'semantic-ui-react';
// components
// redux

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;

export default function ({ question = {}, index = 0, handleChange, handleDuplicate, handleDelete }) {
  return (
    <QuestionContainer>
      <span style={{ marginRight: '8px', fontWeight: 'bold', cursor: 'pointer' }}>::</span>
      <Input
        placeholder='Введите предложение'
        label={index + 1}
        name='question'
        value={question.question || ''}
        index={index}
        onChange={handleChange}
      />
      <Button icon='copy' onClick={() => handleDuplicate(index)} />
      <Button icon='delete' onClick={() => handleDelete(index)} />
    </QuestionContainer>
  );
}

