// auth routes
export const authRoutes = {
    getUser: () => `/api/users`,
    getUserPaymentInfo: () => `/api/users/payment`,
    checkKundelikId: () => `/api/users/check-kundelik-id`,
    linkKundelikId: () => `/api/users/link-kundelik-id`,
    unlinkKundelikId: () => `/api/users/unlink-kundelik-id`,
    register: () => `/api/users/register`,
    login: () => `/api/users/login`,
    loginWithKundelik: () => `/api/users/login-kundelik`,
    logout: () => `/api/users/logout`,
    edit: (attribute) => `/api/users/edit/${attribute}`,
    forgotPassword: (email) => `/api/users/forgot/${email}`,
};

// dashboard routes
export const dashboardRoutes = {
    fetchCourses: () => `/api/courses`,
    fetchCourseInfo: (courseId) => `/api/courses/${courseId}`,
    resetExpertCourseProgress: (courseId) => `/api/courses/${courseId}/reset`,
    // lesson
    fetchLessons: (courseId) => `/api/courses/${courseId}/lessons`,
    fetchLesson: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}`,
    fetchBetterThan: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}/better`,

    updateLesson: (courseId) => `/api/courses/${courseId}/lessons/update`,
    updateBlock: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}/update`,

    answer: (courseId, lessonId, blockId) => `/api/courses/${courseId}/lessons/${lessonId}/${blockId}/answer`,
    saveVoiceRecording: (courseId, lessonId, blockId) => `/api/courses/${courseId}/lessons/${lessonId}/${blockId}/save-voice-recording`,
    deleteVoiceRecording: (courseId, lessonId, blockId) => `/api/courses/${courseId}/lessons/${lessonId}/${blockId}/delete-voice-recording`,
};

// practices routes
export const practicesRoutes = {
    getPractices: (courseId) => `/api/courses/${courseId}/practices`,
    getPractice: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}`,
    updateLastQuestion: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}/update`,
    answer: (courseId, practiceId, questionId) =>
        `/api/courses/${courseId}/practices/${practiceId}/${questionId}/answer`,
    getResults: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}/results`,
};

// admin routes
export const adminRoutes = {
    classes: {
        getClasses: () => `/api/admin/classes`,
        getClass: (id) => `/api/admin/classes/${id}`,
        createClass: () => `/api/admin/classes`,
        editClass: (id) => `/api/admin/classes/${id}`,
        deleteClass: (id) => `/api/admin/classes/${id}`
    },
    courses: {
        getCourses: () => `/api/admin/courses`,
        getCourse: (id) => `/api/admin/courses/${id}`,
        addCourse: () => `/api/admin/courses`,
        editCourse: (id) => `/api/admin/courses/${id}`,
        deleteCourse: (id) => `/api/admin/courses/${id}`,
        editCourseStyle: (id) => `/api/admin/courses/${id}/style`,
        addLesson: (id) => `/api/admin/courses/${id}/lessons`,
    },
    lessons: {
        changeLessonsOrder: (courseId) => `/api/admin/lessons/${courseId}/order`,
        getLesson: (id) => `/api/admin/lessons/${id}`,
        editLesson: (id) => `/api/admin/lessons/${id}`,
        deleteLesson: (id) => `/api/admin/lessons/${id}`,
        addBlock: (id) => `/api/admin/lessons/${id}/block`,
        editBlock: (id, position) => `/api/admin/lessons/${id}/${position}`,
        deleteBlock: (id, position) => `/api/admin/lessons/${id}/${position}`,
        editExtraMaterial: (id) => `/api/admin/lessons/${id}/extra`,
    },
    practices: {
        getPractices: () => `/api/admin/practices`,
        getPractice: (id) => `/api/admin/practices/${id}`,
        addPractice: () => `/api/admin/practices`,
        editPractice: (id) => `/api/admin/practices/${id}`,
        deletePractice: (id) => `/api/admin/practices/${id}`,
        addQuestion: (id) => `/api/admin/practices/${id}/question`,
        editQuestion: (id, position) => `/api/admin/practices/${id}/${position}`,
        deleteQuestion: (id, position) => `/api/admin/practices/${id}/${position}`,
    },
    teachers: {
        getTeachers: () => `/api/admin/teachers`,
        getTeacher: (email) => `/api/admin/teachers/${email}`,
        createTeacher: () => `/api/admin/teachers`,
    },
    authors: {
        getAuthors: () => `/api/admin/authors`,
        getAuthor: (email) => `/api/admin/authors/${email}`,
        createAuthor: () => `/api/admin/authors`,
        editAuthor: (email) => `/api/admin/authors/${email}`,
    },
    upload: {
        uploadImage: () => `/api/admin/upload/image`,
        uploadAudio: () => `/api/admin/upload/audio`,
        uploadCourseStyle: id => `/api/admin/upload/coursestyle/${id}`
    },
    users: {
        getAllStudents: () => `/api/admin/students`,
        getUsers: () => `/api/admin/users`,
        getUser: (email) => `/api/admin/users/${email}`,
        createUser: () => `/api/admin/users`,
        editUser: (email) => `/api/admin/users/${email}`,
        deleteUser: (email) => `/api/admin/users/${email}`,
        resetProgress: (email, courseId) => `/api/admin/users/${email}/reset/${courseId}`,
        addCourseToUser: (email, courseId) => `/api/admin/users/${email}/add/${courseId}`,
        removeCourseFromUser: (email, courseId) => `/api/admin/users/${email}/remove-course/${courseId}`,
        addStudentToTeacher: studentId => `/api/admin/teachers/add-student/${studentId}`,
        removeStudentFromTeacher: studentId => `/api/admin/teachers/remove-student/${studentId}`
    },
};

export const analyticsRoutes = {
    getUsers: () => `/api/analytics`,
    getUser: (email) => `/api/analytics/${email}`,
};
