// ColorContext.js
import React, { createContext, useContext, useState , useEffect} from 'react';

const ColorContext = createContext();

export function ColorProvider({ children }) {
    const [color, setColor] = useState('#3C3C56');
    useEffect(() => {
        document.documentElement.style.setProperty('--body-background-color', color);
    }, [color]);

    return (
        <ColorContext.Provider value={{ color, setColor }}>
            {children}
        </ColorContext.Provider>
    );
}

export function useColor() {
    const context = useContext(ColorContext);
    if (!context) {
        throw new Error('useColor must be used within a ColorProvider');
    }
    return context;
}