// modules
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// components
import Admin from './Admin';
import Dashboard from 'containers/Dashboard';
import { WrappedLanding as Landing } from './Landing';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { ColorProvider } from './Landing/Utils/ColorContext';
// assets
import { userRoles } from '../assets/utils';
// styles
import '../assets/stylesheets/ckeditor5.css';

export const App = () => {
  const isAuthenticated = useSelector((state) => Boolean(state.user.user.email));
  const role = useSelector((state) => state.user.user.scope);

  return (
    <ColorProvider>
    <Router>
      <Switch>
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          condition={!userRoles.includes(role)}
          path="/admin"
          component={Admin} />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          condition={userRoles.includes(role)}
          path="/dashboard"
          component={Dashboard}
        />
        <Route exact path="/" component={Landing} />
        <Redirect to="/" />
      </Switch>
    </Router>
    </ColorProvider>
  );
};
