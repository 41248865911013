// modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import ReactHtmlParser from "react-html-parser";

// styles
import "./index.css";

// components
import Header from './Component/Header';
import WelcomePage from './Component/WelcomePage';
import Footer from './Component/Footer';

// utils
import { useColor } from './Utils/ColorContext';


import LanguageSelect from "components/LanguageSelect";
import { WrappedAboutModal as AboutModal } from "./Modals/AboutModal";
import { WrappedManualModal as ManualModal } from "./Modals/ManualModal";
import { WrappedTermsModal as TermsModal } from "./Modals/TermsModal";
import { WrappedLoginModal as LoginModal } from "./Modals/LoginModal";
import { WrappedRegisterModal as RegisterModal } from "./Modals/RegisterModal";
// redux
import { loginWithKundelik, logout } from "../../store/User";
// utils
import kundelikIntegration from '../../services/kundelik-integration';
import { userRoles } from "../../assets/utils";
// assets
import logo from "../../assets/images/ekitap_logo.png";


const Landing = ({ history, t }) => {
  // const dispatch = useDispatch();

  // const isAuthenticated = useSelector((state) =>
  //   Boolean(!!state.user.user.email)
  // );
  // const role = useSelector((state) => state.user.user.scope);
  // const loginWithKundelikDone = useSelector((state) => state.user.loginWithKundelik.done);

  // const [isLoginModalShown, toggleLoginModalShown] = useState(false);
  // const [isRegisterModalShown, toggleRegisterModalShown] = useState(false);
  // const [registerModalKundelikId, setRegisterModalKundelikId] = useState('');
  // const [isAboutModalShown, toggleAboutModalShown] = useState(false);
  // const [isManualModalShown, toggleManualModalShown] = useState(false);
  // const [isTermsModalShown, toggleTermsModalShown] = useState(false);

  // NEW
  const { color } = useColor();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
        setLoading(false);
    }, 3000);

    return () => {
        clearTimeout(timer);
    };
  }, []);
  const headerStyle = {
    backgroundColor: color === '#3C3C56' ? color : 'white',
    color: color === '#3C3C56' ? 'white' : 'black',
    zIndex: 1,
    transition: 'background-color 1s, color 1s, box-shadow 1s',
  };

  return (
    <DocumentTitle title={t("landing.title")}>
      <div className="App" style={headerStyle}>
        {loading ? (
          <div className="custom-loader">
            <span></span>
          </div>
        ) : (
          <>
              <Header />
              <div className="wp">
                  <WelcomePage />
              </div>
              <Footer />
          </>
        )}
      </div>
    </DocumentTitle>
  );
};

export const WrappedLanding = withTranslation()(Landing);
