// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
// assets
import { dashboardRoutes } from "assets/routes";
import { MATCH_ELEMENT_CLASSNAME, MATCH_SLOT_CLASSNAME } from "assets/interactiveQuestions";
// styles
import { Button, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

const StyledTimerContainer = styled.div`
  padding-bottom: 12px;
`;

const StyledElementsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

const StyledElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 12px;
  background-color: ${props => props.isSelected ? '#0073e6' : 'rgb(249, 251, 252)'};
  border: 1px solid #eaeaea;
  border-radius: 4px;
  color: ${props => props.isSelected ? '#fff' : '#000'};

  &:hover {
    cursor: ${props => props.wasAnswered ? 'auto' : 'pointer'};
  }
`;

const Element = ({ blockId, wasAnswered, element, isSelected, onClick }) => {
  return (
    <StyledElementContainer data-blockid={blockId} className={MATCH_ELEMENT_CLASSNAME} wasAnswered={wasAnswered} isSelected={isSelected} onClick={onClick}>
      {element}
    </StyledElementContainer>
  )
};

const InteractiveDragAndDrop = React.memo(({ blockId, wasAnswered, elements, selectedElement, onElementClick }) => {
  return !wasAnswered && (
    <StyledElementsContainer>
      {elements.map((element) => (
        <Element blockId={blockId} wasAnswered={wasAnswered} element={element} isSelected={element === selectedElement} onClick={onElementClick} />
      ))}
    </StyledElementsContainer>
  )
});

class InteractiveQuestion5 extends Component {
  constructor(props) {
    super(props);

    const { block } = this.props;

    const text = block.text || '';
    const interactive = block.interactive ? JSON.parse(block.interactive) : {};
    const slots = interactive.slots || [];
    const userAnswers = block.answer ? JSON.parse(block.answer) : Array(slots.length).fill('');

    const matchedBlanks = text.match(/-[0-9]+-/g) || [];
    const correctAnswers = matchedBlanks.map((item) => {
      const itemWithoutDash = item.replaceAll('-', '');
      const itemAsIndex = parseInt(itemWithoutDash, 10) - 1;
      const resultItem = slots[itemAsIndex].split(';');
      return resultItem;
    });
    const shuffledElements = slots.sort(() => Math.random() - 0.5);

    const replacedText = text.replace(/-[0-9]+-/g, this.replaceSlotsInText);
    this.renderedElements = ReactHtmlParser(replacedText);

    this.state = {
      userAnswers,
      correctAnswers,
      answered: !!block.answer,
      correct: block.correct,
      elapsed: block.time || 0,
      slots,
      elements: shuffledElements,
      selectedElement: '',
      showHint: false,
      testQuestion: block.testQuestion,
      hasReanswered: block.hasReanswered
    };
  }

  componentDidMount() {
    // add slot click listeners
    const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    [...slotHtmlElements].forEach((element, index) => {
      element.addEventListener('click', this.handleSlotClick);
      element.dataset.index = index;
    });
    // add reset click listener
    document.addEventListener('click', this.handleEmptyPlaceClick);

    if (this.props.preview) return;
    if (!this.state.answered) {
      // set answering to false (for parent Lesson component)
      this.props.handleAnswering(true);
    } else {
      // color user answers
      const matchHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...matchHtmlElements].forEach((element, index) => {
        element.textContent = this.state.userAnswers[index];
        element.style.borderStyle = 'solid';
        element.classList.add(this.state.correctAnswers[index].includes(this.state.userAnswers[index]) ? "correct" : "incorrect");
      });
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);

    // remove slot click listeners
    const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    [...slotHtmlElements].forEach((element) => {
      element.removeEventListener('click', this.handleSlotClick);
    });

    // remove reset click listener
    document.removeEventListener('click', this.handleEmptyPlaceClick);
  }

  replaceSlotsInText = () => {
    const { block } = this.props;
    const { _id: blockId } = block;

    return `<div data-blockid='${blockId}' class='${MATCH_SLOT_CLASSNAME}'></div>`;
  }

  startTimer = () => {
    this.start = new Date();
    this.timer = setInterval(this.tick, 1000);
  };

  handleQuestionVisibility = (isVisible) => {
    if (!isVisible) {
      if (this.timer) clearInterval(this.timer);
    }

    if (isVisible && !this.state.answered) {
      if (this.timer) clearInterval(this.timer);
      this.startTimer();
    }
  };

  // tick-tack time is running
  tick = () => {
    this.setState({
      elapsed: Math.round((new Date() - this.start) / 1000),
    });
  };

  handleHint = () => {
    this.setState({ showHint: !this.state.showHint });
  };

  handleAnswer = () => {
    const { correctAnswers, userAnswers, elapsed } = this.state;
    const { block, courseId, lessonId, index } = this.props;
    const { _id: blockId } = block;

    if (!userAnswers.every((item) => item.length !== 0)) {
      alert('Выберите ответы для каждого пропуска');
      return;
    }
    
    // convert user answers array into a string
    const stringifiedAnswer = JSON.stringify(userAnswers);

    // stop timer
    if (this.timer) clearInterval(this.timer);

    let isCorrect = true;
    // compare user answers with correct answers
    for (let i = 0; i < userAnswers.length; i++) {
      if (!correctAnswers[i].includes(userAnswers[i])) {
        isCorrect = false;
        break;
      }
    }

    // set answering to false
    this.props.handleAnswering(false);
    // set answer (not sure whether we need this one)
    this.props.handleAnswer(index, stringifiedAnswer, isCorrect, elapsed);
    // increment/decrement number of correct answers
    this.props.handleCorrect(isCorrect);

    // handle coloring of user answers
    const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${blockId}']`) || [];
    const slotElements = [...slotHtmlElements];

    const length = Math.min(userAnswers.length, correctAnswers.length);
    for (let i = 0; i < length; i++) {
      const element = slotElements[i];
      element.classList.add(correctAnswers[i].includes(userAnswers[i]) ? "correct" : "incorrect");
      element.style.borderStyle = 'solid';
    }

    // this.setState({
    //   answered: true,
    //   correct: isCorrect,
    // });

    // answer payload
    const payload = {
      answer: stringifiedAnswer,
      correct: isCorrect,
      time: elapsed,
    };

    // axios call
    const answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
    axios.post(answerRoute, payload).then(() => {
      this.setState({
        answered: true,
        correct: isCorrect,
        userAnswers,
      });
    });
  };

  onReanswerClick = () => {
    // handle de-coloring of user answers
    const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    const slotElements = [...slotHtmlElements];
    const elements = this.state.slots.sort(() => Math.random() - 0.5);

    slotElements.forEach((element) => {
      element.textContent = '';
      element.style.borderStyle = 'dashed';
      element.style.backgroundColor = '#ffffff';
      element.classList.remove("correct");
      element.classList.remove("incorrect");
    });

    this.setState(prevState => ({
      answered: false,
      elements,
      userAnswers: Array(prevState.slots.length).fill(''),
      correct: undefined,
      elapsed: 0,
      hasReanswered: true
    }), () => {
      // start the timer again
      this.startTimer();
    });
  }

  handleEmptyPlaceClick = (event) => {
    if (!event.target.classList.contains(MATCH_SLOT_CLASSNAME) && !event.target.classList.contains(MATCH_ELEMENT_CLASSNAME) && this.state.selectedElement.length !== 0) {
      const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      const slotElements = [...slotHtmlElements];

      slotElements.forEach((element) => {
        element.classList.remove('active');
      });

      this.setState({
        selectedElement: '',
      });
    }
  };

  handleElementClick = (event) => {
    const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
    const slotElements = [...slotHtmlElements];

    slotElements.forEach((element) => {
      element.classList.add('active');
    });

    this.setState({
      selectedElement: event.target.textContent,
    });
  };

  handleSlotClick = (event) => {
    if (this.state.selectedElement) {
      if (this.state.answered) return;

      const { target: element } = event;
      const index = parseInt(event.target.dataset.index, 10);
      const value = element.textContent;

      // replace user answer with selected value
      const updatedUserAnswers = [...this.state.userAnswers];
      updatedUserAnswers[index] = this.state.selectedElement;

      // remove that value from available elements
      // add a slot value to available elements if the clicked slot has it
      const updatedElements = [...this.state.elements].filter((item) => item !== this.state.selectedElement);
      if (value) {
        updatedElements.push(value)
      }

      // set slot to selected element value
      element.textContent = this.state.selectedElement;
      element.style.backgroundColor = 'rgb(249, 251, 252)';

      // remove blue border on slots
      const slotHtmlElements = document.querySelectorAll(`div.${MATCH_SLOT_CLASSNAME}[data-blockid='${this.props.block._id}']`) || [];
      [...slotHtmlElements].forEach((elem) => {
        elem.classList.remove('active');
      });

      this.setState({
        userAnswers: updatedUserAnswers,
        elements: updatedElements,
        selectedElement: '',
      });
    } else {
      if (this.state.answered) return;

      const { target: element } = event;
      const index = parseInt(event.target.dataset.index, 10);
      const value = element.textContent;

      // remove selected slot from user answers
      const updatedUserAnswers = [...this.state.userAnswers];
      updatedUserAnswers[index] = '';

      // add the value back to available elements
      const updatedElements = [...this.state.elements];
      if (value) {
        updatedElements.push(value);
      }

      // set slot text content and border style
      element.textContent = '';
      element.style.backgroundColor = '#ffffff';

      this.setState({
        userAnswers: updatedUserAnswers,
        elements: updatedElements,
        selectedElement: '',
      });
    }
  };

  render() {
    const { preview, t } = this.props;

    let answerButton = (
      <Button
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.handleAnswer}
      >
        {t("dashboard.course.lesson.question.answer")}
      </Button>
    );

    let reanswerButton = (
      <Button
        primary
        size={
          this.props.innerWidth > 700
            ? "large"
            : this.props.innerWidth > 600
              ? "medium"
              : this.props.innerWidth > 500
                ? "tiny"
                : "mini"
        }
        onClick={this.onReanswerClick}
      >
        {t("dashboard.course.lesson.question.reanswer")}
      </Button>
    )

    if (preview) {
      answerButton = null;
      reanswerButton = null;
    }
    if (this.state.correct === true || this.state.hasReanswered === true || this.state.testQuestion === true) {
      reanswerButton = null;
    }

    let hint;
    let explanation;
    let controls;

    // display hint
    if (this.props.block.hint) {
      hint = (
        <div className="dashboard-lesson-block-interactive-question-hint">
          <Button
            className="dashboard-lesson-block-interactive-question-hint-button"
            onClick={this.handleHint}
            size={
              this.props.innerWidth > 700
                ? "large"
                : this.props.innerWidth > 600
                  ? "medium"
                  : this.props.innerWidth > 500
                    ? "tiny"
                    : "mini"
            }
          >
            {this.state.showHint
              ? t("dashboard.course.lesson.question.hideHint")
              : t("dashboard.course.lesson.question.showHint")}
          </Button>
          {this.state.showHint ? (
            <div className="dashboard-lesson-block-interactive-question-hint-text">
              <div className="ck-content" style={{ width: '100%', }}>
                {ReactHtmlParser(this.props.block.hint)}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    // display explanation for answered question
    if (this.state.answered) {
      // extract captions for three scenarios
      const { correctText = '', wrongText = '', hintingText = '' } = this.props.block;
      // explanation text
      let explanationText = "";
      if (this.state.correct) {
        explanationText = correctText;
      } else {
        explanationText = this.state.hasReanswered ? wrongText : hintingText;
      }
      // replace name
      if (!!explanationText) {
        explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
      }
      // replace time
      if (this.props.block.time) {
        explanationText = explanationText.replace("$$time$$", this.props.block.time);
      }
      // replace rec time
      if (this.props.block.recTime) {
        explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
      }
      if (explanationText != "") {
        explanation = (
          <div
            className={
              this.state.correct === true
                ? "dashboard-lesson-block-interactive-question-explanation-correct"
                : "dashboard-lesson-block-interactive-question-explanation-wrong"
            }
            id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
          >
            <div className="ck-content" style={{ width: '100%', }}>
              {ReactHtmlParser(explanationText)}
            </div>
          </div>
        );
      }
    }

    // interactive question controls
    controls = (
      <div className="dashboard-lesson-block-interactive-question-controls-container">
        {this.state.answered ? (
          <div>{reanswerButton}</div>
        ) : (
          <div>{answerButton}</div>
        )}

        <StyledTimerContainer>
          <Statistic
            className="dashboard-lesson-block-interactive-question-timer"
            floated="right"
            size="tiny"
          >
            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
            <Statistic.Value>{new Date(this.state.elapsed * 1000).toISOString().substring(14, 19)}</Statistic.Value>
          </Statistic>
        </StyledTimerContainer>
      </div>
    );

    return (
      <VisibilitySensor partialVisibility={true} intervalDelay={200} onChange={this.handleQuestionVisibility}>
        <div
          className={`dashboard-lesson-block-interactive-question ${this.props.block.highlighted ? 'highlighted' : ''}`}
          id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
        >
          <div className="dashboard-lesson-block-interactive-question-text">
            <div className="ck-content" style={{ width: '100%' }}>{this.renderedElements}</div>
          </div>
          <InteractiveDragAndDrop
            blockId={this.props.block._id}
            wasAnswered={this.state.answered}
            elements={this.state.elements}
            selectedElement={this.state.selectedElement}
            onElementClick={this.handleElementClick}
          />
          {this.props.block.audio && <CustomAudioPlayer url={this.props.block.audio} />}
          {hint}
          {controls}
          {explanation}
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.config.locale
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InteractiveQuestion5));
