// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { withTranslation } from "react-i18next";
// assets
import burger from 'assets/images/burger_menu.svg';
import cross from 'assets/images/cross.svg';
import { logoutAndScrollToTop } from '../../utils';
// styles
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import './index.css';
// components
import Analytics from './Analytics';
import UserAnalytics from './Analytics/UserAnalytics';
import Authors from './Authors';
import Author from './Authors/Author';
import NewAuthor from './Authors/NewAuthor';
import Courses from './Courses';
import Course from './Courses/Course';
import NewCourse from './Courses/NewCourse';
import Lesson from './Lessons/Lesson';
import NewLesson from './Lessons/NewLesson';
import Users from './Users';
import User from './Users/User';
import NewUser from './Users/NewUser';
import Class from './Users/Class';
import NewClass from './Users/NewClass';
import Teachers from './Teachers';
import Teacher from './Teachers/Teacher';
import NewTeacher from './Teachers/NewTeacher';
import Settings from './Settings';
import Dashboard from '../Dashboard';
// redux
import { logout } from '../../store/User';

class Admin extends Component {
  state = {
    showSidebar: false
  };

  handleSidebar = () => {
    this.setState(prevState => ({ showSidebar: !prevState.showSidebar }));
  };

  hideSidebar = () => {
    this.setState({ showSidebar: false });
  };

  render() {
    const { showSidebar } = this.state;
    const { t } = this.props;

    return (
      <DocumentTitle title="Панель администратора">
        <div className="admin-wrapper">
          <header className="admin-header">
            <nav>
              <NavLink className="admin-nav-link" to="/">
                <Icon name="home" />
              </NavLink>
              {(this.props.role === 'admin' || this.props.role === 'teacher') && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/users">
                  {t("admin.index.users")}
                </NavLink>
              )}
              {(this.props.role === 'admin' || this.props.role === 'teacher') && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/analytics">
                  {t("admin.index.analytics")}
                </NavLink>
              )}

              {this.props.role === 'admin' && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/teachers">
                  Учителя
                </NavLink>
              )}
              {this.props.role === 'admin' && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/authors">
                  Авторы
                </NavLink>
              )}

              {(this.props.role === 'admin' || this.props.role === 'author') && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/courses">
                  {t("dashboard.courses.header")}
                </NavLink>
              )}

              {(this.props.role === 'teacher') && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/dashboard/courses">
                  {t("dashboard.courses.header")}
                </NavLink>
              )}

              {(this.props.role === 'teacher') && (
                <NavLink className="admin-nav-link" activeClassName="admin-nav-link-active" to="/admin/settings">
                  {t("admin.settings.title")}
                </NavLink>
              )}

              <span className="admin-nav-link admin-nav-link-logout" onClick={logoutAndScrollToTop}>
                {t("admin.index.logout")}
              </span>
            </nav>
          </header>
          <header className="admin-header-mobile">
            <nav>
              <div className="admin-header-mobile-button" onClick={this.handleSidebar}>
                {showSidebar ? <img src={cross} alt="Cross icon" /> : <img src={burger} alt="Burger menu" />}
              </div>
              <Sidebar as={Menu} animation="overlay" className="admin-header-sidebar" inverted vertical visible={showSidebar}>
                <Menu.Item className="admin-nav-link" as="a" href="/">
                  <Icon name="home" />
                  {t("admin.index.home")}

                </Menu.Item>
                <Menu.Item className="admin-nav-link" as="a" onClick={logoutAndScrollToTop}>
                  <Icon name="sign out" />
                  {t("admin.index.logout")}
                </Menu.Item>
                {(this.props.role === 'admin' || this.props.role === 'teacher') && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/users" onClick={this.hideSidebar}>
                    <Icon name="group" />
                    {t("admin.index.users")}
                  </Menu.Item>
                )}
                {(this.props.role === 'admin' || this.props.role === 'teacher') && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/analytics" onClick={this.hideSidebar}>
                    <Icon name="pie chart" />
                    {t("admin.index.analytics")}
                  </Menu.Item>
                )}
                {this.props.role === 'admin' && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/teachers" onClick={this.hideSidebar}>
                    <Icon name="graduation cap" />
                    Учителя
                  </Menu.Item>
                )}
                {this.props.role === 'admin' && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/authors" onClick={this.hideSidebar}>
                    <Icon name="graduation cap" />
                    Авторы
                  </Menu.Item>
                )}
                {(this.props.role === 'admin' || this.props.role === 'author') && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/courses" onClick={this.hideSidebar}>
                    <Icon name="ordered list" />
                    {t("dashboard.courses.header")}
                  </Menu.Item>
                )}
                {(this.props.role === 'teacher') && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/dashboard/courses" onClick={this.hideSidebar}>
                    <Icon name="ordered list" />
                    {t("dashboard.courses.header")}
                  </Menu.Item>
                )}
                {(this.props.role === 'teacher') && (
                  <Menu.Item className="admin-nav-link" as={NavLink} to="/admin/settings" onClick={this.hideSidebar}>
                    <Icon name="setting" />
                    {t("admin.settings.title")}
                  </Menu.Item>
                )}
              </Sidebar>
            </nav>
          </header>

          <main className='admin-content-wrapper'>
            <Switch>
              <Route exact path="/admin/users" component={Users} />
              <Route exact path="/admin/users/new" component={NewUser} />
              <Route exact path="/admin/users/:email" component={User} />
              <Route exact path="/admin/classes/new" component={NewClass} />
              <Route exact path="/admin/classes/:id" component={Class} />
              <Route exact path="/admin/authors" component={Authors} />
              <Route exact path="/admin/authors/new" component={NewAuthor} />
              <Route exact path="/admin/authors/:email" component={Author} />
              <Route exact path="/admin/teachers" component={Teachers} />
              <Route exact path="/admin/teachers/new" component={NewTeacher} />
              <Route exact path="/admin/teachers/:email" component={Teacher} />

              <Route exact path="/admin/courses" component={Courses} />
              <Route exact path="/admin/courses/new" component={NewCourse} />
              <Route exact path="/admin/courses/:id" component={Course} />
              <Route exact path="/admin/courses/:id/lessons/new" component={NewLesson} />
              <Route exact path="/admin/courses/:courseId/lessons/:lessonId" component={Lesson} />

              <Route exact path="/admin/analytics" component={Analytics} />
              <Route exact path="/admin/analytics/:email" component={UserAnalytics} />

              <Route exact path="/admin/settings" component={Settings} />

              <Route path="/admin/dashboard" component={Dashboard} />

              <Redirect to={this.props.role === 'admin' || this.props.role === 'teacher' ? '/admin/users' : '/admin/courses'} />
            </Switch>
          </main>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.user.user.email,
  role: state.user.user.scope
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Admin);
