// modules
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const CardContainer = styled.div`
  border: 1px solid #cecece;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);

  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('400px')`
    width: 144px;
	`}

	${media.between('400px', '600px')`
    width: 160px;
	`}

	${media.between('600px', '800px')`
    width: 196px;
	`}
			
	${media.greaterThan('800px')`
    width: 256px;
	`}
`;

const CardImage = styled.div`
  border-radius: 8px 8px 0px 0px;

  img {
    border-radius: inherit;
    width: 100%;
  }
`;

const CardContent = styled.div`
  ${media.lessThan('400px')`
    padding: 6px;
	`}
			
  ${media.between('400px', '600px')`
    padding: 8px;
	`}

  ${media.between('600px', '800px')`
    padding: 10px;
	`}

	${media.greaterThan('600px')`
    padding: 12px;
	`}
`;

const CardHeader = styled.div`
  margin-bottom: 4px;
  font-weight: 600;

  ${media.lessThan('400px')`
    font-size: 12px;
    line-height: 1.2;
	`}

	${media.between('400px', '600px')`
    font-size: 14px;
    line-height: 1.2;
	`}

	${media.between('600px', '800px')`
    font-size: 16px;
    line-height: 1.2;
	`}
			
	${media.greaterThan('800px')`
    font-size: 18px;
    line-height: 1.4;
	`}
`;

// const CardDescription = styled.div`
//   font-weight: 400;
//   line-height: 1.2;
  
//   ${media.lessThan('400px')`
//     font-size: 10px;
// 	`}

// 	${media.between('400px', '600px')`
//     font-size: 12px;
// 	`}

// 	${media.between('600px', '800px')`
//     font-size: 14px;
// 	`}
			
// 	${media.greaterThan('800px')`
//     font-size: 16px;
// 	`}
// `;

const CourseCard = ({ imgSrc, header, onClick }) => (
  <CardContainer onClick={onClick}>
    <CardImage>
      <img src={imgSrc} alt="" />
    </CardImage>
    <CardContent>
      <CardHeader>{header}</CardHeader>
    </CardContent>
  </CardContainer>
);

export default CourseCard;
