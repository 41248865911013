// modules
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const StyledNavigationButton = styled.button`
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	background-color: ${props => props.backgroundColor};
	border: none;
	border-radius: 50%;
	box-shadow: ${props => (props.isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.75)' : 'none')};
	opacity: ${props => (props.isActive ? '1' : '0.95')};
	outline: none;
	cursor: pointer;
	box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);

	&:hover {
		opacity: 1;
	}

	${media.lessThan('400px')`
		width: 36px;
		height: 36px;

		img, svg {
			width: 16px;
			height: 16px;
			display: flex;
			flex-shrink: 0;
		}
	`}

	${media.between('400px', '600px')`
		width: 48px;
		height: 48px;

		img, svg {
			width: 20px;
			height: 20px;
		}
	`}

	${media.between('600px', '800px')`
		width: 56px;
		height: 56px;

		img, svg {
			width: 24px;
			height: 24px;
		}
	`}
			
	${media.greaterThan('800px')`
		width: 64px;
		height: 64px;

		img, svg {
			width: 32px;
			height: 32px;
		}
	`}
`;

const NavigationButton = ({ id, className, isActive, backgroundColor, onClick, children }) => (
  <StyledNavigationButton circular id={id} className={className} isActive={isActive} backgroundColor={backgroundColor} onClick={onClick}>
    {children}
  </StyledNavigationButton>
);

NavigationButton.defaultProps = {
  backgroundColor: '#3791d4',
  isActive: false
};

export default NavigationButton;
